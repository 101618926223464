import { locationTypes } from 'enums';
import { FormattedMessage } from 'react-intl';

import {
  Heading01Styled,
  Heading03Styled,
  Paragraph01Styled,
} from './WineRackTitle.styled';

type Props = {
  location: string,
  inProductRange: boolean,
  readOnly: boolean,
};

const WineRackTitle = ({ location, inProductRange, readOnly }: Props) => {
  if (inProductRange) {
    return location && location !== locationTypes.EMPTY ? (
      <Heading01Styled readOnly={readOnly}>{location}</Heading01Styled>
    ) : (
      <>
        <Paragraph01Styled readOnly={readOnly} inProductRange>
          <FormattedMessage id="winerack.no-location" />
        </Paragraph01Styled>
        <Heading03Styled inProductRange>
          <FormattedMessage id="winerack.no-location" />
        </Heading03Styled>
      </>
    );
  }
  return (
    <>
      <Paragraph01Styled readOnly={readOnly} inProductRange={false}>
        <FormattedMessage id="winerack.no-offer" />
      </Paragraph01Styled>
      <Heading03Styled inProductRange={false}>
        <FormattedMessage id="winerack.no-offer" />
      </Heading03Styled>
    </>
  );
};

export default WineRackTitle;
