import Grid from '@material-ui/core/Grid';
import printActionTypes from 'enums/printAction.enum';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Paragraph01 } from 'theme/Paragraph';

import { Spinner } from 'components/@common';
import ToastContent from 'components/@common/Toast/ToastContent';
import { Modal02 } from 'components/@modals';

import PrintButtons from './PrintButtons';
import PrintSelect from './PrintSelect';

type Props = {
  pdfLoading: boolean,
  onClose: () => void,
  onAccept: (payload: Object) => void,
  open: boolean,
  modalType: string,
};

type State = {
  printOption: string,
};

class PrintModal extends PureComponent<Props, State> {
  state: State = {
    printOption: printActionTypes.PRINT_SHEETS,
  };

  handleAccept = () => {
    const { onAccept, modalType } = this.props;
    const { printOption } = this.state;

    onAccept({
      printType: printOption,
      modalType,
    });
  };

  handleSelect = (printOption: string) => {
    this.setState({
      printOption,
    });
  };

  render() {
    const { pdfLoading, onClose, open } = this.props;
    const { printOption } = this.state;
    return (
      <Modal02
        title={<FormattedMessage id="print-modal-detail.title" />}
        open={open}
        onClose={onClose}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <Paragraph01>
              <FormattedMessage id="print-modal-detail.paragraph" />
            </Paragraph01>
          </Grid>
          <Grid item>
            <PrintSelect checked={printOption} onSelect={this.handleSelect} />
          </Grid>
          {pdfLoading && (
            <>
              <Grid item>
                <ToastContent
                  variant="info"
                  intlId="print-modal-detail.info.pdf-loading"
                />
              </Grid>
              <Grid item>
                <Spinner />
              </Grid>
            </>
          )}
          {!pdfLoading && (
            <>
              <Grid item>
                <PrintButtons onCancel={onClose} onAccept={this.handleAccept} />
              </Grid>
            </>
          )}
        </Grid>
      </Modal02>
    );
  }
}

export default PrintModal;
