// @ts-ignore
export const mapRequestProduct = (data) => {
  const params = {};

  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      // @ts-ignore
      params[key] = data[key].join('|');
    } else {
      // @ts-ignore
      params[key] = data[key];
    }
  });

  return params;
};
