import SvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';

export const SvgIconStyled = styled(SvgIcon)`
  && {
    ${({ size }) =>
      size
        ? `
          font-size: ${size}px;
        `
        : `
          font-size: 24px;
      `};
  }
`;

export default { SvgIconStyled };
