import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';

import { productsAnalytics } from 'services/analytics/Tealium';

import IcoListView from 'components/@icons/icons/IcoListView';
import IcoNormalView from 'components/@icons/icons/IcoNormalView';

import { ButtonIcon02Styled } from './ViewButtons.styled';

type Props = {
  onSmallViewClick: () => void,
  onNormalViewClick: () => void,
  listViewActive: boolean,
  normalViewActive: boolean,
};

class ViewButtons extends PureComponent<Props> {
  handleSmallViewClick = () => {
    const { onSmallViewClick } = this.props;
    productsAnalytics.trackChangeListView(true);

    onSmallViewClick();
  };

  handleNormalViewClick = () => {
    const { onNormalViewClick } = this.props;
    productsAnalytics.trackChangeListView(false);

    onNormalViewClick();
  };

  render() {
    const { listViewActive, normalViewActive } = this.props;

    return (
      <Grid container direction="row" justifyContent="center" spacing={1}>
        <Grid item>
          <ButtonIcon02Styled
            isActive={listViewActive}
            onClick={this.handleSmallViewClick}
            icon={<IcoListView />}
          />
        </Grid>
        <Grid item>
          <ButtonIcon02Styled
            isActive={normalViewActive}
            onClick={this.handleNormalViewClick}
            icon={<IcoNormalView />}
          />
        </Grid>
      </Grid>
    );
  }
}

export default ViewButtons;
