import { connect } from 'react-redux';

import { getFrontCamera } from 'store/scan/scan.selectors';

import ScannerModalB2c from './ScannerModalB2c';

const mapStateToProps = (state) => ({
  activeCamera: getFrontCamera(state),
});

export default connect(mapStateToProps)(ScannerModalB2c);
