import ToastTypes from 'enums/toast.enum';
import { lighten } from 'polished';
import styled, { css } from 'styled-components';

import ToastContentBase from '../ToastContentBase';

const setColor = (light: number, color: string) => css`
  background-color: ${lighten(light, color)} !important;
  color: ${color} !important;
  fill: ${color} !important;
`;

const getColor = (props) => {
  const { variant } = props;
  const { global01, global02, global06 } = props.theme.palette;
  return css`
    ${variant === ToastTypes.ERROR && setColor(0.35, global01)};
    ${variant === ToastTypes.SUCCESS && setColor(0.7, global02)};
    ${variant === ToastTypes.INFO && setColor(0.35, global06)};
  `;
};

export const ToastContentStyled = styled(ToastContentBase)`
  ${(props) => getColor(props)};
`;

export const CenteredContent = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.textStyles.paragraph01bold};
  > span {
    margin-left: ${(props) => props.theme.spacings.spacing03};
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }
`;
