const IcoEdit = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M4 16.67V20h3.33l9.83-9.83-3.33-3.33zm15.74-9.08a.89.89 0 0 0 0-1.25l-2.08-2.08a.89.89 0 0 0-1.25 0l-1.63 1.63 3.33 3.33z"
      fill="currentColor"
    />
  </svg>
);

export default IcoEdit;
