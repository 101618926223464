import { Component } from 'react';

import Icon from 'components/@icons/Icon';
import IcoClose from 'components/@icons/icons/IcoClose';

import { ChipStyled } from './Chip01.styled';

type Props = {
  children: Node,
  className?: string,
  onDelete: () => void,
};

export default class Chip01 extends Component<Props> {
  render() {
    const { className, onDelete, ...rest } = this.props;
    return (
      <ChipStyled
        className={className}
        deleteIcon={
          <Icon>
            <IcoClose />
          </Icon>
        }
        onDelete={onDelete}
        {...rest}
      />
    );
  }
}
