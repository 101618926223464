/* eslint-disable import/no-mutable-exports */

import config from 'config/env';

import CoreAnalytics from 'services/analytics/CoreAnalytics';
import ProductsAnalytics from 'services/analytics/ProductsAnalytics';
import SearchAnalytics from 'services/analytics/SearchAnalytics';
import WineAnalytics from 'services/analytics/WineAnalytics';
import { AnalyticsParams } from 'types/analytics.types';

export let wineAnalytics: WineAnalytics;
export let coreAnalytics: CoreAnalytics;
export let productsAnalytics: ProductsAnalytics;
export let searchAnalytics: SearchAnalytics;

export const initTealium = () => {
  if (config.ANALYTICS_URL) {
    const elem = document.getElementsByTagName('body')[0];
    elem.setAttribute('data-tms-navigation-section', 'body');
    Tealium.create(config.ANALYTICS_URL, false);
  }
};

class Tealium {
  static _instance: Tealium;

  debug: boolean;
  events: AnalyticsParams[] = [];

  constructor(url: string, debug: boolean) {
    this.debug = debug;

    const utagjs = url;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = `
        (function(a,b,c,d){
          a='${utagjs}';
          b=document;
          c='script';
          d=b.createElement(c);
          d.src=a;
          d.type='text/java' + c;
          d.async=true;
          a=b.getElementsByTagName(c)[0];
          a.parentNode.insertBefore(d,a);
      })();`;
    document.body.appendChild(script);

    this._initAnalytics();
  }

  static create = (url: string, debug: boolean) => {
    if (Tealium._instance === undefined) {
      Tealium._instance = new Tealium(url, debug);
    }
    return Tealium._instance;
  };

  get manager() {
    if (this.debug) {
      // noinspection JSUnresolvedVariable
      return window.utag;
    }
    if (!window.udata) {
      // @ts-ignore
      window.udata = [];
    }
    return window.udata;
  }

  track(data: AnalyticsParams) {
    if (!this.manager) {
      this.events.push(data);
      return;
    }
    this._handlePostponed();
    this.trackEvent(data);
  }

  trackEvent(data: AnalyticsParams) {
    if (this.debug) {
      this.manager?.view(data);
    } else {
      // @ts-ignore
      this.manager?.push(data);
    }
  }

  _handlePostponed() {
    if (this.events.length === 0) {
      return;
    }
    this.events.forEach((item) => {
      this.trackEvent(item);
    });
    this.events = [];
  }

  _initAnalytics() {
    wineAnalytics = new WineAnalytics(this.track.bind(this));
    coreAnalytics = new CoreAnalytics(this.track.bind(this));
    productsAnalytics = new ProductsAnalytics(this.track.bind(this));
    searchAnalytics = new SearchAnalytics(this.track.bind(this));
  }
}
