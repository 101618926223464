import styled from 'styled-components';

import { Heading05 } from 'theme/Heading/Heading.styled';

export const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Heading05Styled = styled(Heading05)`
  ${({ theme }) => theme.textStyles.heading05neutral03uppercase};
  ${({ theme }) => theme.spacingStyles.spacing02bottom};
`;

export const PriceView = styled.div`
  border-right: 1px solid ${({ theme }) => theme.palette.neutral05};
  padding-right: ${({ theme }) => theme.spacings.spacing04};

  & + & {
    padding-left: ${({ theme }) => theme.spacings.spacing03};
  }

  :last-child {
    border: none;
    padding-right: 0;
  }
`;
