import { createTheme } from '@material-ui/core/styles';

import { breakpoints, fonts, palette } from './branding';

export default createTheme({
  breakpoints: {
    values: breakpoints,
  },
  typography: {},
  overrides: {
    MuiMenuItem: {
      root: {
        fontFamily: fonts.default,
      },
    },
    MuiCheckbox: {
      root: {
        padding: '8px',
        color: palette.neutral04,
        '&$checked': {
          color: palette.global02,
        },
      },
      checked: {},
    },
  },
  palette: {},
});
