import { call, put, select, takeLatest } from 'redux-saga/effects';

import { productsAnalytics } from 'services/analytics/Tealium';
import {
  getProductFilters,
  getProducts,
} from 'services/api/products/products.api';
import history from 'services/history';
import { getKiosk } from 'store/app/app.selectors';
import { setKioskAction } from 'store/app/app.slice';
import { getFilter } from 'store/filter/filter.selectors';
import {
  clearFilterChipsStart,
  clearFilterChipStart,
  setFilterAction,
} from 'store/filter/filter.slice';
import { getProductLoading } from 'store/product/product.selectors';
import { getProductsPaging } from 'store/products/products.selectors';
import {
  fetchProductsAction,
  fetchProductsFailedAction,
  fetchProductsFilterFailed,
  fetchProductsFilterStart,
  fetchProductsFilterSuccess,
  fetchProductsSuccessAction,
  setProductsLoading,
  setProductsPagingAction,
} from 'store/products/products.slice';
import { getFilterValues } from 'store/step-filter/step-filter.selectors';
import { toastErrorAction } from 'store/toast/toast.slice';
import { getActiveUserShopId } from 'store/user/user.selectors';
import { setActiveUserShopAction } from 'store/user/user.slice';

import { selectProducts } from '../product/product.slice';

function* fetchProducts() {
  // @ts-ignore
  const activeShopId = yield select(getActiveUserShopId);
  // @ts-ignore
  const isLoading = yield select(getProductLoading);
  const { pageNumber, itemsPerPage } = yield select(getProductsPaging);
  // @ts-ignore
  const filter = yield select(getFilter);
  yield put(selectProducts([]));

  const paging = {
    max_results: itemsPerPage,
    p: pageNumber,
  };

  // If products are not loading set the loading on true
  if (!isLoading) {
    yield put(setProductsLoading(true));
  }

  try {
    // @ts-ignore
    const isKiosk = yield select(getKiosk);

    if (activeShopId) {
      const { data } = yield getProducts({
        shop_id: activeShopId,
        ...filter,
        ...paging,
        local: isKiosk ? 1 : filter.local,
      });
      // Analytics: Track search
      if (filter.search) {
        productsAnalytics.trackEventFilteringSearch(
          filter.search,
          data.meta.total,
        );
      }

      yield put(fetchProductsSuccessAction(data));
    } else {
      yield put(fetchProductsSuccessAction({ data: [], meta: {} }));
    }
  } catch (err) {
    yield put(
      toastErrorAction({
        message: 'toast.error.fetch-products',
      }),
    );
    yield put(fetchProductsFailedAction(err));
  }
}
// @ts-ignore
function* fetchProductsFilterFlow({ payload }) {
  const { excludedFilters } = payload || {};

  try {
    let filter = {};
    // @ts-ignore
    const kiosk = yield select(getKiosk);

    // Fetching filters when using kiosk mode
    if (kiosk) {
      // only filter the available filters when in kiosk mode (B2C)!
      // We get the base filters
      // const baseFilters = yield select(getFilter);
      // We get the filters from the step filters
      // @ts-ignore
      const stepFilters = yield select(getFilterValues);
      // @ts-ignore
      const activeShopId = yield select(getActiveUserShopId);
      let processedStepFilters = stepFilters;

      if (stepFilters.wine_region) {
        delete stepFilters.wine_country;
      }

      // We will remove stepFilters from the stepFilter object, based on the keys in the
      // excludedFilters array.
      // We do this so we can keep a certain filter active (so not removing it from redux) and still
      // call the api excluding that filter.
      if (excludedFilters) {
        processedStepFilters = Object.keys(stepFilters).reduce((acc, key) => {
          if (excludedFilters.indexOf(key) > -1) {
            return acc;
          }

          return {
            ...acc,
            [key]: stepFilters[key],
          };
        }, {});
      }

      // And merge them together
      // And set the local to 1, so we only look in the current shop
      filter = {
        // ...baseFilters,
        ...processedStepFilters,
        shop_id: activeShopId,
        local: 1,
      };
    }

    const { data } = yield call(getProductFilters, filter);
    yield put(fetchProductsFilterSuccess(data));
  } catch (err) {
    // @ts-ignore
    const kiosk = yield select(getKiosk);

    yield put(fetchProductsFilterFailed(err));
    yield put(
      toastErrorAction({
        message: 'toast.error.fetch-filter',
      }),
    );

    /**
     * Redirect to login page
     * When product fails
     * We asume the product UUID is not right
     */
    if (kiosk) {
      yield put(setKioskAction(false));
      yield call(history.push, '/error');
    }
  }
}

function* saga() {
  yield takeLatest(fetchProductsAction, fetchProducts);
  yield takeLatest(clearFilterChipsStart, fetchProducts);
  yield takeLatest(clearFilterChipStart, fetchProducts);
  yield takeLatest(setActiveUserShopAction, fetchProducts);
  yield takeLatest(setFilterAction, fetchProducts);
  yield takeLatest(setProductsPagingAction, fetchProducts);
  yield takeLatest(fetchProductsFilterStart, fetchProductsFilterFlow);
}

export default saga;
