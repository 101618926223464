const IcoDetail = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 152 152" {...props}>
    <path
      d="M82 51V24.5H70V51c-5.92 1.58-12 6.86-12 13.13v63.37h36V64.12c0-6.27-6.08-11.55-12-13.12zm-2-5.47h-8v-11h8zm-8-19h8v6h-8zm-12 49h32v24H60zm32 50H60v-24h32zm0-52H60v-9.41c0-5.38 5.79-10.22 11.21-11.37l.79-.17v-5.06h8v5.07l.79.17C86.21 53.9 92 58.74 92 64.12zm28-61H32a5 5 0 0 0-5 5v117a5 5 0 0 0 5 5h88a5 5 0 0 0 5-5v-117a5 5 0 0 0-5-5.01zm3 122a3 3 0 0 1-3 3H32a3 3 0 0 1-3-3v-117a3 3 0 0 1 3-3h88a3 3 0 0 1 3 3zm-53-47h12v2H70zm-6-4h24v2H64z"
      fill="currentColor"
    />
  </svg>
);

export default IcoDetail;
