import cameraTypes from 'enums/camera.enum';
import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getBarcode = (state: StoreState) => _get(state, 'scan.barcode');
export const getBarcodeLoading = (state: StoreState) =>
  _get(state, 'scan.loading');
export const getScanPaused = (state: StoreState) => _get(state, 'scan.paused');
export const getScanError = (state: StoreState) => _get(state, 'scan.error');
export const getScanInit = (state: StoreState) =>
  _get(state, 'scan.scannerInit');
export const getScanLibInit = (state: StoreState) =>
  _get(state, 'scan.libInit');
export const getScanner = (state: StoreState) => _get(state, 'scan.scanner');
export const getScannedProducts = (state: StoreState) =>
  _get(state, 'scan.products');
export const getFrontCameraTypes = (state: StoreState) =>
  _get(state, 'scan.cameraTypes', cameraTypes); // Fallback for usage when the camara is not initialized
export const getFrontCamera = (state: StoreState) => {
  // Get all cameras available
  const cameras = _get(state, 'scan.cameras');
  const types = getFrontCameraTypes(state);

  // We have cameras
  // @ts-ignore
  if (cameras && cameras.length > 0) {
    // @ts-ignore
    const frontCamera = cameras.find(
      // @ts-ignore
      (camera) => camera.cameraType === types.FRONT,
    );

    if (frontCamera) {
      return frontCamera;
    }

    // Fallback to default camera
    return cameras[0];
  }

  return { cameraType: 'front' };
};
