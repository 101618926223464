import axiosManager from 'services/api/axios';

export const getUser = (userId: string) =>
  axiosManager
    .get(`/users/${userId}`, {
      withCredentials: true,
    })
    .then(
      (response) => response.data,
      (errorResponse) => Promise.reject(errorResponse),
    );

export default getUser;
