import SnackbarContent from '@material-ui/core/SnackbarContent';
import styled from 'styled-components';

export const ToastContentBaseStyled = styled(SnackbarContent)`
  && {
    box-shadow: none;
    border-radius: 8px;
    flex-wrap: nowrap;
    flex-grow: 1 !important;
    max-width: 100% !important;
  }
`;

export default { ToastContentBaseStyled };
