enum StepTypes {
  PRIMARY = 'STEP_PRIMARY',
  SECONDARY = 'STEP_SECONDARY',
  WINE = 'wine_type',
  BUDGET = 'wine_budget_range',
  TASTE = 'wine_taste',
  COUNTRY = 'wine_country',
  REGION = 'wine_region',
  FOOD = 'wine_dish',
  GRAPE = 'wine_grape',
  SUGGESTION = 'suggestion',
  CATALOG = 'catalog',
}

export default StepTypes;
