import { css } from 'styled-components';

export default css`
  /*
  * Base styles
  * ==========================================================================
  */

  @font-face {
    font-family: 'Netto OT';
    src: url('/assets/fonts/NettoOT.woff2') format('woff2'),
      url('/assets/fonts/NettoOT.woff') format('woff'),
      url('/assets/fonts/NettoOT.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Netto OT Bold';
    src: url('/assets/fonts/NettoOT-Bold.woff2') format('woff2'),
      url('/assets/fonts/NettoOT-Bold.woff') format('woff'),
      url('/assets/fonts/NettoOT-Bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Netto OT Bold Italic';
    src: url('/assets/fonts/NettoOT-BoldItalic.woff2') format('woff2'),
      url('/assets/fonts/NettoOT-BoldItalic.woff') format('woff'),
      url('/assets/fonts/NettoOT-BoldItalic.otf') format('opentype');
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: 'Netto OT Italic';
    src: url('/assets/fonts/NettoOT-Italic.woff2') format('woff2'),
      url('/assets/fonts/NettoOT-Italic.woff') format('woff'),
      url('/assets/fonts/NettoOT-Italic.otf') format('opentype');
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: 'HandOfSeanSpar';
    src: url('/assets/fonts/_b2c/HandOfSeanSpar-Regular.woff2') format('woff2'),
      url('/assets/fonts/_b2c/HandOfSeanSpar-Regular.woff') format('woff'),
      url('/assets/fonts/_b2c/HandOfSeanSpar-Regular.otf') format('opentype');

    font-weight: normal;
    font-style: normal;
  }

  html {
    /** make the scrollbar autohide when cursor is not inside the scrolling container */
    -ms-overflow-style: -ms-autohiding-scrollbar;
    min-height: 100%;
  }

  body {
    margin: 0;
    background-color: #f8f8f8;
    min-height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    color: #ed3943;
  }

  button:hover {
    cursor: pointer;
  }
  /*
  * Remove text-shadow in selection highlight:
  * https://twitter.com/miketaylr/status/12228805301
  *
  * These selection rule sets have to be separate.
  * Customize the background color to match your design.
  */

  ::-moz-selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  ::selection {
    background: #b3d4fc;
    text-shadow: none;
  }

  /*
  * A better looking default horizontal rule
  */

  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
  }

  /*
  * Remove the gap between audio, canvas, iframes,
  * images, videos and the bottom of their containers:
  * https://github.com/h5bp/html5-boilerplate/issues/440
  */

  audio,
  canvas,
  iframe,
  img,
  svg,
  video {
    vertical-align: middle;
  }

  /*
  * Remove default fieldset styles.
  */

  fieldset {
    border: 0;
    margin: 0;
    padding: 0;
  }

  /*
  * Allow only vertical resizing of textareas.
  */

  textarea {
    resize: vertical;
  }

  /*
  * Browser upgrade prompt
  * ========================================================================== */

  :global(.browserupgrade) {
    margin: 0.2em 0;
    background: #ccc;
    color: #000;
    padding: 0.2em 0;
  }

  /*
  * Print styles
  * Inlined to avoid the additional HTTP request:
  * http://www.phpied.com/delay-loading-your-print-css/
  * ========================================================================== */

  @media print {
    *,
    *::before,
    *::after {
      background: transparent !important;
      color: #000 !important; /* Black prints faster: http://www.sanbeiji.com/archives/953 */
      box-shadow: none !important;
      text-shadow: none !important;
    }

    a,
    a:visited {
      text-decoration: underline;
    }

    a[href]::after {
      content: ' (' attr(href) ')';
    }

    abbr[title]::after {
      content: ' (' attr(title) ')';
    }

    /*
    * Don't show links that are fragment identifiers,
    * or use the javascript: pseudo protocol
    */

    a[href^='#']::after,
    a[href^='javascript:']::after {
      content: '';
    }

    pre,
    blockquote {
      border: 1px solid #999;
      page-break-inside: avoid;
    }

    /*
    * Printing Tables:
    * http://css-discuss.incutio.com/wiki/Printing_Tables
    */
    thead {
      display: table-header-group;
    }

    tr,
    img {
      page-break-inside: avoid;
    }

    img {
      max-width: 100% !important;
    }

    p,
    h2,
    h3 {
      orphans: 3;
      widows: 3;
    }

    h2,
    h3 {
      page-break-after: avoid;
    }
  }
`;
