import { productActionTypes } from 'enums';
import _get from 'lodash/get';
import { Component } from 'react';

import type { Price } from 'types/price.types';
import type { Product } from 'types/products.types';

import { ProductLocationModal } from 'components/@product';

import PDLeft from './PDLeft';
import PDRight from './PDRight';
import {
  GridContainerStyled,
  LeftGrid,
  RightGrid,
} from './ProductDetails.styled';

type Props = {
  prices: Price[],
  product: Product,
  productLoading: boolean,
  onUpdateProduct: () => void,
  onRemoveProduct: (product: Product) => void,
};

type State = {
  modalOpen: boolean,
  type: string,
};

class ProductDetails extends Component<Props, State> {
  state = {
    modalOpen: false,
    type: '',
  };

  handleModalOpen = (type: string) => () => {
    this.setState({
      modalOpen: true,
      type,
    });
  };

  handleUpdateProduct = (payload) => {
    const { onUpdateProduct, product } = this.props;

    onUpdateProduct({
      productId: product.id,
      type: this.state.type,
      ...payload,
    });
    this.setState({
      modalOpen: false,
      type: '',
    });
  };

  handleRemoveProduct = () => {
    const { onRemoveProduct, product } = this.props;

    onRemoveProduct({
      productId: product.id,
    });

    this.setState({
      modalOpen: false,
      type: '',
    });
  };

  handleClose = () => {
    this.setState({
      modalOpen: false,
      type: '',
    });
  };

  render() {
    const { product, prices = [], productLoading } = this.props;
    const { modalOpen, type } = this.state;

    return (
      <>
        <GridContainerStyled container direction="row">
          <LeftGrid item xs={4}>
            <PDLeft
              locationLoading={productLoading}
              product={product}
              prices={prices}
              onAdd={this.handleModalOpen(productActionTypes.ADD)}
              onRemove={this.handleModalOpen(productActionTypes.REMOVE)}
              onMove={this.handleModalOpen(productActionTypes.MOVE)}
            />
          </LeftGrid>
          <RightGrid item xs={8}>
            <PDRight product={product} />
          </RightGrid>
        </GridContainerStyled>
        <ProductLocationModal
          open={modalOpen}
          type={type}
          inProductRange={_get(product, 'shopInfo.published', false)}
          onCancel={this.handleClose}
          onClose={this.handleClose}
          shopInfo={_get(product, 'shopInfo', {})}
          product={product}
          onUpdateProduct={this.handleUpdateProduct}
          onRemoveProduct={this.handleRemoveProduct}
        />
      </>
    );
  }
}

export default ProductDetails;
