import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { coreAnalytics } from 'services/analytics/Tealium';
import history from 'services/history';

import { Button02 } from 'components/@common/Button';
import IconButton from 'components/@common/IconButton';
import OverlayHeader from 'components/@common/OverlayHeader';
import IcoArrowLeft from 'components/@icons/icons/IcoArrowLeft';
import IcoClose from 'components/@icons/icons/IcoClose';

type RouterProps = {
  history: Object,
  uuid: string,
};

type Props = RouterProps & {
  changeLanguageAction: Function,
  resetFilterAction: Function,
  resetStepFilter: Function,
  closeModalAction: Function,
  stopScan: Function,
  resetProducts: Function,
  position: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative',
  isDark: boolean,
  hasTransparentScrollingBg: boolean,
  withLanguageSwitch?: boolean,
  shouldDisplayClose?: boolean,
  analyticsLocale: string,
};

class Header extends PureComponent<Props> {
  static defaultProps = {
    withLanguageSwitch: true,
  };

  handleBack = () => {
    const {
      uuid,
      closeModalAction,
      shouldDisplayClose,
      stopScan,
      resetProducts,
    } = this.props;

    closeModalAction();

    if (shouldDisplayClose) {
      stopScan();
      resetProducts();
    }

    if (uuid && !shouldDisplayClose) {
      const { resetFilterAction, resetStepFilter } = this.props;

      resetFilterAction();
      resetStepFilter();

      history.push(`/kiosk`);
    }
  };

  // TODO: Change this to a component that handles the language switch
  handleLanguage = () => {
    const { changeLanguageAction, analyticsLocale } = this.props;

    coreAnalytics.trackEventNavigationLanguageSwitcher(
      analyticsLocale,
      'kiosk',
    );

    changeLanguageAction();
  };

  render() {
    const {
      isDark,
      hasTransparentScrollingBg,
      _withLanguageSwitch,
      position,
      shouldDisplayClose,
      ...rest
    } = this.props;

    const languageButton = (
      <Button02
        isDark={isDark}
        onClick={this.handleLanguage}
        data-tms-navigation-section="language switch"
      >
        <FormattedMessage id="b2c.header.change-language" />
      </Button02>
    );

    const closeButton = shouldDisplayClose ? (
      <IconButton
        icon={<IcoClose />}
        onClick={this.handleBack}
        isDark={isDark}
        labelLeft={<FormattedMessage id="b2c.common.close" />}
      />
    ) : (
      <IconButton
        icon={<IcoArrowLeft />}
        onClick={this.handleBack}
        isDark={isDark}
        labelRight={<FormattedMessage id="b2c.header.back-to-begin" />}
      />
    );

    return (
      <OverlayHeader
        hasTransparentScrollingBg={hasTransparentScrollingBg}
        position={position}
        left={shouldDisplayClose ? languageButton : closeButton}
        right={shouldDisplayClose ? closeButton : languageButton}
        {...rest}
      />
    );
  }
}
export default Header;
