import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Paragraph01 } from 'theme/Paragraph';

import ApproveModal from 'components/@modals/ApproveModal';

type Props = {
  open: boolean,
  onAccept: () => void,
  onClose: () => void,
};

export default class KioskModal extends PureComponent<Props> {
  render() {
    const { open, onClose, onAccept } = this.props;
    return (
      <ApproveModal
        onClose={onClose}
        open={open}
        title={<FormattedMessage id="kiosk-modal.title" />}
        onCancel={onClose}
        onAccept={onAccept}
        acceptLabel={<FormattedMessage id="kiosk-modal.button.start-kiosk" />}
      >
        <Paragraph01>
          <FormattedMessage id="kiosk-modal.description" />
        </Paragraph01>
        <Paragraph01>
          <FormattedMessage id="kiosk-modal.question" />
        </Paragraph01>
      </ApproveModal>
    );
  }
}
