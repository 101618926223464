import styled from 'styled-components';

export const AppLoadingContainer = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral05};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  align-content: center;
`;

export default { AppLoadingContainer };
