import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import IdleTimer from 'react-idle-timer';
import { FormattedMessage } from 'react-intl';

import { Heading03 } from 'theme/Heading';

import { Spinner } from 'components/@common';
import { Button01 } from 'components/@common/Button';
import ToastContent from 'components/@common/Toast/ToastContent';
import Header from 'components/@kiosk/Header';
import Scandit from 'components/@scanner/Scandit';

import {
  LoadingScannerWrapper,
  LoadingWrapper,
  MessageContainer,
  SpinnerStyled,
  StyledGrid,
  Wrapper,
} from './ScannerModalB2c.styled';

type Camera = {
  cameraType: string,
  currentResolution: Object,
  deviceId: string,
  label: string,
};

type Props = {
  open: boolean,
  onAccept: () => void,
  onClose: () => void,
  loading: boolean,
  paused: boolean,
  onScan: (barcode: string) => void,
  error: boolean,
  onRetry: () => void,
  scannerInit: boolean,
  scannerLibInit: boolean,
  activeCamera: Camera,
};

const noop = () => {};

export default class ScannerModal extends PureComponent<Props> {
  idleTimer = null;

  handleScan = (barcode: string) => {
    const { onScan } = this.props;
    onScan(barcode);
  };

  render() {
    const {
      open,
      onClose,
      loading = false,
      error,
      onRetry,
      scannerInit,
      scannerLibInit,
      activeCamera,
    } = this.props;

    return (
      <Dialog
        fullScreen
        keepMounted
        disablePortal
        onClose={onClose}
        open={open}
      >
        {open ? (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref;
            }}
            element={document}
            onActive={noop}
            onIdle={onClose}
            onAction={noop}
            debounce={250}
            timeout={60000}
          />
        ) : null}
        <Wrapper>
          <Header
            disableDock
            isDark={scannerInit && scannerLibInit}
            transparent
            shouldDisplayClose
          />

          {loading && (
            <LoadingWrapper>
              <Spinner color="white" />
            </LoadingWrapper>
          )}

          <MessageContainer>
            {!loading && !error && (
              <StyledGrid
                container
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <ToastContent
                    variant="info"
                    message={
                      <FormattedMessage id="b2c.scanner-modal.toast.info" />
                    }
                  />
                </Grid>
              </StyledGrid>
            )}
            {!loading && error && (
              <StyledGrid
                container
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <ToastContent
                    variant="error"
                    message={
                      <FormattedMessage id="b2c.scanner-modal.toast.not-found" />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button01 color="primary" onClick={onRetry}>
                    <FormattedMessage id="b2c.scanner-modal.button.retry" />
                  </Button01>
                </Grid>
              </StyledGrid>
            )}
          </MessageContainer>

          <Scandit
            onScan={this.handleScan}
            paused={!open}
            activeCamera={activeCamera}
            enableCameraSwitcher={false}
            scanningPaused
            scanSettings={{
              gpuAcceleration: false,
            }}
          />

          {!scannerInit && !scannerLibInit && (
            <LoadingScannerWrapper>
              <SpinnerStyled />
              <Heading03>
                <FormattedMessage id="b2c.scanner-modal.scanner-is-loading" />
              </Heading03>
            </LoadingScannerWrapper>
          )}
        </Wrapper>
      </Dialog>
    );
  }
}
