import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Paragraph01 } from 'theme/Paragraph';

import { Button01 } from 'components/@common/Button';
import { SpinnerStyled } from 'components/@common/Spinner/Spinner.styled';

type Props = {
  onRemoveProduct: (payload) => void,
  onCancel: () => void,
  isUpdatingProducts: boolean,
};

export default class RemoveProduct extends PureComponent<Props> {
  render() {
    const { onRemoveProduct, onCancel, isUpdatingProducts } = this.props;
    return (
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Paragraph01>
              <FormattedMessage id="product.remove-modal.description" />
            </Paragraph01>
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button01 onClick={onCancel} color="secondary">
              <FormattedMessage id="common.button.cancel" />
            </Button01>
            {isUpdatingProducts ? (
              <SpinnerStyled />
            ) : (
              <Button01 onClick={onRemoveProduct} color="primary">
                <FormattedMessage id="common.button.delete" />
              </Button01>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
