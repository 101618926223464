import styled, { css } from 'styled-components';

import { Heading03 } from 'theme/Heading';

import { ButtonIcon01 } from 'components/@common/Button';

const labelActive = (props) => {
  const { isDark, theme } = props;
  if (isDark) {
    return css`
      color: ${theme.palette.neutral06};
    `;
  }
  return css`
    color: ${theme.palette.neutral02};
  `;
};

const labelThemed = (props) => {
  const { isDark, theme } = props;

  if (isDark) {
    return css`
      ${theme.textStyles.heading03neutral01};
    `;
  }

  return css`
    ${theme.textStyles.heading03neutral03};
  `;
};

const buttonIconThemed = (props) => {
  const { isDark, theme } = props;
  if (isDark) {
    return css`
      border-color: ${theme.palette.neutral06};
    `;
  }
  return css`
    border-color: ${theme.palette.neutral02};
  `;
};

export const Label = styled(Heading03)`
  ${(props) => labelThemed(props)};

  ${({ left }) => (left ? 'margin-right: 16px;' : 'margin-left: 16px;')};
`;

export const ButtonIcon01Styled = styled(ButtonIcon01)``;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  && {
    &:active {
      ${Label} {
        ${(props) => labelActive(props)};
      }

      ${ButtonIcon01Styled} {
        ${(props) => buttonIconThemed(props)};
      }
    }

    &:hover {
      cursor: pointer;
    }
  }
`;
