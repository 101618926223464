import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  downloadUrl: string;
  error?: unknown;
};

export const initialState: State = {
  loading: false,
  downloadUrl: '',
};

const printSlice = createSlice({
  name: 'print',
  initialState,
  reducers: {
    fetchPdfStart: (state: State) => {
      state.loading = true;
    },
    fetchPdfSuccess: (state: State, { payload }: PayloadAction<unknown>) => {
      state.loading = false;
      // @ts-ignore
      state.downloadUrl = payload;
    },
    fetchPdfFailed: (state: State, { payload }: PayloadAction<unknown>) => {
      state.loading = false;
      state.error = payload;
    },
    pollTaskStart: () => {},
    pollTaskStop: () => {},
  },
});

export const {
  fetchPdfStart,
  fetchPdfSuccess,
  fetchPdfFailed,
  pollTaskStart,
  pollTaskStop,
} = printSlice.actions;

export default printSlice;
