import Grid from '@material-ui/core/Grid';
import pagingTypes from 'enums/paging.enum';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { wineAnalytics } from 'services/analytics/Tealium';

import { ButtonIcon02 } from 'components/@common/Button';
import IcoArrowLeft from 'components/@icons/icons/IcoArrowLeft';
import IcoArrowLeftFirst from 'components/@icons/icons/IcoArrowLeftFirst';
import IcoArrowRight from 'components/@icons/icons/IcoArrowRight';
import IcoArrowRightLast from 'components/@icons/icons/IcoArrowRightLast';

import {
  ButtonsContainer,
  LabelBoldStyled,
  LabelStyled,
  PaginationGrid,
} from './ProductPagination.styled';

type Props = {
  totalPages: number,
  totalItems: number,
  pageNumber: number,
  itemsPerPage: number,
  transparent: boolean,
  onPageChange: (pageNumber: number) => void,
  onItemsPerPageChange: (value: number) => void,
};

export default class ProductPagination extends Component<Props> {
  handleClick = (type: string) => {
    const { pageNumber, onPageChange, totalPages } = this.props;

    switch (type) {
      case pagingTypes.FIRST:
        wineAnalytics.trackCatalogPaginationButton(type);
        onPageChange(1);
        break;
      case pagingTypes.LAST:
        wineAnalytics.trackCatalogPaginationButton(type);
        onPageChange(totalPages);
        break;
      case pagingTypes.PREV:
        // Docs want this to be the full word
        wineAnalytics.trackCatalogPaginationButton('previous');
        onPageChange(pageNumber - 1);
        break;
      case pagingTypes.NEXT:
      default:
        wineAnalytics.trackCatalogPaginationButton(type);
        onPageChange(pageNumber + 1);
        break;
    }

    this.scrollToTop();
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  renderFromToLabel = () => {
    const { pageNumber, itemsPerPage, totalItems } = this.props;

    const from = (pageNumber - 1) * itemsPerPage + 1;
    const to = pageNumber * itemsPerPage;

    return `${from} - ${to > totalItems ? totalItems : to} `;
  };

  render() {
    const { transparent, totalItems, totalPages, pageNumber } = this.props;

    return (
      <PaginationGrid
        container
        direction="row"
        alignItems="center"
        transparent={transparent}
      >
        <Grid item xs={3}>
          <LabelBoldStyled>{this.renderFromToLabel()}</LabelBoldStyled>
          <LabelStyled>
            <FormattedMessage id="pagination.left.page-of" /> {totalItems}
          </LabelStyled>
        </Grid>
        <Grid item xs={6} />
        <Grid item xs={3}>
          <ButtonsContainer>
            <ButtonIcon02
              disabled={pageNumber === 1}
              onClick={() => this.handleClick(pagingTypes.FIRST)}
              icon={<IcoArrowLeftFirst />}
            />
            <ButtonIcon02
              disabled={pageNumber === 1}
              onClick={() => this.handleClick(pagingTypes.PREV)}
              icon={<IcoArrowLeft />}
            />
            <ButtonIcon02
              disabled={pageNumber === totalPages}
              onClick={() => this.handleClick(pagingTypes.NEXT)}
              icon={<IcoArrowRight />}
            />
            <ButtonIcon02
              disabled={pageNumber === totalPages}
              onClick={() => this.handleClick(pagingTypes.LAST)}
              icon={<IcoArrowRightLast />}
            />
          </ButtonsContainer>
        </Grid>
      </PaginationGrid>
    );
  }
}
