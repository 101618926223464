import { combineReducers } from 'redux';

import appSlice from './app/app.slice';
import authSlice from './auth/auth.slice';
import catalogSlice from './catalog/catalog.slice';
import filterSlice from './filter/filter.slice';
import kioskSlice from './kiosk/kiosk.slice';
import modalSlice from './modal/modal.slice';
import previewSlice from './preview/preview.slice';
import printSlice from './print/print.slice';
import productSlice from './product/product.slice';
import productsSlice from './products/products.slice';
// DON'T REFACTOR redux/scan TO A SLICE - more info in the redux/scan/README.md
import scan from './scan/scan.reducer';
import shopsSlice from './shops/shops.slice';
import stepFilterSlice from './step-filter/step-filter.slice';
import themeSlice from './theme/theme.slice';
import toastSlice from './toast/toast.slice';
import userSlice from './user/user.slice';

export const reducers = {
  stepFilter: stepFilterSlice.reducer,
  toast: toastSlice.reducer,
  auth: authSlice.reducer,
  user: userSlice.reducer,
  app: appSlice.reducer,
  catalog: catalogSlice.reducer,
  product: productSlice.reducer,
  shops: shopsSlice.reducer,
  products: productsSlice.reducer,
  filter: filterSlice.reducer,
  print: printSlice.reducer,
  modal: modalSlice.reducer,
  theme: themeSlice.reducer,
  kiosk: kioskSlice.reducer,
  preview: previewSlice.reducer,
  scan,
};

export const rootReducer = combineReducers(reducers);
export type RootReducer = typeof rootReducer;
export type StoreState = ReturnType<RootReducer>;

export default rootReducer;
