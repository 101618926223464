import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';
import _uniqBy from 'lodash/uniqBy';

import { TEALIUM_NAVIGATION_DEFAULTS } from 'constants/tealium.constant';
import GeneralAnalytics from 'services/analytics/GeneralAnalytics';
import { AnalyticsFunction } from 'types/analytics.types';
import type { FilterState } from 'types/filter.types';
import type { Product } from 'types/products.types';
import { User } from 'types/user.types';

export default class WineAnalytics extends GeneralAnalytics {
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(args: AnalyticsFunction) {
    super(args);
  }

  trackScanWine(locale: string) {
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: 'kiosk',
      page_status: 200,
      page_template: 'zoek een wijn',
      'data-tms-navigation-detail': 'scan wine',
      'data-tms-navigation-section': 'header',
    });
  }

  trackEventWineAdding = (data: Product) => {
    const price = _get(data, 'shopInfo.priceAw', '');
    const products = [
      {
        id: data.productId,
        name: data.name,
        price: price ? Number(price).toFixed(2) : '',
      },
    ];

    return this.track({
      event_name: 'interaction',
      interaction_name: 'click: add wine to rack',
      products,
    });
  };

  trackEventWineAdjustLocation = (data: Product) => {
    const price = _get(data, 'shopInfo.priceAw', '');
    const products = [
      {
        id: data.productId,
        name: data.name,
        price: price ? Number(price).toFixed(2) : '',
      },
    ];

    return this.track({
      event_name: 'interaction',
      interaction_name: 'click: edit wine to rack',
      products,
    });
  };

  trackEventWineBulkAdjustLocation = (data: Product[]) => {
    const products = data.map((p) => ({
      id: p.productId,
      name: p.name,
      price: _get(p, 'shopInfo.priceAw', '')
        ? Number(_get(p, 'shopInfo.priceAw', '')).toFixed(2)
        : '',
    }));

    return this.track({
      event_name: 'interaction',
      interaction_name: 'click: bulk update wines',
      products,
    });
  };

  trackEventWineDelete = (data: Product) => {
    const price = _get(data, 'shopInfo.priceAw', '');
    const products = [
      {
        id: data.productId,
        name: data.name,
        price: price ? Number(price).toFixed(2) : '',
      },
    ];

    return this.track({
      event_name: 'interaction',
      interaction_name: 'click: remove wine from rack',
      products,
    });
  };

  trackEventBulkWineDelete = (data: Product[]) => {
    const products = data.map((p) => ({
      id: p.productId,
      name: p.name,
      price: _get(p, 'shopInfo.priceAw', '')
        ? Number(_get(p, 'shopInfo.priceAw', '')).toFixed(2)
        : '',
    }));

    return this.track({
      event_name: 'interaction',
      interaction_name: 'click: bulk remove wines',
      products,
    });
  };

  trackEventNavigationWineDetail = (
    user: User,
    locale: string,
    activeFilters: Partial<FilterState>[],
    availableFilters: FilterState[],
    isKiosk: boolean,
  ) => {
    let info = {
      env_shop: 'b2c',
    };

    if (user) {
      info = {
        env_shop: 'b2b',
        //@ts-ignore
        user_id: user.id,
        user_logged: 'yes',
        env_organisation: user.activeShop && user.activeShop.shopNumber,
      };
    }
    let filledFilters = [];
    if (isKiosk) {
      let tempValues: unknown;
      const filterList = Object.keys(activeFilters)
        .map((key) => {
          if (key.includes('shop_id')) return null;

          if (key.includes('budget_min_av')) {
            // @ts-ignore
            const values = availableFilters
              // @ts-ignore
              ?.find((f) => f.name === 'wine_budget_range')
              // @ts-ignore
              .ranges.find((v) => v.min === activeFilters.budget_min_av);

            if (values) {
              tempValues = {
                id: 'budget_min_av',
                listingType: 'budget',
                value: `min: ${values.min} - max: ${_get(
                  values,
                  'max',
                  'infinite',
                )}`,
              };
            }
          } else if (key.includes('wine_region')) {
            tempValues =
              // @ts-ignore
              availableFilters.find((f) => f.name === 'wine_country') &&
              // @ts-ignore
              availableFilters
                // @ts-ignore
                .find((f) => f.name === 'wine_country')
                // @ts-ignore
                .listing.listingValues.find(
                  // @ts-ignore
                  (value) => value.id === activeFilters.wine_country,
                ).children;
          } else {
            tempValues =
              // @ts-ignore
              availableFilters.find((f) => f.name === key) &&
              // @ts-ignore
              availableFilters
                // @ts-ignore
                .find((f) => f.name === key)
                // @ts-ignore
                .listing.listingValues.find(
                  // @ts-ignore
                  (value) => value.id === activeFilters[key],
                );
          }

          const values = Array.isArray(tempValues)
            ? // @ts-ignore
              tempValues.find((a) => a.id === activeFilters[key])
            : [tempValues];

          return values;
        })
        .flatMap((v) => v)
        .filter((v) => !!v)
        .map(
          (v) =>
            v && {
              category: v.listingType,
              name: v.value,
            },
        );

      filledFilters = _uniqBy(filterList, 'category');
    } else {
      // TODO: Cleanup
      const filters = _pickBy(activeFilters, (v) =>
        Array.isArray(v) ? (v || []).length > 0 : !!v,
      );
      if (filters && availableFilters) {
        filledFilters = Object.keys(filters)
          .map((key) => {
            let tempValues: unknown;
            if (key.includes('shop_id')) return null;
            if (key.includes('budget_max')) {
              const isAVFilter = key.includes('av');
              tempValues = {
                id: isAVFilter ? 'budget av' : 'budget aw',
                listingType: 'budget',
                value: {
                  // @ts-ignore
                  min: filters[`budget_min_${isAVFilter ? 'av' : 'aw'}`],
                  // @ts-ignore
                  max: filters[`budget_max_${isAVFilter ? 'av' : 'aw'}`],
                },
              };
            } else if (key.includes('wine_region')) {
              tempValues =
                // @ts-ignore
                availableFilters.find((f) => f.name === 'wine_country') &&
                // @ts-ignore
                availableFilters
                  // @ts-ignore
                  .find((f) => f.name === 'wine_country')
                  // @ts-ignore
                  .listing.listingValues.flatMap((values) => values.children);
            } else {
              tempValues =
                // @ts-ignore
                availableFilters.find((f) => f.name === key) &&
                // @ts-ignore
                availableFilters
                  // @ts-ignore
                  .find((f) => f.name === key)
                  // @ts-ignore
                  .listing.listingValues.map((values) => values);
            }

            const values = key.includes('budget')
              ? tempValues && {
                  // @ts-ignore
                  category: tempValues.id,
                  // @ts-ignore
                  value: tempValues.value,
                }
              : // @ts-ignore
                filters[key] &&
                // @ts-ignore
                Array.isArray(filters[key]) &&
                // @ts-ignore
                filters[key]
                  .map(
                    // @ts-ignore
                    (fv) => tempValues && tempValues.find((a) => a.id === fv),
                  )
                  // @ts-ignore
                  .map((b) => (b ? { category: key, value: b.value } : null));

            return values;
          })
          .flatMap((i) => i)
          .filter((v) => !!v);
      }
    }

    // temporary for debugging
    return this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      ...info,
      page_language: locale,
      page_name: 'product detail',
      page_status: 200,
      page_template: 'detail',
      list_filters: filledFilters,
    });
  };

  trackEventWineDetails = (data: Product) => {
    // Track to services
    const price = _get(data, 'shopInfo.priceAv', '');
    const products = [
      {
        id: data.productId,
        name: data.name,
        price: price ? Number(price).toFixed(2) : '',
      },
    ];

    this.track({
      event_name: 'product view',
      products,
    });
  };

  trackEventFilteringToggle = (
    // @ts-ignore
    filters: { category: string; [string]: number | string }[],
  ) =>
    this.track({
      event_name: 'list filter',
      list_name: 'wines',
      list_filters: Array.isArray(filters) ? filters : [filters],
    });

  trackEventFilteringRemove = (
    // @ts-ignore
    filters: { category: string; [string]: number | string }[],
  ) =>
    this.track({
      event_name: 'list filter removal',
      list_name: 'wines',
      list_filters: Array.isArray(filters) ? filters : [filters],
    });

  trackEventSorting = (sortingCatalogParameters: string) =>
    this.track({
      event_name: 'list sort',
      list_sort_method: sortingCatalogParameters,
      list_name: 'wines',
    });

  trackCatalogPaginationButton = (button: string) =>
    this.track({
      event_name: 'interaction',
      interaction_name: 'click: wine list pagination buttons"',
      interaction_details1: button,
    });
}
