import MenuItem from '@material-ui/core/MenuItem';
import { PureComponent } from 'react';
import { withTheme } from 'styled-components';

import { getHtmlArrow } from 'utils/dropdown.utils';
import { withElippsis } from 'utils/text.utils';

type Props = {
  arrowType: string,
  label: string,
};

class Option extends PureComponent<Props> {
  static defaultProps = {
    ellipsis: true,
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { label, arrowType, ellipsis, theme, ...rest } = this.props;

    return (
      <MenuItem {...rest}>
        {ellipsis ? withElippsis(label) : label}
        {arrowType && getHtmlArrow(arrowType)}
      </MenuItem>
    );
  }
}
export default withTheme(Option);
