import { FC } from 'react';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

import config from 'config/env';

import { getLocale } from 'store/app/app.selectors';

import { messages } from './config';

const IntlSelectorProvider: FC = ({ children }) => {
  const locale = useSelector(getLocale);

  return (
    <IntlProvider
      key={locale}
      locale={locale}
      defaultLocale={config.DEFAULT_LOCALE}
      // @ts-ignore
      messages={messages[locale]}
    >
      {children}
    </IntlProvider>
  );
};

export default IntlSelectorProvider;
