import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import startPointTypes from 'enums/startPoint.enum';

import type { WineType } from 'types/products.types';

export type Values = {
  shop_id?: string;
  sort_by?: string;
  sort_order?: string;
  search?: string;
  wine_type?: number;
};

export type State = {
  loading: boolean;
  canSubmit: boolean;
  steps: string[];
  values: Values;
  current: string;
  wine?: WineType;
  startPoint: typeof startPointTypes;
};

export const initialState: State = {
  loading: true,
  canSubmit: false,
  steps: [],
  values: {},
  current: '',
  wine: undefined,
  // @ts-ignore
  startPoint: startPointTypes.RESET,
};

const stepFilterSlice = createSlice({
  name: 'step-filter',
  initialState,
  reducers: {
    setCurrentStep: (state: State, { payload }: PayloadAction<string>) => {
      state.current = payload;
    },
    setSteps: (state: State, { payload }: PayloadAction<string[]>) => {
      state.steps = [...payload];
    },
    setStepFilterValues: (state: State, { payload }: PayloadAction<Values>) => {
      Object.keys(payload).forEach(
        // @ts-ignore
        (key) => payload[key] === undefined && delete payload[key],
      );
      state.values = { ...payload };
    },
    setStepFilterSorting: (
      state: State,
      { payload }: PayloadAction<Values>,
    ) => {
      state.values = { ...state.values, ...payload };
    },
    setStepFilterLoading: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = payload;
    },
    setWine: (state: State, { payload }: PayloadAction<WineType>) => {
      state.wine = { ...payload };
    },
    resetStepFilter: (state: State) => ({
      ...initialState,
      values: state.values.sort_by
        ? {
            // sorting filters should be remembered
            sort_by: state.values.sort_by,
            sort_order: state.values.sort_order,
          }
        : {},
    }),
    setStartPoint: (
      state: State,
      { payload }: PayloadAction<typeof startPointTypes>,
    ) => {
      state.startPoint = payload;
    },
    nextStep: () => {},
    prevStep: () => {},
    startStepFilter: () => {},
    chooseWineType: () => {},
  },
});

export const {
  setCurrentStep,
  setStepFilterLoading,
  setStepFilterValues,
  setStepFilterSorting,
  setSteps,
  nextStep,
  prevStep,
  resetStepFilter,
  startStepFilter,
  chooseWineType,
  setWine,
  setStartPoint,
} = stepFilterSlice.actions;

export default stepFilterSlice;
