import MUIToolbar from '@material-ui/core/Toolbar';
import styled from 'styled-components';

export const ModalToolbarStyled = styled(MUIToolbar)`
  && {
    background-color: ${({ theme }) => theme.palette.global01};
    min-height: 80px;
    box-sizing: border-box;
  }
`;

export default { ModalToolbarStyled };
