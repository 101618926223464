import { ReactNode } from 'react';

import { ButtonIcon02Styled, IconStyled } from './ButtonIcon02.styled';

type Props = {
  className: string,
  icon: ReactNode,
  isActive?: boolean,
  disabled?: boolean,
};

const ButtonIcon02 = ({
  icon,
  className,
  disabled,
  isActive,
  ...rest
}: Props) => (
  <ButtonIcon02Styled
    className={className}
    disableRipple
    disabled={disabled}
    {...rest}
  >
    <IconStyled isActive={isActive} disabled={disabled}>
      {icon}
    </IconStyled>
  </ButtonIcon02Styled>
);

export default ButtonIcon02;
