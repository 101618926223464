import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './KioskFilter' /* webpackChunkName: "kiosk-filter", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.KioskFilter,
  authenticated: false,
};
