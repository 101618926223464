import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import history from 'services/history';

import { Button01 } from 'components/@common/Button';
import Spacer from 'components/@common/Spacer';
import KioskWrapper from 'components/@kiosk/KioskWrapper';

import {
  Background,
  CenterWrapper,
  Paragraph,
  Wrapper,
  WrittenTitle,
} from './Error.styled';

type Props = {
  changeLanguageAction: Function,
  history: Object,
  analyticsLocale: string,
};

class GenericError extends PureComponent<Props> {
  // TODO: Change this to a component that handles the language switch
  handleGoBack = () => {
    history.push('/kiosk');
  };

  render() {
    return (
      <Wrapper>
        <Background />
        <CenterWrapper>
          <KioskWrapper>
            <Spacer bottom={16}>
              <WrittenTitle>
                <FormattedMessage id="b2c.error.something-went-wrong" />
              </WrittenTitle>
            </Spacer>
            <Spacer bottom={48}>
              <Paragraph>
                <FormattedMessage id="b2c.error.try-again-later" />
              </Paragraph>
            </Spacer>
            <Spacer bottom={48}>
              <Button01
                intlId="b2c.header.back-to-begin"
                color="primary"
                fullWidth
                onClick={this.handleGoBack}
              />
            </Spacer>
          </KioskWrapper>
        </CenterWrapper>
      </Wrapper>
    );
  }
}

export default GenericError;
