import { brandingTypes } from 'enums';
import { darken, rgba } from 'polished';

import {
  breakpoints as b2bBreakpoints,
  flavor as b2bFlavor,
  fonts as b2bFonts,
  fontSizes as b2bFontSizes,
  palette as b2bPalette,
  radius as b2bRadius,
  spacings as b2bSpacings,
} from './branding';
import spacingStyles from './spacingStyles';
import textStyles from './textStyles';
import zIndexes from './z-indexes';

export const palette = {
  ...b2bPalette,
};

export const fonts = {
  ...b2bFonts,
};

export const breakpoints = {
  ...b2bBreakpoints,
};

export const fontSizes = {
  ...b2bFontSizes,
  heading01: '80px',
  heading02: '56px',
  heading03: '40px',
  heading04: '28px',
  heading05: '14px',
  paragraph02: '16px',
  paragraph01: '20px',
};

export const spacings = {
  ...b2bSpacings,
};

export const radius = {
  ...b2bRadius,
  radius01: '16px',
  radius02: '12px',
};

export const flavor = {
  ...b2bFlavor,
  button01: {
    primary: {
      base: {
        borderRadius: radius.radius01,
        padding: '27px 24px',
        fontSize: '20px',
        iconSize: '30px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        hover: {
          backgroundColor: palette.global01,
        },
        active: {
          backgroundColor: darken(0.1, palette.global01),
          transform: 'scale(1.05)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
        },
      },
      dark: b2bFlavor.button01.primary.dark,
    },
    secondary: {
      base: {
        borderRadius: radius.radius01,
        padding: '27px 24px',
        fontSize: '20px',
        iconSize: '30px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: 'transparent',
        color: palette.global01,
        border: `2px solid ${palette.global01}`,
        hover: {
          backgroundColor: 'transparent',
        },
        active: {
          backgroundColor: rgba(palette.global01, 0.2),
          transform: 'scale(1.05)',
        },
        disabled: {
          backgroundColor: 'transparent',
          color: palette.neutral04,
          border: `2px solid ${palette.neutral06}`,
        },
      },
      dark: b2bFlavor.button01.secondary.dark,
    },
  },
  button01FullWidth: b2bFlavor.button01FullWidth,
  button02: {
    primary: {
      light: {
        color: palette.neutral02,
        fontSize: fontSizes.paragraph02,
        borderColor: darken(0.1, palette.neutral02),
        hover: {
          color: palette.neutral02,
        },
        active: {
          color: palette.neutral02,
          borderColor: palette.neutral02,
        },
        disabled: {
          color: palette.neutral04,
          borderColor: palette.neutral04,
        },
      },
      dark: {
        color: palette.neutral01,
        fontSize: fontSizes.paragraph02,
        borderColor: darken(0.1, palette.neutral01),
        hover: {
          color: palette.neutral01,
        },
        active: {
          color: palette.neutral01,
          borderColor: palette.neutral01,
        },
        disabled: {
          color: palette.neutral04,
          borderColor: palette.neutral04,
        },
      },
    },
    secondary: {
      light: {
        color: palette.global01,
        fontSize: fontSizes.paragraph01,
        borderColor: palette.global01,
        hover: {
          color: palette.neutral02,
        },
        active: {
          color: palette.neutral02,
          borderColor: palette.neutral02,
        },
        disabled: {
          color: palette.neutral04,
          borderColor: palette.neutral04,
        },
      },
    },
  },
  buttonicon01: {
    primary: {
      base: {
        padding: '12px',
      },
      dark: {
        backgroundColor: 'transparent',
        borderColor: palette.neutral01,
        color: palette.neutral01,
        hover: {
          backgroundColor: 'transparent',
        },
        active: {
          backgroundColor: 'transparent',
          borderColor: palette.neutral06,
        },
        disabled: {
          backgroundColor: palette.neutral05,
        },
      },
      light: {
        backgroundColor: 'transparent',
        borderColor: palette.neutral04,
        color: palette.neutral02,
        hover: {
          backgroundColor: 'transparent',
        },
        active: {
          backgroundColor: 'transparent',
          borderColor: palette.neutral02,
        },
        disabled: {
          backgroundColor: palette.neutral05,
        },
      },
    },
  },
  button05: {
    primary: {
      base: {
        borderRadius: radius.radius01,
        padding: '18px 16px',
        fontSize: '16px',
        iconSize: '24px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        hover: {
          backgroundColor: darken(0.1, palette.global01),
        },
        active: {
          backgroundColor: darken(0.1, palette.global01),
          transform: 'scale(1.05)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
        },
      },
    },
  },
  fullWidth: {
    primary: {
      base: {
        padding: '34px 46px',
        fontSize: '20px',
        fontWeight: 'bold',
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        hover: {
          backgroundColor: palette.global01,
        },
        active: {
          backgroundColor: darken(0.1, palette.global01),
          transform: 'scale(1.05)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
        },
      },
    },
    secondary: {
      base: {
        borderRadius: radius.radius01,
        padding: '27px 24px',
        fontSize: '20px',
        iconSize: '30px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: 'transparent',
        color: palette.global01,
        border: `2px solid ${palette.global01}`,
        hover: {
          backgroundColor: 'transparent',
        },
        active: {
          backgroundColor: rgba(palette.global01, 0.2),
          transform: 'scale(1.05)',
        },
        disabled: {
          backgroundColor: 'transparent',
          color: palette.neutral04,
          border: `2px solid ${palette.neutral06}`,
        },
      },
      dark: b2bFlavor.button01.secondary.dark,
    },
  },
};

export default {
  name: brandingTypes.B2C,
  fonts,
  palette,
  spacings,
  textStyles,
  spacingStyles,
  radius,
  fontSizes,
  breakpoints,
  zIndexes,
  flavor,
};
