import MUIButton from '@material-ui/core/Button';
import MUISvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';

export const ButtonStyled = styled(MUIButton)`
  && {
    :hover {
      background-color: transparent;
    }
  }
`;

export const SvgIconStyled = styled(MUISvgIcon)`
  position: relative;
`;
