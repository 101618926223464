import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import type { Node } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatLocation } from 'utils';
import {
  configLabels,
  formatSubRegionCountryLabel,
  sortByValue,
} from 'utils/product.utils';

import type { Price } from 'types/price.types';
import type { Product } from 'types/products.types';

import Checkbox01 from 'components/@common/Checkbox/Checkbox01';
import { ProductID } from 'components/@product';
import Specification from 'components/@product/Specification';
import SpecificationPrices from 'components/@product/Specification/SpecificationPrices';

import ProductLocation from '../ProductLocation';
import {
  AwardContainer,
  Button03Styled,
  IconsContainer,
  IconStyled,
  ImageGrid,
  ImageStyled,
  LocationContainer,
  ProductContentGrid,
  ProductContentGrid02,
  ProductGrid,
  ProductTitle,
} from './ProductView.styled';

type Props = {
  product: Product,
  circleIcon: Node,
  prices: Price[],
  onProductClick: () => void,
  onDelete?: () => void,
  onSelect?: (id: string) => void,
  isSelected: Boolean,
};

export default class ProductView extends Component<Props> {
  renderIcons = (icons) =>
    icons
      .slice()
      .sort(sortByValue)
      .map((icon, idx) => (
        <IconStyled
          key={parseInt(idx.toString(), 10)}
          src={_get(icon, 'b2bMedia.reference', '')}
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
      ));

  renderLabels = (labels) =>
    configLabels(labels).map((label, idx) => (
      <ImageStyled
        key={parseInt(idx.toString(), 10)}
        src={_get(label, 'media.reference', '')}
      />
    ));

  render() {
    const {
      product,
      prices = [],
      onProductClick,
      onDelete,
      isSelected,
      onSelect,
    } = this.props;

    return (
      <ProductGrid
        checked={isSelected}
        container
        direction="row"
        alignItems="center"
        onClick={onProductClick}
      >
        {onSelect && (
          <Grid item xs={2}>
            <Checkbox01 onChange={onSelect} checked={isSelected || false} />
          </Grid>
        )}
        <ImageGrid item xs={2}>
          <ImageStyled
            src={_get(product, 'media.880', '/assets/img/Rose01.jpg')}
          />
        </ImageGrid>
        <ProductContentGrid item xs={onSelect ? 8 : 9}>
          <ProductContentGrid02
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <ProductID
                id={product.productId}
                color={_get(product, 'wineType.colorHex', '')}
                desc={_get(product, 'wineType.value', '')}
                filled={_get(product, 'wineType.filled', '')}
              />
              <ProductTitle>{product.name}</ProductTitle>
              <Specification
                flag={_get(product, 'wineCountry.media.reference', '')}
                label={formatSubRegionCountryLabel(product)}
              />
              <IconsContainer>
                {this.renderIcons(_get(product, 'wineFoodPairings', []))}
              </IconsContainer>
            </Grid>
            <Grid item>
              <SpecificationPrices prices={prices} />
            </Grid>
          </ProductContentGrid02>
        </ProductContentGrid>
        <LocationContainer>
          {!!onDelete && (
            <Button03Styled
              onClick={(e) => {
                e.stopPropagation();
                onDelete();
              }}
            >
              <FormattedMessage id="product-view.remove-item.label" />
            </Button03Styled>
          )}
          {_get(product, 'shopInfo.published', true) && (
            <ProductLocation
              label={formatLocation(_get(product, 'shopInfo', {}))}
            />
          )}
        </LocationContainer>
        <AwardContainer>
          {this.renderLabels(_get(product, 'labels', []))}
        </AwardContainer>
      </ProductGrid>
    );
  }
}
