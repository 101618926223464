import styled from 'styled-components';

import { Heading05 } from 'theme/Heading/Heading.styled';
import { Paragraph01 } from 'theme/Paragraph';

import Flag from 'components/@product/Flag';

export const Title = styled(Heading05)`
  ${({ theme }) => theme.textStyles.heading05neutral03uppercase};
  ${({ theme }) => theme.spacingStyles.spacing02bottom};
  ${({ readOnly }) =>
    readOnly &&
    'font-size: 14px; letter-spacing: 1px; font-weight: normal; margin-top: 8px;'};
`;

export const Paragraph01Styled = styled(Paragraph01)`
  ${({ theme }) => theme.textStyles.paragraph01neutral02};
  ${({ theme }) => theme.spacingStyles.spacing01bottom};

  ${({ readOnly }) => readOnly && 'font-size: 20px;'};
`;

export const FlagStyled = styled(Flag)`
  margin-right: 8px;
`;

export const IconStyled = styled.img`
  && {
    height: 24px;
    width: 24px;
    position: relative;
    ${({ theme }) => theme.spacingStyles.spacing02right};
  }
`;

export const Percentage = styled.span`
  ${({ theme, readOnly }) =>
    !readOnly && theme.textStyles.paragraph01neutral02bold};
  ${({ theme, readOnly }) => !readOnly && theme.spacingStyles.spacing02right};
  ${({ readOnly }) =>
    readOnly &&
    'font-size: 20px; line-height: 28px; min-width: 60px; font-weight: bold;'};
  display: inline-block;
`;
