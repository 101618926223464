import MUITypography from '@material-ui/core/Typography';
import styled from 'styled-components';

export const ModalTitleStyled = styled(MUITypography)`
  && {
    flex: 1;
    ${({ theme }) => theme.textStyles.heading03neutral01};
  }
`;

export default { ModalTitleStyled };
