import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getUserShops = (state: StoreState) => _get(state, 'user.shops');
export const getActiveUserShop = (state: StoreState) =>
  _get(state, 'user.activeShop');
export const getActiveUserShopId = (state: StoreState) =>
  _get(state, 'user.activeShop.id');
export const getUserId = (state: StoreState) => _get(state, 'user.id');
export const getUser = (state: StoreState) => _get(state, 'user');
