import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './Kiosk' /* webpackChunkName: "kiosk", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.Kiosk,
  authenticated: false,
};
