export enum Routes {
  Root = '/',
  Error = '/error',

  Branch = '/branch',

  Catalog = '/catalog',

  Kiosk = '/kiosk',
  KioskFilter = '/kiosk/filter',
  KioskSearch = '/kiosk/search',
  KioskSuggestion = '/kiosk/suggestion',
  KioskCatalog = '/kiosk/catalog',
  KioskFeatured = '/kiosk/featured',
  KioskPreviewDetail = '/kiosk/preview/detail/:token',
  KioskPreviewFeatured = '/kiosk/preview/featured/:token',

  Login = '/login',

  RequestResetPassword = '/reset-password',
  ResetPassword = '/reset-password/:token',
}
