import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  selectedProductIds: number[];
  isLocationModalOpen: boolean;
  error?: boolean;
  isEditing: boolean;
  isUpdatingProducts: boolean;
  editAction: string;
};

export const initialState: State = {
  loading: false,
  selectedProductIds: [],
  isLocationModalOpen: false,
  isEditing: false,
  isUpdatingProducts: false,
  editAction: '',
};

const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    updateProductStart: (state: State) => {
      state.loading = true;
    },
    updateProductsStart: (state: State) => {
      state.loading = true;
    },
    deleteProductStart: (state: State) => {
      state.loading = true;
    },
    deleteProductsStart: (state: State) => {
      state.loading = true;
    },
    updateProductSuccess: (state: State) => {
      state.loading = false;
    },
    updateProductsSuccess: (state: State) => {
      state.loading = false;
      state.selectedProductIds = [];
      state.isLocationModalOpen = false;
      state.editAction = '';
    },
    deleteProductSuccess: (state: State) => {
      state.loading = false;
    },
    deleteProductsSuccess: (state: State) => {
      state.loading = false;
      state.selectedProductIds = [];
      state.editAction = '';
    },
    updateProductFailed: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = false;
      state.error = payload;
    },
    updateProductsFailed: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = false;
      state.error = payload;
    },
    deleteProductFailed: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = false;
      state.error = payload;
    },
    deleteProductsFailed: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.loading = false;
      state.error = payload;
    },
    updateSelectedProducts: (
      state: State,
      { payload }: PayloadAction<{ id: number }>,
    ) => {
      state.selectedProductIds = (state.selectedProductIds || []).includes(
        payload.id,
      )
        ? (state.selectedProductIds || []).filter((i) => i !== payload.id)
        : [...(state.selectedProductIds || []), payload.id];
    },
    selectProducts: (state: State, { payload }: PayloadAction<number[]>) => {
      state.selectedProductIds = payload || [];
    },
    setLocationModalOpen: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLocationModalOpen = payload;
    },
    toggleIsEditing: (state: State) => {
      state.isEditing = !state.isEditing;
    },
    setEditingAction: (state: State, { payload }: PayloadAction<string>) => {
      state.editAction = payload;
    },
    setIsUpdatingProducts: (
      state: State,
      { payload }: PayloadAction<string>,
    ) => {
      // @ts-ignore
      state.isUpdatingProducts = payload;
    },
  },
});

export const {
  updateProductStart,
  updateProductSuccess,
  updateProductFailed,
  updateProductsStart,
  updateProductsSuccess,
  updateProductsFailed,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductFailed,
  deleteProductsStart,
  deleteProductsSuccess,
  deleteProductsFailed,
  updateSelectedProducts,
  selectProducts,
  setLocationModalOpen,
  toggleIsEditing,
  setEditingAction,
  setIsUpdatingProducts,
} = productSlice.actions;

export default productSlice;
