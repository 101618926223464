import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  showIntroModal: boolean;
  allProducts: {
    meta: Object;
  };
  products: {
    data: unknown[];
    meta: Object;
    links: Object;
  };
  error?: unknown;
  paging: { pageNumber: number; itemsPerPage: number };
};

export const initialState: State = {
  loading: false,
  showIntroModal: false,
  allProducts: {
    meta: {},
  },
  products: {
    data: [],
    meta: {},
    links: {},
  },
  paging: { pageNumber: 1, itemsPerPage: 24 },
};

const kioskSlice = createSlice({
  name: 'kiosk',
  initialState,
  reducers: {
    redirectToHomeStart: () => {},
    redirectToHomeSuccess: () => {},
    redirectToHomeFailed: () => {},
    fetchKioskFeaturedStart: () => {},
    fetchKioskFeaturedProductsSuccess: () => {},
    fetchKioskFeaturedProductsFailed: () => {},
    resetKiosk: () => initialState,
    fetchKioskProductsStart: (state: State) => {
      state.loading = true;
    },
    fetchKioskSuggestionProductsStart: (state: State) => {
      state.loading = true;
    },
    fetchKioskProductsSuccess: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      state.loading = false;
      state.products = {
        // @ts-ignore
        ...payload,
      };
    },
    fetchKioskSuggestionProductsSuccess: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      state.loading = false;
      state.products = {
        // @ts-ignore
        ...payload,
      };
    },
    fetchKioskSuggestionProductsFailed: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      // ...initialState,
      state.error = payload;
    },
    fetchKioskProductsFailed: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => ({
      ...initialState,
      error: payload,
    }),
    setKioskProductsLoading: (state: State) => {
      state.loading = true;
    },
    setKioskProductsPaging: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      state.loading = true;
      state.paging = {
        ...state.paging,
        // @ts-ignore
        ...payload,
      };
    },
  },
});

export const {
  redirectToHomeStart,
  redirectToHomeSuccess,
  redirectToHomeFailed,
  resetKiosk,
  fetchKioskProductsStart,
  fetchKioskSuggestionProductsStart,
  fetchKioskProductsSuccess,
  fetchKioskSuggestionProductsSuccess,
  fetchKioskSuggestionProductsFailed,
  fetchKioskProductsFailed,
  setKioskProductsLoading,
  setKioskProductsPaging,
  fetchKioskFeaturedStart,
  fetchKioskFeaturedProductsSuccess,
  fetchKioskFeaturedProductsFailed,
} = kioskSlice.actions;

export default kioskSlice;
