import GeneralAnalytics from 'services/analytics/GeneralAnalytics';
import { AnalyticsFunction } from 'types/analytics.types';

export default class SearchAnalytics extends GeneralAnalytics {
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(args: AnalyticsFunction) {
    super(args);
  }

  trackEventSearchB2C = (
    filteringSearchDetails?: string,
    itemCount?: number,
    searchMethod?: string,
    searchOrigin?: string,
  ) => {
    let filteringSearchDetailsFormatted = filteringSearchDetails;
    filteringSearchDetailsFormatted = filteringSearchDetailsFormatted || '';

    this.track({
      event_name: 'search',
      search_category: 'product',
      search_term: filteringSearchDetailsFormatted,
      search_results: itemCount,
      search_method: searchMethod,
      search_origin: searchOrigin,
    });
  };
}
