import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button01 from 'components/@common/Button/Button01';
import IcoScan from 'components/@icons/icons/IcoScan';
import IcoSearch from 'components/@icons/icons/IcoSearch';
import Modal02 from 'components/@modals/Modal02';

import {
  Heading04Styled,
  OrStyled,
  Paragraph01Styled,
  WrapperStyled,
} from './IntroModal.styled';

export type IntroModalProps = {
  open: boolean,
  onClose?: Function,
  onSearch: () => void,
  onScan: () => void,
};

export default class IntroModal extends Component<IntroModalProps> {
  render() {
    const { open, onClose, onSearch, onScan } = this.props;
    return (
      <Modal02
        title={<FormattedMessage id="modal.intro.title" />}
        open={open}
        onClose={onClose}
      >
        <Paragraph01Styled>
          <FormattedMessage id="modal.intro.paragraph" />
        </Paragraph01Styled>
        <Heading04Styled>
          <FormattedMessage id="modal.intro.heading" />
        </Heading04Styled>
        <WrapperStyled>
          <Button01
            onClick={onSearch}
            iconLeft={<IcoSearch />}
            color="primary"
            intlId="modal.intro.search"
          />
          <OrStyled>
            <Paragraph01Styled>
              <FormattedMessage id="modal.intro.or" />
            </Paragraph01Styled>
          </OrStyled>
          <Button01
            onClick={onScan}
            iconLeft={<IcoScan />}
            color="primary"
            intlId="modal.intro.scan"
          />
        </WrapperStyled>
      </Modal02>
    );
  }
}
