import Slide from '@material-ui/core/Slide';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Component, ReactNode } from 'react';

import IcoClose from 'components/@icons/icons/IcoClose';

import {
  ButtonCloseStyled,
  DialogStyled,
  ModalContent,
  ModalTitle,
} from './Modal02.styled';

type Props = {
  children?: ReactNode,
  title?: string,
  onClose: () => void,
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Modal02 extends Component<Props> {
  render() {
    const { title, children, onClose, ...rest } = this.props;

    return (
      <DialogStyled {...rest} TransitionComponent={Transition}>
        <ModalContent>
          <ButtonCloseStyled isDark onClick={onClose}>
            <SvgIcon>
              <IcoClose />
            </SvgIcon>
          </ButtonCloseStyled>
          <ModalTitle>{title}</ModalTitle>
          {children}
        </ModalContent>
      </DialogStyled>
    );
  }
}

export default Modal02;
