import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Component } from 'react';

import type { MenuItem } from 'types/menu.types';

import {
  ButtonStyled,
  PaperStyled,
  PopperStyled,
} from 'components/@common/Menu/MenuPopper/MenuPopper.styled';

type Props = {
  menuItems: MenuItem[],
  onMenuItemClick: (menuItem: MenuItem) => void,
};

export default class MenuPopper extends Component<Props> {
  handleClick = (menuItem: MenuItem) => {
    this.props.onMenuItemClick(menuItem);
  };

  renderMenuItems = () => {
    const { menuItems } = this.props;
    return (
      menuItems &&
      menuItems.map((item) => {
        const { active, label } = item;

        return (
          <ButtonStyled
            data-tms-navigation-section={
              label === 'FR' || label === 'NL' ? 'language switch' : ''
            }
            key={label}
            active={!!active}
            onClick={() => this.handleClick(item)}
          >
            {label}
          </ButtonStyled>
        );
      })
    );
  };

  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onClickAway, menuItems, onMenuItemClick, ...rest } = this.props;
    return (
      <PopperStyled {...rest}>
        <ClickAwayListener onClickAway={onClickAway}>
          <PaperStyled>
            {menuItems && menuItems.length > 0 && <>{this.renderMenuItems()}</>}
          </PaperStyled>
        </ClickAwayListener>
      </PopperStyled>
    );
  }
}
