import { PureComponent } from 'react';

import { AppLoading } from 'components/@common';

type Props = {
  appLoading: boolean,
  loggedIn: boolean,
};
export default class AppLoadingWrapper extends PureComponent<Props> {
  render() {
    const { appLoading } = this.props;
    return appLoading ? <AppLoading /> : null;
  }
}
