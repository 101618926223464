import styled from 'styled-components';

import { Heading05 } from 'theme/Heading/Heading.styled';
import { Paragraph01 } from 'theme/Paragraph';

export const Title = styled(Heading05)`
  ${({ theme }) => theme.textStyles.heading05neutral03uppercase};
  ${({ theme }) => theme.spacingStyles.spacing02bottom};
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 1px;
`;

export const Item = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacings.spacing04};
`;

export const Paragraph01Styled = styled(Paragraph01)`
  font-size: ${({ theme }) => theme.fontSizes.paragraph01};
  color: ${({ theme }) => theme.palette.black};
`;

export const IconStyled = styled.img`
  && {
    height: 48px;
    width: 48px;
    position: relative;
    margin-right: 12px;
  }
`;
