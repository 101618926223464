import { Component } from 'react';

import IcoSettings from 'components/@icons/icons/IcoSettings';
import IcoTriangleDown from 'components/@icons/icons/IcoTriangleDown';

import { ButtonStyled, SvgIconStyled } from './MenuIconButton.styled';

export default class MenuIconButton extends Component {
  render() {
    const { ...rest } = this.props;
    return (
      <ButtonStyled disableRipple {...rest}>
        <SvgIconStyled>
          <IcoSettings />
        </SvgIconStyled>
        <SvgIconStyled>
          <IcoTriangleDown />
        </SvgIconStyled>
      </ButtonStyled>
    );
  }
}
