import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Heading01 } from 'theme/Heading';
import { heading01neutral02bold } from 'theme/textStyles/heading';

import { ButtonIcon01 } from 'components/@common/Button';

export const DialogStyled = styled(Dialog)`
  && {
    > div:last-child {
      margin: 0 auto;
      max-width: 620px;
    }

    ${breakpoint('sm', 'md')`
      > div:last-child {
        max-width: 595px;
      }
    `};

    ${breakpoint('xs', 'sm')`
      > div:last-child {
        max-width: none;
        margin: 0;
      }
    `};
  }
`;

export const ButtonCloseStyled = styled(ButtonIcon01)`
  && {
    position: absolute;
    top: 0;
    right: 0;
    color: ${({ theme }) => theme.palette.neutral04};
  }
`;

export const ModalContent = styled.div`
  position: relative;
  padding: 32px 24px;
  max-width: none;
  > :not(:last-child) {
    margin-bottom: 24px;
  }

  > :nth-last-child(2) {
    margin-bottom: 32px;
  }
`;

export const ModalTitle = styled(Heading01)`
  ${heading01neutral02bold};
`;
