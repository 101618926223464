import Grid from '@material-ui/core/Grid';
import type { Node } from 'react';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button01 } from 'components/@common/Button';
import Modal02 from 'components/@modals/Modal02';

type Props = {
  onAccept: () => void,
  onCancel: () => void,
  cancelLabel: string,
  acceptLabel: string,
  children: Node,
};

export default class ApproveModal extends PureComponent<Props> {
  render() {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { onAccept, onCancel, acceptLabel, cancelLabel, children, ...rest } =
      this.props;
    return (
      <Modal02 {...rest}>
        {children}
        <Grid container direction="row" justifyContent="flex-end" spacing={1}>
          <Grid item>
            <Button01 onClick={onCancel} color="secondary">
              <FormattedMessage id="common.button.cancel" />
            </Button01>
          </Grid>
          <Grid item>
            <Button01 onClick={onAccept} color="primary">
              {acceptLabel}
            </Button01>
          </Grid>
        </Grid>
      </Modal02>
    );
  }
}
