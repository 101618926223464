import { arrowTypes, brandingTypes, sortTypes } from 'enums';
import _sortBy from 'lodash/sortBy';

type DropdownSortConfig = {
  label: string;
  arrowType?: string;
};

export const getHtmlArrow = (sort: string) => {
  switch (sort) {
    case sortTypes.DESC: {
      return String.fromCharCode(arrowTypes.UP);
    }
    case sortTypes.ASC: {
      return String.fromCharCode(arrowTypes.DOWN);
    }
    default:
      return null;
  }
};

const dropDownWithArrows = (items: DropdownSortConfig[], arrowType: string) =>
  items.map((item) => ({
    ...item,
    arrowType,
  }));

const getB2cFilters = (messages: unknown) => ({
  // @ts-ignore
  av_price: messages['b2c.product-search.dropdown.av-price'],
  // @ts-ignore
  relevance: messages['b2c.product-search.dropdown.relevance'],
});

const getB2bFilters = (messages: Object) => ({
  // @ts-ignore
  name: messages['product-search.dropdown.name'],
  // @ts-ignore
  product_id: messages['product-search.dropdown.product-id'],
  // @ts-ignore
  av_price: messages['product-search.dropdown.av-price'],
  // @ts-ignore
  aw_price: messages['product-search.dropdown.aw-price'],
  // @ts-ignore
  country: messages['product-search.dropdown.country'],
  // @ts-ignore
  location: messages['product-search.dropdown.location'],
  // @ts-ignore
  relevance: messages['product-search.dropdown.relevance'],
});

export const configureDropdownSort = (
  messages: Object,
  brandingType: string,
) => {
  const filters =
    brandingType === brandingTypes.B2C
      ? getB2cFilters(messages)
      : getB2bFilters(messages);

  const items = Object.keys(filters).reduce((acc, filter) => {
    if (filter !== 'relevance') {
      // @ts-ignore
      acc.push({
        filter,
        // @ts-ignore
        label: filters[filter],
      });
      return acc;
    }
    return acc;
  }, []);
  const dropDownItems = [
    ...dropDownWithArrows(items.filter(Boolean), sortTypes.DESC),
    ...dropDownWithArrows(items.filter(Boolean), sortTypes.ASC),
  ];

  if (Object.keys(filters).includes('relevance')) {
    // @ts-ignore
    dropDownItems.unshift({ filter: 'relevance', label: filters.relevance });
  }

  return _sortBy(dropDownItems, ['filter']);
};
