import modalTypes from 'enums/modal.enum';
import { PureComponent } from 'react';

import { coreAnalytics } from 'services/analytics/Tealium';

import { IntroModal } from 'components/@modals';

type Props = {
  closeModalAction: () => void,
  openModalAction: (payload: Object) => void,
  startScan: () => void,
  modalType: string,
};

class Intro extends PureComponent<Props> {
  handleClose = () => {
    const { closeModalAction } = this.props;

    closeModalAction();
  };

  handleSearch = () => {
    coreAnalytics.trackEventOnboardingIntromodalInteraction();
    this.handleClose();
  };

  handleScan = () => {
    const { closeModalAction, openModalAction, startScan } = this.props;

    closeModalAction();

    openModalAction({
      type: modalTypes.SCANNING,
    });

    startScan();
  };

  render() {
    const { modalType } = this.props;

    const open = modalType === modalTypes.INTRO;

    return (
      <IntroModal
        open={open}
        onClose={this.handleClose}
        onSearch={this.handleSearch}
        onScan={this.handleScan}
      />
    );
  }
}

export default Intro;
