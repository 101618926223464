/* eslint-disable no-param-reassign */
/* eslint-disable import/no-unresolved */
import axios from 'axios';
import humps from 'humps';

import config from 'config/env';

import { isDev } from 'utils/env.utils';

import { store } from 'store';
import { getKiosk, getLocale } from 'store/app/app.selectors';
import { getAccessToken } from 'store/auth/auth.selectors';

const { API_BASE } = config;

// @ts-ignore
function getBetterErrorByResponse(err) {
  const res = err.response;
  const apiErrors = res.data.errors;

  if (apiErrors && apiErrors.length > 0) {
    const { detail, title } = apiErrors[0];
    return `${title}: ${detail}`;
  }

  if (isDev()) {
    return `${res.status}: ${res.statusText}`;
  }

  return err.message;
}

const axiosApi = axios.create({
  baseURL: API_BASE,
  headers: {
    'Content-Type': 'application/json',
    'X-Client-Token': 1,
    'X-oAuth-Refresh-Type': 'httpOnlyCookie',
    'Access-Control-Allow-Origin': '*',
  },
  timeout: 30000,
  transformRequest: [
    (data) => {
      // convert all body keys to snake_case

      if (data) {
        data = humps.decamelizeKeys(data);
        // convert all body objects to strings
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
      }

      return data;
    },
  ],
  transformResponse: [
    (data) => {
      // convert all body keys to snake_case
      if (data) {
        data = humps.camelizeKeys(JSON.parse(data));
      }
      return data;
    },
  ],
});

// Add a request interceptor
axiosApi.interceptors.request.use(
  (axiosConfig) => {
    const state = store.getState();

    const accessToken = getAccessToken(state);
    const kiosk = getKiosk(state);
    const locale = getLocale(state);
    // @ts-ignore
    axiosConfig.headers = {
      ...axiosConfig.headers,
      ...(!kiosk && { Authorization: `Bearer ${accessToken}` }),
      'Accept-Language': locale,
    };
    return axiosConfig;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  },
);

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error;
    if (
      response &&
      response.status === 401 &&
      response.url &&
      !response.url.includes('login')
    ) {
      console.error(error);
    }
    error.message = getBetterErrorByResponse(error);
    return Promise.reject(error);
  },
);

export default axiosApi;
