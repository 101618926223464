import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getLoggedIn = (state: StoreState) => _get(state, 'auth.loggedIn');
export const isLoading = (state: StoreState) => _get(state, 'auth.isLoading');
export const getAccessToken = (state: StoreState) =>
  _get(state, 'auth.authData.accessToken');
export const getError = (state: StoreState) => _get(state, 'auth.error');
export const getAuthData = (state: StoreState) => _get(state, 'auth.authData');
