import _get from 'lodash/get';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatLocation } from 'utils';

import type { Price } from 'types/price.types';
import type { Product } from 'types/products.types';

import { Spinner } from 'components/@common';
import SpecificationPrices from 'components/@product/Specification/SpecificationPrices';
import WineRack from 'components/@product/WineRack';

import {
  FullWidthGrid,
  GridContainer,
  ProductImageStyled,
} from './PDLeft.styled';

type Props = {
  product: Product,
  prices: Price[],
  onAdd: () => void,
  onRemove: () => void,
  onMove: () => void,
  locationLoading: boolean,
};

export default class PDLeft extends PureComponent<Props> {
  render() {
    const {
      product,
      prices = [],
      onAdd,
      onRemove,
      onMove,
      locationLoading,
    } = this.props;

    const { shopInfo } = product;

    // Boolean function to make sure 0 is false
    const hasPrices = Boolean(
      shopInfo && (shopInfo.priceAv || shopInfo.priceAw),
    );

    return (
      <GridContainer container direction="column" alignItems="center">
        <FullWidthGrid item>
          <ProductImageStyled
            labels={_get(product, 'labels', [])}
            src={_get(product, 'media.880')}
          />
        </FullWidthGrid>

        {hasPrices && (
          <FullWidthGrid item>
            <SpecificationPrices
              title={<FormattedMessage id="product.details.spec.price" />}
              prices={prices}
            />
          </FullWidthGrid>
        )}

        <FullWidthGrid item>
          {locationLoading && <Spinner />}
          {!locationLoading && (
            <WineRack
              onAdd={onAdd}
              onRemove={onRemove}
              onMove={onMove}
              location={formatLocation(_get(product, 'shopInfo', {}))}
              inProductRange={_get(product, 'shopInfo.published', false)}
            />
          )}
        </FullWidthGrid>
      </GridContainer>
    );
  }
}
