import { connect } from 'react-redux';

import { getIsLoading } from 'store/app/app.selectors';
import { getLoggedIn } from 'store/auth/auth.selectors';

import AppLoadingWrapper from './AppLoadingWrapper';

const mapStateToProps = (state) => ({
  appLoading: getIsLoading(state),
  loggedIn: getLoggedIn(state),
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(AppLoadingWrapper);
