import Snackbar from '@material-ui/core/Snackbar';
import styled from 'styled-components';

export const ToastStyled = styled(Snackbar)`
  && {
    position: fixed;
    margin-top: 90px;
  }
`;

export default { ToastStyled };
