import styled from 'styled-components';

import { Heading05 } from 'theme/Heading';
import { Paragraph01 } from 'theme/Paragraph';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 16px;
  border-radius: 16px;
  background-color: white;

  :hover {
    cursor: pointer;
  }
`;

export const Name = styled(Heading05)`
  font-weight: bold;
  font-size: 20px;
  color: #ed3943;
  margin: 6px 0;
`;

export const Flag = styled.img`
  width: 19px;
  height: 14px;
  padding-right: 8px;
`;

export const ImageContainer = styled.div`
  flex: 1;
`;

export const Content = styled.div`
  flex: 3;
`;

export const Paragraph01Styled = styled(Paragraph01)`
  font-size: 16px;
`;

export const InfoTagWrapper = styled.div`
  height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: ${({ maxWidth }) => maxWidth || 56}px;
  margin-left: ${({ marginLeft }) => marginLeft || 0}px;
`;
