import { CircleStyled, Heading05Styled } from './ProductID.styled';

type Props = {
  id: string,
  color: string,
  desc: string,
  filled: boolean,
  readOnly: boolean,
};

const ProductID = ({ id, desc, color, filled, readOnly }: Props) => (
  <Heading05Styled readOnly={readOnly}>
    {id}
    <CircleStyled
      readOnly={readOnly}
      fontSize="small"
      color={color}
      filled={filled}
    />
    {desc}
  </Heading05Styled>
);

export default ProductID;
