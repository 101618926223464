import modalTypes from 'enums/modal.enum';
import { Component } from 'react';

import { KioskModal } from 'components/@modals';

type Props = {
  modalType: string,
  setKioskAction: (payload: boolean) => void,
  closeModalAction: () => void,
};
class Kiosk extends Component<Props> {
  handleClose = () => {
    const { closeModalAction } = this.props;

    closeModalAction();
  };

  handleAccept = () => {
    const { closeModalAction, setKioskAction } = this.props;

    closeModalAction();
    setKioskAction(true);
  };

  render() {
    const { modalType } = this.props;
    const open = modalType === modalTypes.KIOSK;
    return (
      <KioskModal
        open={open}
        onClose={this.handleClose}
        onAccept={this.handleAccept}
      />
    );
  }
}

export default Kiosk;
