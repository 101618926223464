import MUIAppBar from '@material-ui/core/AppBar';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const ModalAppBarStyled = styled(MUIAppBar)`
  && {
    background-color: ${({ theme }) => theme.palette.global01};
    box-shadow: none;
    ${breakpoint('md')`
      position: relative;
    `};

    ${breakpoint('xs', 'md')`
      position: relative;
    `};
  }
`;

export default { ModalAppBarStyled };
