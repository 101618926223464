import styled from 'styled-components';

export const LabelStyled = styled.label`
  ${({ theme, big }) =>
    big ? 'font-size: 20px;' : theme.textStyles.paragraph01};
`;

export const SelectView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  ${LabelStyled}:first-child {
    margin-right: 8px;
  }
  ${LabelStyled}:last-child {
    margin-left: 8px;
  }
`;
