import Grid from '@material-ui/core/Grid';
import { rgba } from 'polished';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const GridStyled = styled(({ inProductRange, ...rest }) => (
  <Grid {...rest} />
))`
  && {
    background-color: ${({ theme }) => rgba(theme.palette.global02, 0.15)};
    border-radius: 8px;
    ${({ inProductRange, theme }) =>
      !inProductRange &&
      `
      background-color: ${theme.palette.neutral05};
  `};

    ${breakpoint('sm')`
      padding: 12px 12px;
      ${({ readOnly }) => readOnly && 'padding: 8px 8px;'};
      flex-direction: column;
      justify-content: center;
      align-items: center;
  `};

    ${breakpoint('xs', 'sm')`
      width: 177px;
      padding: 0 12px;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
  `};
  }
`;

export const WinerackContainerReadOnly = styled.div`
  background-color: ${({ theme }) => rgba(theme.palette.global02, 0.15)};
  display: inline-block;
  padding: 8px 24px;
  border-radius: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  box-sizing: border-box;
`;

export default { GridStyled };
