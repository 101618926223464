import { Component } from 'react';

import type { Price } from 'types/price.types';
import type { Product } from 'types/products.types';

import PDLeftReadOnly from './PDLeft/PDLeftReadOnly';
import PDRightReadOnly from './PDRight/PDRightReadOnly';
import {
  GridContainerStyled,
  LeftGrid,
  RightGrid,
  Wrapper,
} from './ProductDetails.styled';

type Props = {
  readOnly: boolean,
  prices: Price[],
  product: Product,
  productLoading: boolean,
};

export default class ProductDetailsReadOnly extends Component<Props> {
  render() {
    const { product, prices = [], productLoading } = this.props;

    return (
      <Wrapper>
        <GridContainerStyled container direction="row">
          <LeftGrid item xs={4}>
            <PDLeftReadOnly
              locationLoading={productLoading}
              product={product}
              prices={prices}
            />
          </LeftGrid>
          <RightGrid item xs={8}>
            <PDRightReadOnly product={product} />
          </RightGrid>
        </GridContainerStyled>
      </Wrapper>
    );
  }
}
