import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Product } from 'types/products.types';

type State = {
  showIntroModal: boolean;
  products: Product[];
};

export const initialState: State = {
  showIntroModal: false,
  products: [],
};

const catalogSlice = createSlice({
  name: 'catalog',
  initialState,
  reducers: {
    setIntroModalAction: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.showIntroModal = payload;
    },
  },
});

export const { setIntroModalAction } = catalogSlice.actions;

export default catalogSlice;
