import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './Error' /* webpackChunkName: "error", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.Error,
  authenticated: false,
};
