import { ToastContentBaseStyled } from './ToastContentBase.styled';

type Props = {
  variant: string,
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ToastContentBase = ({ variant, ...rest }: Props) => (
  <ToastContentBaseStyled {...rest} />
);

export default ToastContentBase;
