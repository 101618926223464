import { MuiThemeProvider } from '@material-ui/core/styles';
import { brandingTypes } from 'enums';
import { ReactNode, useEffect, useState } from 'react';
import { createGlobalStyle, ThemeProvider } from 'styled-components';

import { store } from 'store';
import { getKiosk } from 'store/app/app.selectors';
import branding from 'theme/branding';
import brandingB2C from 'theme/brandingB2C';
import muiTheme from 'theme/muiTheme';
import styledReset from 'theme/styledReset';

const GlobalStyle = createGlobalStyle`
  ${styledReset}
`;

interface Props {
  children: ReactNode;
}

const ThemeContext = ({ children }: Props) => {
  const [currentTheme, setCurrentTheme] = useState<unknown>(branding);

  useEffect(() => {
    store.subscribe(checkForUpdate);
  });

  const checkForUpdate = () => {
    const state = store.getState();
    const isKiosk = getKiosk(state);

    // If kiosk is true, we want to change the full theme to branding b2c
    // @ts-ignore
    if (isKiosk && currentTheme.name === brandingTypes.B2B) {
      setCurrentTheme(brandingB2C);
      // @ts-ignore
    } else if (!isKiosk && currentTheme.name === brandingTypes.B2C) {
      // @ts-ignore
      setCurrentTheme(branding);
    }
  };

  return (
    <ThemeProvider theme={currentTheme}>
      <MuiThemeProvider theme={muiTheme}>
        {children}
        <GlobalStyle />
      </MuiThemeProvider>
    </ThemeProvider>
  );
};

export default ThemeContext;
