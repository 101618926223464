import type { Node } from 'react';
import { PureComponent } from 'react';

import {
  FlagStyled,
  IconStyled,
  Paragraph01Styled,
  Percentage,
  Title,
} from './Specification.styled';

type Props = {
  title: string,
  label: string,
  flag?: string,
  icon?: Node,
  percentage?: number,
  readOnly?: boolean,
};

export default class Specification extends PureComponent<Props> {
  hasIcon = (icon?: Node) =>
    icon && (
      <IconStyled
        src={icon}
        alt=""
        onError={(e) => {
          e.target.style.display = 'none';
        }}
      />
    );

  hasPercentage = (percentage?: number) =>
    percentage && (
      <Percentage readOnly={this.props.readOnly}>
        {percentage}
        &#37;
      </Percentage>
    );

  render() {
    const { title, label, icon, percentage, flag, readOnly } = this.props;

    return (
      <>
        {title && <Title readOnly={readOnly}>{title}</Title>}
        <Paragraph01Styled readOnly={readOnly}>
          {flag && <FlagStyled src={flag} h={20} w={20} />}
          {this.hasIcon(icon)}
          {this.hasPercentage(percentage)}
          {label || '-'}
        </Paragraph01Styled>
      </>
    );
  }
}
