import { ReactNode } from 'react';

import { ButtonLabelStyled } from './ButtonLabel.styled';

type Props = {
  className?: string,
  children?: ReactNode,
  ellipsis?: boolean,
};

const ButtonLabel = ({ className, ellipsis, children }: Props) => (
  <ButtonLabelStyled className={className} ellipsis={ellipsis ? 1 : 0}>
    {children}
  </ButtonLabelStyled>
);

export default ButtonLabel;
