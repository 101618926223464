import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './KioskCatalog' /* webpackChunkName: "kiosk-catalog", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.KioskCatalog,
  authenticated: false,
};
