import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import ProductImage from 'components/@product/ProductImage';

export const Label = styled.h5`
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.heading05};
  color: ${({ theme }) => theme.palette.neutral03};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: normal;
  text-transform: uppercase;
  margin-top: 24px;
  margin-bottom: 4px;
  letter-spacing: 1px;
`;

export const Text = styled.p`
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.paragraph02};
  color: ${({ theme }) => theme.palette.black};
  font-family: ${({ theme }) => theme.fonts.regular};
  font-weight: normal;
  letter-spacing: 0;
  margin: 0;
`;

export const FullWidthGrid = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const GridContainer = styled(Grid)`
  > ${FullWidthGrid} {
    padding: 12px 0;
  }
`;

export const ProductImageStyled = styled(ProductImage)`
  width: 214px;
`;
