import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import { FormattedMessage, injectIntl, type IntlShape } from 'react-intl';

import { Button01 } from 'components/@common/Button';
import Button02 from 'components/@common/Button/Button02';

import Menu from '../../@common/Menu';

type Props = {
  intl: IntlShape,
  actions?: { label: string, value: string }[],
  disabled?: boolean,
  onSubmit: (actionValue: string) => void,
  onCancel?: () => void,
  value: string,
  onItemChange: (action: string) => void,
};

class ActionBar extends PureComponent<Props> {
  defaultActions = [
    {
      value: '',
      label: this.props.intl.formatMessage({
        id: 'action-bar.actions.choose-action.label',
      }),
    },
    {
      value: 'edit',
      label: this.props.intl.formatMessage({
        id: 'action-bar.actions.edit-action.label',
      }),
    },
    {
      value: 'remove',
      label: this.props.intl.formatMessage({
        id: 'action-bar.actions.remove-action.label',
      }),
    },
  ];

  render() {
    const { actions, onSubmit, onCancel, disabled, value, onItemChange } =
      this.props;
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={1}
        style={{ padding: '0 24px' }}
      >
        {!!onCancel && (
          <Grid
            item
            xs={2}
            style={{ display: 'flex' }}
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <Button02
              onClick={onCancel}
              intlId="action-bar.button.cancel-edit"
            />
          </Grid>
        )}
        <Grid
          item
          xs={onCancel ? 6 : 8}
          style={{ display: 'flex' }}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Menu
            disabled={disabled}
            menuItems={(actions || this.defaultActions).map((a) => ({
              ...a,
              active: a.value === value,
            }))}
            onMenuItemSelect={(menuItem) => onItemChange(menuItem.value)}
          />
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: 'flex' }}
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Button01 disabled={!value || disabled} onClick={onSubmit}>
            <FormattedMessage id="products-modal.submit.label" />
          </Button01>
        </Grid>
      </Grid>
    );
  }
}

export default injectIntl(ActionBar);
