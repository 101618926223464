import CircularProgress from '@material-ui/core/CircularProgress';
import styled, { css } from 'styled-components';

// @ts-ignore
const setColor = ({ theme, color }) => css`
  color: ${color || theme.palette.global01};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SpinnerStyled = styled(({ color, ...rest }) => (
  <CircularProgress {...rest} />
))`
  && {
    ${(props) => setColor(props)};
  }
`;

export const SpinnerView = styled.div`
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`;
