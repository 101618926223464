import { lazy, Suspense } from 'react';
import { Router, Switch } from 'react-router-dom';

import history from 'services/history';

import RouteBoundary from 'components/@boundaries/RootBoundary/RootBoundary';
import { RouteProtected, RoutePublic } from 'components/@common/Route';

import routes from './routes';

const NotFound = lazy(
  () => import('./not-found' /* webpackChunkName: "not-found" */),
);

const Pages = () => (
  <RouteBoundary>
    <Router history={history}>
      <Suspense fallback={null}>
        <Switch>
          {routes.map((route) => {
            const routeProps = {
              key: route.path?.toString(),
              exact: route.exact,
              path: route.path,
              component: route.component,
              authenticated: route.authenticated,
            };

            return route.authenticated ? (
              <RouteProtected {...routeProps} />
            ) : (
              <RoutePublic {...routeProps} />
            );
          })}
          <RoutePublic component={NotFound} />
        </Switch>
      </Suspense>
    </Router>
  </RouteBoundary>
);

export default Pages;
