const IcoArrowLeft = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 20l1.41-1.41L7.83 13H20v-2H7.83l5.58-5.59L12 4l-8 8z"
      fill="currentColor"
    />
  </svg>
);

export default IcoArrowLeft;
