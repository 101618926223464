import { modalTypes } from 'enums/index';
import { Component } from 'react';
import { connect } from 'react-redux';

import { wineAnalytics } from 'services/analytics/Tealium';
import { getKiosk, getLocale } from 'store/app/app.selectors';
import { openModalAction } from 'store/modal/modal.slice';
import { stopScan } from 'store/scan/scan.actions';
import { getScannedProducts } from 'store/scan/scan.selectors';

import ScannerModal from './ScannerModal';

const mapStateToProps = (state) => ({
  isKiosk: getKiosk(state),
  products: getScannedProducts(state),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  openModalAction,
  stopScan,
};
class ScannerModalContainer extends Component<
  ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps,
> {
  componentDidMount() {
    const { locale } = this.props;
    wineAnalytics.trackScanWine(locale);
  }

  handleProductCardClick = (product: Object) => {
    this.props.openModalAction({
      type: modalTypes.PRODUCT_DETAIL,
      data: product,
    });
  };

  handleProductsButtonsClick = () => {
    this.props.openModalAction({
      type: modalTypes.PRODUCTS,
    });
  };

  render() {
    return (
      <ScannerModal
        {...this.props}
        handleProductCardClick={this.handleProductCardClick}
        handleProductsButtonsClick={this.handleProductsButtonsClick}
      />
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ScannerModalContainer);
