import Grid from '@material-ui/core/Grid';
import SvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';

import { Heading03, Heading05 } from 'theme/Heading';

import { Button03 } from 'components/@common/Button';

export const ImageGrid = styled(Grid)`
  height: 239px;
  display: flex;
  justify-content: center;
  padding: 24px 0;
`;

export const ImageStyled = styled.img`
  height: 100%;
  max-width: 100px;
  object-fit: contain;
`;

export const LocationContainer = styled.div`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const AwardContainer = styled.div`
  position: absolute;
  bottom: 24px;
  right: 24px;
  display: inline-flex;
  justify-content: space-between;
  height: 32px;
  > * {
    margin: 0 4px;
  }
`;

export const ProductTitle = styled(Heading03)`
  ${({ theme }) => theme.textStyles.heading03global01bold};
  margin-bottom: 12px;
  max-width: 281px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: flex-start;
`;

export const ProductGrid = styled(Grid)`
  position: relative;
  background-color: ${({ theme, checked }) =>
    checked ? '#F8F8F5' : theme.palette.neutral01};
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral05};
  padding: 0 24px;
  min-width: 520px;

  &:hover {
    border-color: ${({ theme }) => theme.palette.neutral04};
    cursor: pointer;
  }
`;

export const ProductContentGrid = styled(Grid)`
  padding: 24px 0 24px 24px;
`;

export const ProductContentGrid02 = styled(Grid)`
  height: 100%;
`;

export const ProductInfo = styled(Heading05)`
  ${({ theme }) => theme.textStyles.heading05neutral03uppercase};
`;

export const SvgIconStyled = styled(SvgIcon)`
  top: -0.05em;
  position: relative;
`;

export const IconStyled = styled.img`
  height: 24px;
  width: 24px;
`;

export const Button03Styled = styled(Button03)`
  && {
    font-weight: bold;
    font-size: 14px;
    line-height: 20px;
    color: #ef5059;
    border-bottom: 1px solid #ef5059;
    border-radius: 0;
    margin-bottom: 48px;
    padding: 0;
  }
`;
