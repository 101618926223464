import { SpinnerStyled, SpinnerView } from './Spinner.styled';

type Props = {
  color?: string;
  className?: string;
};

const Spinner = ({ color, className }: Props) => (
  <SpinnerView className={className}>
    <SpinnerStyled color={color} />
  </SpinnerView>
);

export default Spinner;
