import Dialog from '@material-ui/core/Dialog';
import { PureComponent } from 'react';

type Props = {
  open: boolean,
  children: Node,
};

export default class Modal03 extends PureComponent<Props> {
  render() {
    const { open, children } = this.props;
    return (
      <Dialog fullScreen open={open}>
        {children}
      </Dialog>
    );
  }
}
