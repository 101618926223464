import { Component } from 'react';

import type { Price } from 'types/price.types';

import PriceComp from 'components/@product/Specification/Price';

import {
  Heading05Styled,
  PriceContainer,
  PriceView,
} from './SpecificationPrices.styled';

type Props = {
  title: string,
  prices: Price[],
};

export default class SpecificationPrices extends Component<Props> {
  render() {
    const { prices = [], title } = this.props;
    return (
      <>
        {title && <Heading05Styled>{title}</Heading05Styled>}
        <PriceContainer>
          {prices &&
            prices.map((p, idx) =>
              p.content ? (
                <PriceView key={parseInt(idx.toString(), 2)}>
                  <PriceComp vertical {...p} />
                </PriceView>
              ) : null,
            )}
        </PriceContainer>
      </>
    );
  }
}
