import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

type State = {
  loading: boolean;
  error: boolean;
  theme: unknown;
};

export const initialState: State = {
  loading: false,
  error: false,
  theme: null,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    fetchThemeStart: (state: State) => {
      state.loading = true;
      state.error = false;
      state.theme = null;
    },
    fetchThemeSuccess: (
      state,
      { payload }: PayloadAction<{ theme: unknown }>,
    ) => {
      state.loading = false;
      state.error = false;
      state.theme = payload;
    },
    fetchThemeFail: (state: State) => {
      state.loading = false;
      state.error = false;
      state.theme = null;
    },
  },
});

export const { fetchThemeStart, fetchThemeSuccess, fetchThemeFail } =
  themeSlice.actions;

export default themeSlice;
