import { createSlice } from '@reduxjs/toolkit';

type State = {};

export const initialState: State = {};

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: { showIntroModalAction: () => {} },
});

export const { showIntroModalAction } = shopsSlice.actions;

export default shopsSlice;
