import MUIIconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

import Icon from 'components/@icons/Icon';

export const ButtonIcon02Styled = styled(MUIIconButton)`
  && {
    padding: 0px;
    &:hover {
      background-color: transparent;
    }

    &[disabled],
    &:disabled {
      pointer-events: auto;
      cursor: no-drop;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const IconStyled = styled(({ isActive, ...rest }) => <Icon {...rest} />)`
  color: ${({ theme }) => theme.palette.neutral02};
  ${({ isActive, theme }) =>
    isActive &&
    `
      color: ${theme.palette.global01}
  `};
  ${({ disabled, theme }) =>
    disabled &&
    `
      color: ${theme.palette.neutral05};
  `};
`;
