import { Component } from 'react';
import { connect } from 'react-redux';

import { coreAnalytics } from 'services/analytics/Tealium';
import { getLocale } from 'store/app/app.selectors';
import { getUserId } from 'store/user/user.selectors';

import IntroModal, { type IntroModalProps } from './IntroModal';

type IntroModalContainerProps = {
  userId: number,
  locale: string,
} & IntroModalProps;

class IntroModalContainer extends Component<IntroModalContainerProps> {
  componentDidUpdate(prevProps) {
    const { open, userId, locale } = this.props;

    if (!prevProps.open && open) {
      // if it wasn't previously open but it is now > track it as "page" navigation
      coreAnalytics.trackOnBoarding(userId, locale);
    }
  }

  render() {
    return <IntroModal {...this.props} />;
  }
}

const mapStateToProps = (state) => ({
  locale: getLocale(state),
  userId: getUserId(state),
});

export default connect(mapStateToProps, {})(IntroModalContainer);
