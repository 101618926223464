import _get from 'lodash/get';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getProducts } from 'services/api/products/products.api';
import { getThemes } from 'services/api/theme/theme.api';
import {
  fetchThemeFail,
  fetchThemeStart,
  fetchThemeSuccess,
} from 'store/theme/theme.slice';
import { getActiveUserShopId } from 'store/user/user.selectors';

// @ts-ignore
function* fetchThemeFlow(action) {
  try {
    // @ts-ignore
    const activeShopId = yield select(getActiveUserShopId);

    if (activeShopId) {
      // @ts-ignore
      const theme = yield call(getThemes, {
        shop_id: activeShopId,
        local: 1,
      });

      const themeId = _get(theme, 'id');
      const withProducts = _get(action, 'payload.withProducts');

      // Remove productIds, we replace them with real products
      if (theme) {
        theme.products = [];

        // Don't call with products on homepage -> performance, not needed
        if (withProducts && themeId) {
          // @ts-ignore
          const products = yield call(getProducts, {
            shop_id: activeShopId,
            theme_id: themeId,
            local: 1,
          });

          theme.products = _get(products, 'data.data', []);
        }

        yield put(fetchThemeSuccess(theme));
      } else {
        // @ts-ignore
        yield put(fetchThemeFail('No theme found.'));
      }
    } else {
      // @ts-ignore
      yield put(fetchThemeFail('No shopId found.'));
    }
  } catch (err) {
    // @ts-ignore
    yield put(fetchThemeFail(err));
  }
}

function* saga() {
  yield takeLatest(fetchThemeStart, fetchThemeFlow);
}

export default saga;
