import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { FormControlLabelStyled, MUICheckboxStyled } from './Checkbox01.styled';

type Props = {
  intlId?: string,
  ellipsis?: boolean,
};

type State = {
  checked?: boolean,
};

export default class Checkbox01 extends Component<Props, State> {
  state = {
    checked: false,
  };

  static defaultProps = {
    ellipsis: true,
  };

  handleChange = (event) => {
    this.setState({ checked: event.target.checked });
  };

  render() {
    const { intlId = '', ellipsis, ...rest } = this.props;
    return (
      <FormControlLabelStyled
        $ellipsis={ellipsis}
        control={
          <MUICheckboxStyled
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={this.state.checked}
            onChange={this.handleChange}
            {...rest}
          />
        }
        label={intlId ? <FormattedMessage id={intlId} /> : ''}
      />
    );
  }
}
