import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './KioskSearch' /* webpackChunkName: "kiosk-search", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.KioskSearch,
  authenticated: false,
};
