import {
  GridStyled,
  WinerackContainerReadOnly,
} from './WineRackContainer.styled';

type Props = {
  inProductRange: boolean,
  readOnly: boolean,
  children: unknown,
};

const WineRackContainer = (props: Props) =>
  props.readOnly ? (
    <WinerackContainerReadOnly>{props.children}</WinerackContainerReadOnly>
  ) : (
    <GridStyled {...props} />
  );

export default WineRackContainer;
