const IcoArrowRightLast = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M10.38 20L9 18.59 14.55 13H2.38v-2h12.17L9 5.41 10.38 4l8 8zm9.25-.25h2v-15h-2z"
      fill="currentColor"
    />
  </svg>
);

export default IcoArrowRightLast;
