import styled, { css } from 'styled-components';

import ButtonBase from '../ButtonBase';

const getThemeStyles = (props) => {
  const { color, isDark, theme } = props;
  const { button01 } = theme.flavor;

  const group = color === 'secondary' ? 'secondary' : 'primary';
  const variant = isDark ? 'dark' : 'light';

  return css`
    border-radius: ${button01[group].base.borderRadius};
    padding: ${button01[group].base.padding};
    font-size: ${button01[group].base.fontSize};
    font-weight: ${button01[group].base.fontWeight};
    transition: background-color 0.3s, transform 0.3s, color 0.3s;

    svg {
      width: ${button01[group].base.iconSize};
      height: ${button01[group].base.iconSize};
    }

    background-color: ${button01[group][variant].backgroundColor};
    color: ${button01[group][variant].color};
    border: ${button01[group][variant].border};

    &:hover {
      background-color: ${button01[group][variant].hover.backgroundColor};
    }

    &:active {
      background-color: ${button01[group][variant].active.backgroundColor};
      transform: ${button01[group][variant].active.transform};
    }

    &:disabled {
      background-color: ${button01[group][variant].disabled.backgroundColor};
      color: ${button01[group][variant].disabled.color};
      cursor: no-drop;
    }
  `;
};

export const Button01Styled = styled(ButtonBase)`
  && {
    ${(props) =>
      props.fullWidth &&
      `
        width: 100%;
    `};

    ${(props) =>
      props.$iconLeft &&
      `
        svg {
          margin-right: 10px;
        }
    `};
    ${(props) =>
      props.$iconRight &&
      `
        svg {
          margin-left: 10px;
        }
    `};

    ${(props) => getThemeStyles(props)};
  }
`;

export default { Button01Styled };
