import { call, put, select, takeLatest } from 'redux-saga/effects';

import { setSentryShopContext } from 'utils/sentry.utils';

import { locales } from 'services/i18n/config';
import { getKiosk, getLocaleObject } from 'store/app/app.selectors';
import {
  changeLanguageAction,
  initApp,
  setAppLoadingAction,
  setKioskAction,
  setLanguageAction,
} from 'store/app/app.slice';
import { getAuthData, getLoggedIn } from 'store/auth/auth.selectors';
import { authUserStart, logoutUserStart } from 'store/auth/auth.slice';
import { getActiveUserShop } from 'store/user/user.selectors';
import { setActiveUserShopAction } from 'store/user/user.slice';

function* bootApplication() {
  // @ts-ignore
  const authData = yield select(getAuthData);

  if (!authData) {
    yield put(setAppLoadingAction(false));
  } else {
    yield put(authUserStart());
  }
}

function* changeLanguage() {
  // @ts-ignore
  const currLang = yield select(getLocaleObject);

  yield put(
    setLanguageAction({
      // @ts-ignore
      locale: currLang.value === locales.nl.value ? locales.fr : locales.nl,
    }),
  );
}

function* setKiosk() {
  yield put(setAppLoadingAction(true));
  // @ts-ignore
  const isLoggedIn = yield select(getLoggedIn);
  // @ts-ignore
  const isKiosk = yield select(getKiosk);
  // @ts-ignore
  const activeShop = yield select(getActiveUserShop);

  yield call(setSentryShopContext, activeShop);

  if (isLoggedIn && isKiosk) {
    // @ts-ignore
    yield put(logoutUserStart(`/kiosk`));
    yield put(setAppLoadingAction(false));
    yield put(setActiveUserShopAction(activeShop));
  } else {
    yield put(setAppLoadingAction(false));
  }
}

export default function* saga() {
  yield takeLatest(initApp, bootApplication);
  yield takeLatest(changeLanguageAction, changeLanguage);
  yield takeLatest(setKioskAction, setKiosk);
}
