import MUIButton from '@material-ui/core/Button';
import MUISvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';

export const ButtonStyled = styled(MUIButton)`
  && {
    font-size: 16px;
    padding: 8px 2px;
    font-family: ${({ theme }) => theme.fonts.default};

    :hover {
      background-color: transparent;
    }

    span {
      ::first-letter {
        text-transform: uppercase;
      }
      :first-child {
        text-transform: lowercase;
      }
      display: block;
    }
  }
`;

export const SvgIconStyled = styled(MUISvgIcon)`
  position: relative;
`;
