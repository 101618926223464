import styled, { css } from 'styled-components';

const Inset = css`
  padding-top: ${({ top }) => top || 0}px;
  padding-bottom: ${({ bottom }) => bottom || 0}px;
  padding-left: ${({ left }) => left || 0}px;
  padding-right: ${({ right }) => right || 0}px;
`;

const Outer = css`
  margin-top: ${({ top }) => top || 0}px;
  margin-bottom: ${({ bottom }) => bottom || 0}px;
  margin-left: ${({ left }) => left || 0}px;
  margin-right: ${({ right }) => right || 0}px;
`;

export const Spacer = styled.div`
  ${({ inset }) => (inset ? Inset : Outer)};
`;

export default Spacer;
