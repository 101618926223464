import _get from 'lodash/get';

import type { FilterConfig, FilterListingValue } from 'types/filter.types';

type CheckBoxListConfig = {
  type?: string;
  withIcon?: boolean;
  withColor?: boolean;
};

type AccordionListConfig = {
  type?: string;
};

const sortFilterItemsByValueASC = (
  a: FilterListingValue,
  b: FilterListingValue,
) => {
  // @ts-ignore
  const valueA = a.value.toUpperCase(); // ignore upper and lowercase
  // @ts-ignore
  const valueB = b.value.toUpperCase(); // ignore upper and lowercase

  return valueA.localeCompare(valueB);
};

export const configureCheckboxList = (
  availableFilters: FilterConfig[],
  config: CheckBoxListConfig = {},
  selectedValues: unknown[],
) => {
  const { type, withIcon, withColor } = config;

  const fc: FilterConfig =
    availableFilters.find((x) => x.name && x.name === type) || {};

  return _get(fc, 'listing.listingValues', [])
    .slice()
    .sort(sortFilterItemsByValueASC)
    .map(
      (v): FilterListingValue => ({
        id: v.id,
        // @ts-ignore
        label: v.value,
        checked: selectedValues.includes(v.id),
        ...(withIcon && { icon: _get(v, 'b2bMedia.reference', '') }),
        // @ts-ignore
        ...(withColor && { color: v.colorHex, filled: !!v.filled }),
      }),
    );
};

const configureCheckBoxes = (
  listingValues: FilterListingValue[] = [],
  selectedValue: unknown[],
) =>
  listingValues
    .slice()
    .sort(sortFilterItemsByValueASC)
    .map((lv) => ({
      id: lv.id,
      label: lv.value,
      checked: selectedValue.includes(lv.id),
    }));

export const configureFilterAccordionList = (
  availableFilters: FilterConfig[],
  config: AccordionListConfig,
  selectWineCountries: unknown[],
  selectWineRegions: string,
) => {
  const { type } = config;

  const fc: FilterConfig =
    availableFilters.find((x) => x.name && x.name === type) || {};

  // FilterListingValue[]
  return _get(fc, 'listing.listingValues', [])
    .slice()
    .sort(sortFilterItemsByValueASC)
    .map((lv) => ({
      id: lv.id,
      label: lv.value,
      checked: selectWineCountries.includes(lv.id),
      ...(lv.children && {
        // @ts-ignore
        checkboxes: configureCheckBoxes(lv.children, selectWineRegions),
      }),
    }));
};
