import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getProductLoading = (state: StoreState) =>
  _get(state, 'product.loading');

export const getIsLocationModalOpen = (state: StoreState) =>
  _get(state, 'product.isLocationModalOpen');

export const getSelectedProductIds = (state: StoreState) =>
  _get(state, 'product.selectedProductIds');

export const getIsEditing = (state: StoreState) =>
  _get(state, 'product.isEditing');
export const getIsUpdatingProducts = (state: StoreState) =>
  _get(state, 'product.isUpdatingProducts');

export const getEditAction = (state: StoreState) =>
  _get(state, 'product.editAction');

export default { getProductLoading };
