import Icon from 'components/@icons/Icon';

import { ButtonIcon01Styled, Label, Wrapper } from './IconButton.styled';

type Props = {
  labelLeft?: string,
  labelRight?: string,
  isDark?: boolean,
  icon: Node,
  onClick: Function,
};

const IconButton = ({
  labelLeft,
  labelRight,
  isDark,
  icon,
  onClick,
}: Props) => (
  <Wrapper
    onClick={onClick}
    container
    direction="row"
    alignItems="center"
    spacing={2}
    isDark={isDark}
  >
    {labelLeft && (
      <Label left isDark={isDark}>
        {labelLeft}
      </Label>
    )}

    <ButtonIcon01Styled isDark={isDark}>
      <Icon>{icon}</Icon>
    </ButtonIcon01Styled>

    {labelRight && <Label isDark={isDark}>{labelRight}</Label>}
  </Wrapper>
);

export default IconButton;
