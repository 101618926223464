/**
 * @flow
 *
 * IOS scroll locking utility
 *
 * */

let previousPositionValue = '';
let scrollLockIsActive = false;

export function lockBodyScroll() {
  if (scrollLockIsActive) {
    return;
  }

  // Save previous values
  previousPositionValue = document.body.style.position;
  // Clear previous values
  document.body.style.position = '';
  // Set body style fixed
  document.body.style.position = 'fixed';
  document.body.style.width = '100%';
  scrollLockIsActive = true;
}

export function unlockBodyScroll() {
  scrollLockIsActive = false;

  // restore previous value of the position
  if (previousPositionValue) {
    document.body.style.position = previousPositionValue;
  } else {
    document.body.style.position = '';
  }

  document.body.style.width = '';
}
