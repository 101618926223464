// @ts-nocheck
import axiosManager from 'services/api/axios';

export const updateShopProduct = ({ shopId, productId, ...rest }: unknown) =>
  axiosManager
    .put(
      `/shops/${shopId}/products/${productId}`,
      { ...rest },
      {
        withCredentials: true,
      },
    )
    .then(
      (res) => res.data,
      (e) => Promise.reject(e),
    );

export const updateShopProducts = ({ shopId, ...rest }: unknown) =>
  axiosManager
    .put(
      `/shops/${shopId}/products`,
      { ...rest },
      {
        withCredentials: true,
      },
    )
    .then(
      (res) => res.data,
      (e) => Promise.reject(e),
    );

export const deleteShopProduct = ({ shopId, productId }: unknown) =>
  axiosManager
    .delete(`/shops/${shopId}/products/${productId}`, {
      withCredentials: true,
    })
    .then(
      (res) => res.data,
      (e) => Promise.reject(e),
    );

export const deleteShopProducts = ({ shopId, ...rest }: unknown) =>
  axiosManager
    .delete(
      `/shops/${shopId}/products`,
      { data: { data: rest.data } }, // Because delete doesn't have a data param by default
      {
        withCredentials: true,
      },
    )
    .then((res) => res.data)
    .catch((e) => Promise.reject(e));
