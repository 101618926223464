import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const GridStyled = styled(Grid)`
  && {
    margin-bottom: 16px;
  }
`;

export const FoodPairingReadOnly = styled.div``;

export default { GridStyled };
