import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { initTealium } from 'services/analytics/Tealium';
import IntlSelectorProvider from 'services/i18n';
import withNavigatorProvider from 'services/navigator/NavigatorProvider';
import { queryClient } from 'services/react-query';
import ThemeContext from 'services/theme-context';
import storeConfig from 'store';

import Listeners from 'pages/Listeners';
import Pages from 'pages/Pages';

import RootBoundary from 'components/@boundaries/RootBoundary';

const App = () => {
  initTealium();

  return (
    <RootBoundary>
      <QueryClientProvider client={queryClient}>
        <ReduxProvider store={storeConfig.store}>
          <PersistGate
            persistor={storeConfig.persistor}
            onBeforeLift={storeConfig.initApp}
          >
            <IntlSelectorProvider>
              <HelmetProvider>
                <ThemeContext>
                  <Pages />
                  <Listeners />
                </ThemeContext>
              </HelmetProvider>
            </IntlSelectorProvider>
          </PersistGate>
        </ReduxProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </RootBoundary>
  );
};
export default withNavigatorProvider(App);
