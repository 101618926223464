import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getIsLoading = (state: StoreState) => _get(state, 'app.isLoading');
export const getLocale = (state: StoreState) =>
  _get(state, 'app.language.locale.date.code');
export const getLocaleObject = (state: StoreState) =>
  _get(state, 'app.language.locale');
export const getNextLocaleForAnalytics = (state: StoreState) => {
  const currentLocale = _get(state, 'app.language.locale');
  // @ts-ignore
  return currentLocale === 'nl' ? 'NL' : 'FR';
};

export const getKiosk = (state: StoreState) => _get(state, 'app.kiosk');
