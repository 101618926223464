/* eslint-disable @typescript-eslint/no-unused-vars */
import MUIAppBar from '@material-ui/core/AppBar';
import MUIToolbar from '@material-ui/core/Toolbar';
import styled, { css } from 'styled-components';

import { Heading03 } from 'theme/Heading';

const AppBarDocked = css`
  background-color: transparent;
  box-shadow: 0 0 16px 0 rgba(17, 17, 17, 0);
`;

const AppBarScrolling = css`
  background-color: ${({ theme }) => theme.palette.neutral06};
  box-shadow: 0 8px 16px 0 rgba(17, 17, 17, 0.08);
`;

const AppBarScrollingTransparentBg = css`
  background-color: transparent;
  box-shadow: 0 8px 16px 0 rgba(17, 17, 17, 0.08);
`;

function getScrollingStyle({ scrolling, hasTransparentScrollingBg }) {
  if (scrolling && hasTransparentScrollingBg) {
    return AppBarScrollingTransparentBg;
  }

  if (scrolling) {
    return AppBarScrolling;
  }

  return AppBarDocked;
}

export const AppBar = styled(
  ({ scrolling, children, hasTransparentScrollingBg, ...rest }) => (
    <MUIAppBar {...rest}>{children}</MUIAppBar>
  ),
)`
  && {
    transition: box-shadow 0.5s;
    ${getScrollingStyle};
  }
`;

export const Toolbar = styled(({ transparent, ...rest }) => (
  <MUIToolbar {...rest} />
))`
  && {
    background-color: ${({ theme, transparent }) =>
      transparent ? 'transparent' : theme.palette.neutral06};
    min-height: 88px;
    box-sizing: border-box;
    width: 100%;
  }
`;

export const Title = styled(Heading03)`
  ${({ theme }) => theme.textStyles.heading03neutral02bold};
`;
