import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { FilterState } from 'types/filter.types';

type State = {
  loading: boolean;
  paging: { pageNumber: number; itemsPerPage: number };
  data: unknown[];
  meta: Object;
  availableFilters: FilterState[];
  loadingFilter: boolean;
  error?: Object;
};

export const initialState: State = {
  loading: false,
  paging: { pageNumber: 1, itemsPerPage: 25 },
  data: [],
  meta: {},
  availableFilters: [],
  loadingFilter: false,
  error: undefined,
};

const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProductsAction: (state: State, { payload }: PayloadAction<unknown>) => ({
      ...state,
      // @ts-ignore
      ...payload,
    }),
    setProductsLoading: (state: State, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
    fetchProductsAction: (state: State) => {
      state.loading = true;
    },
    fetchProductsSuccessAction: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => ({
      ...state,
      loading: false,
      // @ts-ignore
      ...payload,
    }),
    fetchProductsFailedAction: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      state.loading = false;
      // @ts-ignore
      state.error = payload;
      state.data = [];
      state.meta = {};
    },
    setProductsPagingAction: (
      state: State,
      { payload }: PayloadAction<{ pageNumber: number; itemsPerPage: number }>,
    ) => {
      state.paging = {
        ...state.paging,
        ...payload,
      };
    },
    resetProductsPaging: (state: State) => {
      state.paging = initialState.paging;
    },
    fetchProductsFilterStart: (state: State) => {
      state.loadingFilter = true;
    },
    fetchProductsFilterSuccess: (
      state: State,
      { payload }: PayloadAction<FilterState[]>,
    ) => {
      state.loadingFilter = false;
      state.availableFilters = [...payload];
    },
    fetchProductsFilterFailed: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      state.loadingFilter = false;
      state.availableFilters = [];
      // @ts-ignore
      state.error = payload;
    },
    resetProducts: () => initialState,
  },
});

export const {
  setProductsAction,
  fetchProductsAction,
  fetchProductsFilterStart,
  fetchProductsFilterFailed,
  fetchProductsFilterSuccess,
  fetchProductsSuccessAction,
  fetchProductsFailedAction,
  setProductsPagingAction,
  resetProductsPaging,
  resetProducts,
  setProductsLoading,
} = productsSlice.actions;

export default productsSlice;
