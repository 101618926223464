import {
  Container,
  Heading03Styled,
  Paragraph01Styled,
} from './ProductDescription.styled';

type Props = {
  title: string,
  description: string,
  readOnly: boolean,
};

const ProductDescription = ({ title, description, readOnly }: Props) => (
  <Container>
    <Heading03Styled readOnly={readOnly}>{title}</Heading03Styled>
    <Paragraph01Styled readOnly={readOnly}>{description}</Paragraph01Styled>
  </Container>
);

export default ProductDescription;
