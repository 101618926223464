import { modalTypes } from 'enums';
import printActionTypes from 'enums/printAction.enum';
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

import { coreAnalytics } from 'services/analytics/Tealium';
import {
  getProduct,
  getProducts,
  getTask,
} from 'services/api/products/products.api';
import { getFilter } from 'store/filter/filter.selectors';
import { getModalData } from 'store/modal/modal.selectors';
import { toastErrorAction, toastSuccessAction } from 'store/toast/toast.slice';
import { getActiveUserShop } from 'store/user/user.selectors';

import {
  fetchPdfFailed,
  fetchPdfStart,
  fetchPdfSuccess,
  pollTaskStart,
  pollTaskStop,
} from './print.slice';

const printTypes = {
  [printActionTypes.PRINT_LIST]: 'list',
  [printActionTypes.PRINT_SHEETS]: 'detail',
};

function* getProductsPdfFlow(layout: string) {
  // @ts-ignore
  const activeShop = yield select(getActiveUserShop);
  // @ts-ignore
  const filter = yield select(getFilter);
  // @ts-ignore
  const response = yield call(getProducts, {
    shop_id: activeShop.id,
    ...filter,
    format: 'pdf',
    layout,
  });

  coreAnalytics.trackExportInteraction(
    layout === 'list' ? 'list: list view' : 'list: separate view',
  );

  return response.data;
}

function* getProductPdfFlow(productId: string) {
  // @ts-ignore
  const activeShop = yield select(getActiveUserShop);
  // @ts-ignore
  const response = yield call(getProduct, productId, {
    shop_id: activeShop.id,
    format: 'pdf',
    layout: printTypes[printActionTypes.PRINT_SHEETS],
  });

  coreAnalytics.trackExportInteraction('single');

  return response.data;
}

// @ts-ignore
function* fetchPdfFlow({ payload }) {
  const { modalType, printType } = payload;
  try {
    if (modalType === modalTypes.PRINT) {
      // @ts-ignore
      const { data } = yield call(getProductsPdfFlow, printTypes[printType]);
      yield put(pollTaskStart(data.taskUrl));
    }
    if (modalType === modalTypes.PRINT_DETAIL) {
      const { id } = yield select(getModalData);
      const { data } = yield call(getProductPdfFlow, id);
      yield put(pollTaskStart(data.taskUrl));
    }
  } catch (err) {
    yield put(
      toastErrorAction({
        message: 'toast.pdf-failed',
      }),
    );
    yield put(fetchPdfFailed(err));
  }
}

function* pollTask(url: string) {
  while (true) {
    try {
      yield delay(3000);

      const { data } = yield call(getTask, url);

      switch (data.status) {
        case 'processed':
          yield put(fetchPdfSuccess(data.url));
          yield window.open(data.url, '_tab');
          yield put(
            toastSuccessAction({
              message: 'toast.pdf-success',
            }),
          );
          yield put(pollTaskStop());
          break;
        case 'failed':
          yield put(
            toastErrorAction({
              message: 'toast.pdf-failed',
            }),
          );
          // @ts-ignore
          yield put(fetchPdfFailed());
          yield put(pollTaskStop());
          break;
        default:
          break;
      }
    } catch (err) {
      yield put(
        toastErrorAction({
          message: 'toast.pdf-failed',
        }),
      );
      yield put(fetchPdfFailed(err));
      yield put(pollTaskStop());
    }
  }
}

export function* watchPollFlow() {
  while (true) {
    const { payload } = yield take(pollTaskStart);
    yield race([call(pollTask, payload), take(pollTaskStop)]);
  }
}

export default function* saga() {
  yield takeLatest(fetchPdfStart, fetchPdfFlow);
  yield watchPollFlow();
}
