import { connect } from 'react-redux';

import { getLocale } from 'store/app/app.selectors';
import { getModalData, getModalType } from 'store/modal/modal.selectors';
import { closeModalAction, openModalAction } from 'store/modal/modal.slice';
import { getProductLoading } from 'store/product/product.selectors';
import {
  deleteProductStart,
  updateProductStart,
} from 'store/product/product.slice';
import { getUser } from 'store/user/user.selectors';

import ProductDetail from './ProductDetail';

const mapStateToProps = (state) => ({
  modalData: getModalData(state),
  modalType: getModalType(state),
  productLoading: getProductLoading(state),
  user: getUser(state),
  locale: getLocale(state),
});

const mapDispatchToProps = {
  closeModalAction,
  updateProductStart,
  deleteProductStart,
  openModalAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
