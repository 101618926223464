import styled from 'styled-components';

export const ButtonLabelStyled = styled.span`
  font-weight: bold;
  ${(props) =>
    props.ellipsis &&
    `
    max-width: 153px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
  `};
`;

export default { ButtonLabelStyled };
