/**
 * Function returns the expiration time minus 15% in milliseconds
 *
 * @param {number} expiresIn
 */
export function getExpirationTime(expiresIn: number): number {
  return (expiresIn - expiresIn * 0.15) * 1000;
}

export default { getExpirationTime };
