import Grid from '@material-ui/core/Grid';
import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { Spinner } from 'components/@common';

const scanbox = css`
  box-sizing: border-box;
  width: 472px;
  height: 264px;
  border: 2px solid ${({ theme }) => theme.palette.neutral01};
  border-radius: 0;
  box-shadow: 0px 0px 0px 9999px
    ${({ theme }) => rgba(theme.palette.neutral02, 0.45)};
  transform: translate(-50%, -50%);
  left: 50% !important;
  top: 50% !important;
  width: 60% !important;
  height: 30% !important;
`;

export const MessageContainer = styled.div`
  position: absolute;
  top: 104px;
  left: 0;
  right: 0;
`;

const loadingbox = css`
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => rgba(theme.palette.neutral02, 0.45)};
  border: none;
  border-radius: 0;
`;

const setBox = ({ loading }) => css`
  ${loading ? loadingbox : scanbox};
`;

export const Wrapper = styled.div`
  && {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: black;

    .scandit {
      .scandit-barcode-picker {
        height: 100vh;
      }

      .scandit-viewfinder {
        ${(props) => setBox(props)};
      }

      .scandit-camera-switcher {
        bottom: 5% !important;
        top: auto;
      }
    }
  }
`;

export const StyledGrid = styled(Grid)`
  && {
    position: relative;
    margin-bottom: 24px;
    left: 0;
    right: 0;
    z-index: 11;
    padding-left: 24px;
    padding-right: 24px;
  }
`;

export const LoadingWrapper = styled.div`
  z-index: 12;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  margin-left: auto;
  margin-right: auto;
`;

export const LoadingScannerWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.neutral01};
`;

export const SpinnerStyled = styled(Spinner)`
  && {
    height: auto;
  }
`;
