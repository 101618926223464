import { Component } from 'react';

import ScannerModalB2b from './b2b';
import ScannerModalB2c from './b2c';

type Props = {
  isKiosk: boolean,
  handleProductsButtonsClick: () => void,
  handleProductCardClick: (product: Object) => void,
};

export default class ScannerModal extends Component<Props> {
  render() {
    const {
      isKiosk,
      handleProductCardClick,
      handleProductsButtonsClick,
      ...rest
    } = this.props;

    if (isKiosk) {
      return <ScannerModalB2c {...rest} />;
    }

    return (
      <ScannerModalB2b
        {...rest}
        handleProductCardClick={handleProductCardClick}
        handleProductsButtonsClick={handleProductsButtonsClick}
      />
    );
  }
}
