import { css } from 'styled-components';

export const heading01 = css`
  font-size: 28px;
  line-height: 32px;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const heading01global02 = css`
  ${heading01};
  color: ${({ theme }) => theme.palette.global02};
`;

export const heading01global02bold = css`
  ${heading01global02};
  font-weight: 700;
`;

export const heading01neutral02 = css`
  ${heading01};
  color: ${({ theme }) => theme.palette.neutral02};
`;

export const heading01neutral02bold = css`
  ${heading01neutral02};
  font-weight: 700;
`;

export const heading02 = css`
  font-size: 24px;
  line-height: 28px;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const heading03 = css`
  font-size: 20px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const heading03global01 = css`
  ${heading03};
  color: ${({ theme }) => theme.palette.global01};
`;

export const heading03neutral01 = css`
  ${heading03};
  color: ${({ theme }) => theme.palette.neutral01};
`;

export const heading03neutral02 = css`
  ${heading03};
  color: ${({ theme }) => theme.palette.neutral02};
`;

export const heading03neutral03 = css`
  ${heading03};
  color: ${({ theme }) => theme.palette.neutral03};
`;

export const heading03neutral04 = css`
  ${heading03};
  color: ${({ theme }) => theme.palette.neutral04};
`;

export const heading03global01bold = css`
  ${heading03global01};
  font-weight: 700;
`;
export const heading03neutral04bold = css`
  ${heading03neutral04};
  font-weight: bold;
`;

export const heading03global02 = css`
  ${heading03};
  color: ${({ theme }) => theme.palette.global02};
`;

export const heading03neutral01bold = css`
  ${heading03neutral01};
  font-weight: 700;
`;

export const heading03neutral02bold = css`
  ${heading03neutral02};
  font-weight: 700;
`;

export const heading03neutral03bold = css`
  ${heading03neutral03};
  font-weight: 700;
`;

export const heading03global02bold = css`
  ${heading03global02};
  font-weight: 700;
`;

export const heading04 = css`
  font-size: 16px;
  line-height: 24px;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const heading04neutral02 = css`
  ${heading04};
  color: ${({ theme }) => theme.palette.neutral02};
`;

export const heading04global02 = css`
  ${heading04};
  color: ${({ theme }) => theme.palette.global02};
`;

export const heading04global01 = css`
  ${heading04};
  color: ${({ theme }) => theme.palette.global01};
`;

export const heading04global02bold = css`
  ${heading04global02};
  font-weight: 700;
`;

export const heading04global01bold = css`
  ${heading04global01};
  font-weight: 700;
`;

export const heading04global02boldcenter = css`
  ${heading04global02bold};
  text-align: center;
`;

export const heading04neutral02bold = css`
  ${heading04neutral02};
  font-weight: 700;
`;
export const heading04neutral02boldcenter = css`
  font-weight: 700;
  text-align: center;
  ${heading04neutral02};
`;

export const heading05 = css`
  font-size: 12px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.default};
`;

export const heading05neutral02 = css`
  ${heading05};
  color: ${({ theme }) => theme.palette.neutral02};
`;

export const heading05neutral03 = css`
  ${heading05};
  color: ${({ theme }) => theme.palette.neutral03};
`;

export const heading05neutral02uppercase = css`
  ${heading05neutral02};
  text-transform: uppercase;
`;

export const heading05neutral03uppercase = css`
  ${heading05neutral03};
  text-transform: uppercase;
`;

export default {
  heading01,
  heading01neutral02,
  heading01neutral02bold,
  heading01global02,
  heading01global02bold,
  heading02,
  heading03,
  heading03global01,
  heading03global01bold,
  heading03global02,
  heading03global02bold,
  heading03neutral01,
  heading03neutral01bold,
  heading03neutral02,
  heading03neutral02bold,
  heading03neutral03,
  heading03neutral03bold,
  heading04,
  heading04neutral02,
  heading04neutral02bold,
  heading04neutral02boldcenter,
  heading04global01,
  heading04global01bold,
  heading04global02,
  heading04global02bold,
  heading04global02boldcenter,
  heading03neutral04,
  heading03neutral04bold,
  heading05,
  heading05neutral02,
  heading05neutral02uppercase,
  heading05neutral03,
  heading05neutral03uppercase,
};
