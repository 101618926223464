import modalTypes from 'enums/modal.enum';
import { put, select, takeLatest } from 'redux-saga/effects';

import { getProducts } from 'services/api/products/products.api';
import { openModalAction } from 'store/modal/modal.slice';
import { showIntroModalAction } from 'store/shops/shops.slice';
import { getActiveUserShop } from 'store/user/user.selectors';

function* showIntroModal() {
  // We call the products API to check if we have products in our local assortiment
  // @ts-ignore
  const activeShop = yield select(getActiveUserShop);
  let products = null;

  try {
    const { data } = yield getProducts({
      shop_id: activeShop.id,
      local: 1,
    });
    products = data.data;
  } catch (err) {
    console.error('Failed to get local assortiment for intro modal');
  }

  if (!products || products.length <= 0) {
    yield put(
      openModalAction({
        type: modalTypes.INTRO,
      }),
    );
  }
}

function* saga() {
  yield takeLatest(showIntroModalAction, showIntroModal);
}

export default saga;
