import { connect } from 'react-redux';

import { getModalType } from 'store/modal/modal.selectors';
import { closeModalAction, openModalAction } from 'store/modal/modal.slice';
import { startScan } from 'store/scan/scan.actions';

import Intro from './Intro';

const mapStateToProps = (state) => ({
  modalType: getModalType(state),
});

const mapDispatchToProps = {
  closeModalAction,
  openModalAction,
  startScan,
};

export default connect(mapStateToProps, mapDispatchToProps)(Intro);
