import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  ErrorStyled,
  FormControlStyled,
} from 'components/@form/InputField01/InputField01.styled';

import Input from '../Input';
import InputLabel from '../InputLabel';

type Props = {
  children?: ReactNode,
  error?: boolean,
  errorText?: string,
  label?: string,
  intlId?: string,
};

const InputField01 = (props: Props) => {
  const { error, errorText, label, intlId, ...rest } = props;
  return (
    <FormControlStyled>
      <InputLabel error={error}>
        {intlId && <FormattedMessage id={intlId} />}
        {label}
      </InputLabel>
      <Input error={error} {...rest} />
      {error && <ErrorStyled>{errorText}</ErrorStyled>}
    </FormControlStyled>
  );
};

export default InputField01;
