import * as Sentry from '@sentry/browser';

import { Shop } from 'types/shop.types';

export function setSentryShopContext(shop: Shop) {
  if (shop) {
    Sentry.setContext('Shop', {
      id: shop.id,
      shopNumber: shop.shopNumber,
      name: shop.name,
    });

    Sentry.setTag('shopId', shop.id);
    Sentry.setTag('shopName', shop.name);
    Sentry.setTag('shopNumber', shop.shopNumber);
  }
}

export function unsetSentryShopContext() {
  Sentry.configureScope((s) => s.clear());
}
