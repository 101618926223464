import { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { coreAnalytics } from 'services/analytics/Tealium';

import Button01 from 'components/@common/Button/Button01';
import Spacer from 'components/@common/Spacer';
import Header from 'components/@kiosk/Header';
import KioskWrapper from 'components/@kiosk/KioskWrapper';
import Modal03 from 'components/@modals/Modal03';

import {
  Background,
  CenterWrapper,
  Paragraph,
  Understanding,
  Wrapper,
  WrittenTitle,
} from './DisclaimerModal.styled';

type Props = {
  open: boolean,
  onAccept: () => void,
  onClose: () => void,
};

export default class DisclaimerModal extends Component<Props> {
  modalRef = createRef();

  componentDidUpdate() {
    const { open } = this.props;
    // colr. asked to track the htmlElement of the modal. why? noone knows
    if (open) coreAnalytics.trackAgeValidation(this.modalRef);
  }

  render() {
    const { open, onClose, onAccept } = this.props;

    return (
      <Modal03 open={open} onClose={onClose}>
        <Wrapper ref={this.modalRef}>
          <Background />
          <Header transparent withLanguageSwitch={false} />
          <CenterWrapper>
            <KioskWrapper>
              <Spacer bottom={16}>
                <WrittenTitle>
                  <FormattedMessage id="disclaimer-modal.title" />
                </WrittenTitle>
              </Spacer>
              <Spacer bottom={48}>
                <Paragraph>
                  <FormattedMessage id="disclaimer-modal.subtitle" />
                </Paragraph>
              </Spacer>
              <Spacer bottom={48}>
                <Button01
                  intlId="disclaimer-modal.button.accept"
                  color="primary"
                  fullWidth
                  onClick={onAccept}
                />
              </Spacer>
              <Spacer bottom={8}>
                <Paragraph>
                  <FormattedMessage id="disclaimer-modal.description" />
                </Paragraph>
              </Spacer>
              <Understanding>
                <FormattedMessage id="disclaimer-modal.understanding" />
              </Understanding>
            </KioskWrapper>
          </CenterWrapper>
        </Wrapper>
      </Modal03>
    );
  }
}
