import styled from 'styled-components';

import Toast from 'components/@common/Toast';

// Network toast should have a different 'bottom' so it doesn't get stacked on top of another toast.
export const ToastStyled = styled(Toast).attrs({
  classes: { root: 'root' },
})`
  &.root {
    bottom: 94px;
  }
`;
