import InputBase from '@material-ui/core/InputBase';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import { Component } from 'react';

import { lockBodyScroll, unlockBodyScroll } from 'utils/scroll-lock.utils';

import { fonts, palette } from 'theme/branding';

import { LabelStyled, SelectView } from './Dropdown01.styled';

// Styling to make the input look like the previous component
// It is a local override because InputBase get used everywhere else
const BootstrapInput = withStyles((theme) => ({
  root: {},
  input: {
    fontFamily: fonts.default,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: palette.neutral01,
    border: `1px solid ${palette.neutral04}`,
    fontSize: 14,
    lineHeight: '20px',
    width: 100,
    padding: '2px 26px 2px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      backgroundColor: palette.neutral01,
      borderRadius: 4,
      borderColor: palette.neutral03,
    },
  },
}))(InputBase);

type Props = {
  name: string,
  value: string,
  className?: string,
  labelLeft: string,
  defaultOption: string,
  labelRight: string,
  big: boolean,
  children: Node,
  renderValue?: (e: any) => unknown,
  onChange: (e: SyntheticInputEvent) => void,
};

type State = {
  isOpen: boolean,
};

class Dropdown01 extends Component<Props, State> {
  state = {
    isOpen: false,
  };

  componentWillUnmount() {
    unlockBodyScroll();
  }

  // Callbacks only work in controlled mode
  // AKA we need to keep the open state in this wrapper component
  handleOpen = () => {
    this.setState(
      {
        isOpen: true,
      },
      () => {
        lockBodyScroll();
      },
    );
  };

  // Callbacks only work in controlled mode
  // AKA we need to keep the open state in this wrapper component
  handleClose = () => {
    this.setState(
      {
        isOpen: false,
      },
      () => {
        unlockBodyScroll();
      },
    );
  };

  render() {
    const {
      name,
      value,
      className,
      labelLeft,
      labelRight,
      big,
      children,
      renderValue,
      onChange,
    } = this.props;

    return (
      <SelectView big={big} className={className}>
        {labelLeft && (
          <LabelStyled big={big} htmlFor={name}>
            {labelLeft}
          </LabelStyled>
        )}
        <Select
          name={name}
          value={value}
          onChange={onChange}
          displayEmpty
          input={<BootstrapInput name={name} id={name} />}
          renderValue={renderValue}
          open={this.state.isOpen}
          onOpen={this.handleOpen}
          onClose={this.handleClose}
        >
          {children}
        </Select>
        {labelRight && <LabelStyled big={big}>{labelRight}</LabelStyled>}
      </SelectView>
    );
  }
}

export default Dropdown01;
