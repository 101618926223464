import Grid from '@material-ui/core/Grid';
import { locationTypes } from 'enums';

import {
  LocationLabel,
  LocationLabelSmall,
  LocationWrapper,
  Title,
} from './ProductLocation.styled';

type Props = {
  title: string,
  label: string,
  small: boolean,
};

const MAX_CHARS = 5;

const formatLabel = (label: string) => {
  if (label.length > MAX_CHARS) {
    return `${label.substring(0, MAX_CHARS)}...`;
  }
  if (label === locationTypes.EMPTY) {
    return '--';
  }
  return label;
};

const ProductLocation = ({ label = '--', small, title }: Props) => (
  <Grid container direction="column">
    {title && (
      <Grid item>
        <Title>{title}</Title>
      </Grid>
    )}
    <Grid item>
      <LocationWrapper small={small}>
        {small && <LocationLabelSmall>{formatLabel(label)}</LocationLabelSmall>}
        {!small && <LocationLabel>{formatLabel(label)}</LocationLabel>}
      </LocationWrapper>
    </Grid>
  </Grid>
);

export default ProductLocation;
