import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getKiosk } from 'store/app/app.selectors';
import { openModalAction } from 'store/modal/modal.slice';

import ProductDetailsModal from './ProductDetailsModal';

const mapStateToProps = (state) => ({
  isKiosk: getKiosk(state),
});

const mapDispatchToProps = {
  openModalAction,
};

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhancer(ProductDetailsModal);
