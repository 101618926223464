import styled from 'styled-components';

import Chip01 from 'components/@common/Chips/Chip01';

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const FilterItem = styled.div`
  min-width: 90px;
`;

export const Chip01Styled = styled(Chip01)`
  margin: 4px 4px;
`;
