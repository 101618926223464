import SvgIcon from '@material-ui/core/SvgIcon';

import IcoBubbels from 'components/@icons/icons/IcoBubbels';
import IcoCircle from 'components/@icons/icons/IcoCircle';

type Props = {
  color: string;
  filled: boolean;
  className: string;
};

const Circle = ({ className, color, filled, ...rest }: Props) => (
  <SvgIcon className={className} {...rest}>
    {filled ? <IcoCircle color={color} /> : <IcoBubbels color={color} />}
  </SvgIcon>
);

export default Circle;
