import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Heading03 } from 'theme/Heading';

import { Spinner } from 'components/@common';
import { Button01 } from 'components/@common/Button';
import ToastContent from 'components/@common/Toast/ToastContent';
import HeaderModal from 'components/@modals/HeaderModal';
import ProductCard from 'components/@product/ProductCard';
import Scandit from 'components/@scanner/Scandit';

import {
  GridStyled,
  LoadingScannerWrapper,
  LoadingWrapper,
  ModalStyled,
  SpinnerStyled,
  Wrapper,
} from './ScannerModalB2b.styled';

type Props = {
  products: [],
  open: boolean,
  onAccept: () => void,
  onClose: () => void,
  loading: boolean,
  paused: boolean,
  onScan: (barcode: string) => void,
  error: boolean,
  onRetry: () => void,
  scannerInit: boolean,
  scannerLibInit: boolean,
  handleProductsButtonsClick: () => void,
  handleProductCardClick: (product: Object) => void,
};

export default class ScannerModal extends PureComponent<Props> {
  render() {
    const {
      open,
      onClose,
      loading = false,
      onScan,
      paused = false,
      error,
      onRetry,
      scannerInit,
      scannerLibInit,
      products,
      handleProductCardClick,
      handleProductsButtonsClick,
    } = this.props;
    return (
      <ModalStyled onClose={onClose} keepMounted open={open}>
        <>
          <Wrapper>
            <HeaderModal
              onClose={onClose}
              title={<FormattedMessage id="scanner-modal.title" />}
            />
            {loading && (
              <LoadingWrapper>
                <Spinner color="white" />
              </LoadingWrapper>
            )}
            {!loading && !error && (
              <GridStyled
                container
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <ToastContent
                    variant="info"
                    message={<FormattedMessage id="scanner-modal.toast.info" />}
                  />
                </Grid>
              </GridStyled>
            )}
            {!loading && error && (
              <GridStyled
                container
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Grid item xs={12}>
                  <ToastContent
                    variant="error"
                    message={
                      <FormattedMessage id="scanner-modal.toast.not-found" />
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button01 color="primary" onClick={onRetry}>
                    <FormattedMessage id="scanner-modal.button.retry" />
                  </Button01>
                </Grid>
              </GridStyled>
            )}
            {!loading && !scannerInit && (
              <GridStyled container direction="row" justifyContent="center">
                <Grid item xs={12}>
                  <ToastContent
                    variant="error"
                    message={
                      <FormattedMessage id="scanner-modal.toast.camera-access" />
                    }
                  />
                </Grid>
              </GridStyled>
            )}
            {(products || []).length >= 1 && (
              <GridStyled
                style={{ bottom: -35, maxWidth: '60%', margin: 'auto' }}
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item sm={12}>
                  <ProductCard
                    handleClick={handleProductCardClick}
                    product={products[products.length - 1]}
                  />
                  {products.length > 1 && (
                    <div style={{ marginTop: 8 }}>
                      <Button01 fullWidth onClick={handleProductsButtonsClick}>
                        <FormattedMessage
                          id="scanner-modal.b2b.multiple-items.label"
                          values={{ itemCount: products.length }}
                        />
                      </Button01>
                    </div>
                  )}
                </Grid>
              </GridStyled>
            )}
            <Scandit
              onScan={onScan}
              paused={!open || paused}
              enableCameraSwitcher
              scanningPaused
              scanSettings={{
                gpuAcceleration: false,
              }}
            />
            {!scannerInit && !scannerLibInit && (
              <LoadingScannerWrapper>
                <SpinnerStyled />
                <Heading03>
                  <FormattedMessage id="scanner-modal.scanner-is-loading" />
                </Heading03>
              </LoadingScannerWrapper>
            )}
          </Wrapper>
        </>
      </ModalStyled>
    );
  }
}
