import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const ImageStyled = styled.img`
  width: 100px;
  height: 239px;
  object-fit: contain;
`;

export const ImagePlaceholder = styled.div`
  height: 239px;
`;

export const ImageAward = styled.img`
  width: 32px;
  height: 32px;
`;

export const ProductImageContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.neutral01};
  border: solid 1px ${({ theme }) => theme.palette.neutral05};
  padding: 24px 0;
  width: 288px;
  ${breakpoint('xs', 'sm')`
    min-width: 100vw;
    width: 100%;
  `};

  ${breakpoint('md')`
    width: 364px;
  `};
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconsWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  left: 16px;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  > *:not(:last-child) {
    padding-bottom: 8px;
  }
`;
