import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatLocation } from 'utils';
import { formatVolume } from 'utils/product.utils';

import type { Product } from 'types/products.types';

import { Spinner } from 'components/@common';
import FancyPrice from 'components/@product/Specification/Price/FancyPrice';
import WineRackContainer from 'components/@product/WineRack/WineRackContainer';
import WineRackTitle from 'components/@product/WineRack/WineRackTitle/WineRackTitle';

import {
  FullWidthGrid,
  GridContainer,
  Label,
  ProductImageStyled,
  Text,
} from './PDLeft.styled';

type Props = {
  product: Product,
  locationLoading: boolean,
};

export default class PDLeft extends PureComponent<Props> {
  withLabel = (intlId: string, body?: ReactNode): ReactNode => (
    <div>
      <Label>{intlId && <FormattedMessage id={intlId} />}</Label>
      {body}
    </div>
  );

  withLabelAndText = (intlId: string, body?: ReactNode): ReactNode =>
    body ? (
      <FullWidthGrid item>
        <>{this.withLabel(intlId, <Text>{body}</Text>)}</>
      </FullWidthGrid>
    ) : null;

  render() {
    const { product, locationLoading } = this.props;

    const price = _get(product, 'shopInfo.priceAv', '');

    return (
      <div>
        <GridContainer container direction="column">
          <FullWidthGrid item>
            <ProductImageStyled
              labels={_get(product, 'labels', [])}
              src={_get(product, 'media.880')}
            />
          </FullWidthGrid>
          {!!price && (
            <FullWidthGrid item>
              {this.withLabel(
                'product.details.label.price',
                <FancyPrice value={price} />,
              )}
            </FullWidthGrid>
          )}
          <Grid item xs={12}>
            {locationLoading && <Spinner />}

            {!locationLoading &&
              _get(product, 'shopInfo.published') &&
              _get(product, 'shopInfo.locationX') &&
              this.withLabel(
                'product.details.label.location',
                <WineRackContainer
                  container
                  readOnly
                  spacing={1}
                  inProductRange={_get(product, 'shopInfo.published', false)}
                >
                  <WineRackTitle
                    readOnly
                    location={formatLocation(_get(product, 'shopInfo', {}))}
                    inProductRange={_get(product, 'shopInfo.published', false)}
                  />
                </WineRackContainer>,
              )}
          </Grid>

          {this.withLabelAndText(
            'product.details.label.volume',
            formatVolume(product),
          )}

          {this.withLabelAndText(
            'product.details.label.storage-time',
            _get(product, 'wineStorageTime'),
          )}

          {this.withLabelAndText(
            'product.details.label.pouring-temperature',
            _get(product, 'pouringTemperature'),
          )}

          {this.withLabelAndText(
            'product.details.label.wine-closure-type',
            _get(product, 'wineClosureType.value'),
          )}

          {this.withLabelAndText(
            'product.details.label.product-id',
            _get(product, 'productId'),
          )}
        </GridContainer>
      </div>
    );
  }
}
