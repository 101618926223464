import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { getBulkUpdateDescription } from 'utils/index';

import { wineAnalytics } from 'services/analytics/Tealium';

import Button01 from 'components/@common/Button/Button01';
import { SpinnerStyled } from 'components/@common/Spinner/Spinner.styled';
import ToastContent from 'components/@common/Toast/ToastContent';
import InputField01 from 'components/@form/InputField01';

type ShopInfo = {
  locationX: string,
  locationY: string,
};

type Props = {
  products: object[],
  onUpdateProducts: (payload) => void,
  onCancel: () => void,
  handleChangeX: (event: SyntheticInputEvent<>) => void,
  handleChangeY: (event: SyntheticInputEvent<>) => void,
  type: string,
  shopInfo: ShopInfo,
  isUpdatingProducts: boolean,
};

type State = ShopInfo & {
  hasError: boolean,
};

class BulkUpdateProducts extends PureComponent<Props, State> {
  // this.props.locationX and this.props.locationY can be null. If they're null, state should be ''
  state: State = {
    locationX: '',
    locationY: '',
    hasError: false,
  };

  handleChangeX = (event: Event) => {
    // Row: Only letters allowed
    const { value } = event.target;
    this.setState(({ locationX }) => ({
      locationX:
        /^[a-zA-Z]+$/.test(value) || value === ''
          ? `${value}`.toUpperCase()
          : locationX,
      hasError: false,
    }));
  };

  handleChangeY = (event: Event) => {
    const { value } = event.target;

    this.setState(({ locationY }) => ({
      locationY: /^[0-9]+$/.test(value) || value === '' ? value : locationY,
      hasError: false,
    }));
  };

  handleClick = () => {
    const { onUpdateProducts, products } = this.props;
    const { locationX, locationY } = this.state;

    if ((!locationX && !locationY) || locationX) {
      wineAnalytics.trackEventWineBulkAdjustLocation(products);

      onUpdateProducts({ ...this.state, products });
    } else {
      this.setState({
        hasError: true,
      });
    }
  };

  render() {
    const { locationX, locationY, hasError } = this.state;
    const { onCancel, type, products, isUpdatingProducts } = this.props;

    return (
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            {getBulkUpdateDescription(products, type)}
          </Grid>
        </Grid>
        {hasError && (
          <ToastContent
            variant="error"
            intlId="toast.error.update-products-location-fill-all-values"
          />
        )}
        <Grid container direction="row" spacing={2}>
          <Grid item xs={6}>
            <InputField01
              type="text"
              label={<FormattedMessage id={`products.${type}-modal.row`} />}
              value={locationX}
              onChange={this.handleChangeX}
            />
          </Grid>
          <Grid item xs={6}>
            <InputField01
              type="text"
              pattern="\d*"
              label={<FormattedMessage id={`products.${type}-modal.field`} />}
              value={locationY}
              onChange={this.handleChangeY}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button01 onClick={onCancel} color="secondary">
              <FormattedMessage id="common.button.cancel" />
            </Button01>
          </Grid>
          <Grid item>
            {isUpdatingProducts ? (
              <Box mx={1}>
                <SpinnerStyled />
              </Box>
            ) : (
              <Button01
                onClick={this.handleClick}
                color="primary"
                isLoading={isUpdatingProducts}
              >
                <FormattedMessage
                  id={`products.${type}-modal.${type}-assortiment`}
                />
              </Button01>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default BulkUpdateProducts;
