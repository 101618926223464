import { rgba } from 'polished';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import ButtonBase from '../ButtonBase';

export const Button03Styled = styled(ButtonBase)`
  && {
    svg {
      width: 18px;
      height: 18px;
      position: relative;
      margin-right: ${(props) => props.theme.spacings.spacing02};
    }

    &:hover {
      > span {
        fill: ${(props) => rgba(props.theme.palette.neutral02, 0.65)};
      }
      background-color: transparent;
      color: ${(props) => rgba(props.theme.palette.neutral02, 0.65)};
    }

    ${breakpoint('xs', 'sm')`
    svg {
      margin: 0;
    }
    > span > span {
      display: none;
    }
  `};
  }
`;

export default { Button03Styled };
