import { Component } from 'react';
import { connect } from 'react-redux';

import history from 'services/history';
import {
  loadUserFromTokenAction,
  setAppLoadingAction,
} from 'store/app/app.slice';
import { getLoggedIn } from 'store/auth/auth.selectors';
import { getUserId } from 'store/user/user.selectors';

const mapStateToProps = (state) => ({
  loggedIn: getLoggedIn(state),
  userId: getUserId(state),
});

const mapDispatchToProps = {
  loadUserFromTokenAction,
  setAppLoadingAction,
};

const withAuth = (WrappedComponent) => {
  class Wrapped extends Component {
    componentDidMount() {
      const { loggedIn } = this.props;

      if (!loggedIn) {
        history.push('/login');
      }
    }

    render() {
      const { loggedIn, userId, ...rest } = this.props;

      if (loggedIn && userId) {
        return <WrappedComponent {...rest} />;
      }

      return null;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(Wrapped);
};

export default withAuth;
