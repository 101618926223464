import styled, { css } from 'styled-components';

import ButtonBase from '../ButtonBase';

const getThemedStyled = (props) => {
  const { theme, $isDark, color } = props;
  const { button02 } = theme.flavor;

  const group = color === 'secondary' ? 'secondary' : 'primary';
  const variant = $isDark ? 'dark' : 'light';
  return css`
    padding: 0;
    color: ${button02[group][variant].color};
    font-size: ${button02[group][variant].fontSize};

    > span {
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-bottom-color: ${button02[group][variant].borderColor};
    }

    &:hover {
      background-color: transparent;
      color: ${button02[group][variant].hover.color};
    }

    &:active {
      color: ${button02[group][variant].active.color};
      > span {
        border-color: ${button02[group][variant].active.borderColor};
      }
    }

    &:disabled {
      color: ${button02[group][variant].disabled.color};
      > span {
        border-color: ${button02[group][variant].disabled.borderColor};
      }
      cursor: no-drop;
    }
  `;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Button02Styled = styled(({ color, ...rest }) => (
  <ButtonBase {...rest} />
))`
  && {
    ${(props) => getThemedStyled(props)};
  }
`;

export default { Button02Styled };
