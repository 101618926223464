import { call, put, takeLatest } from 'redux-saga/effects';

import { fetchPreview } from 'services/api/preview/preview.api';
import {
  fetchPreviewFailed,
  fetchPreviewStart,
  fetchPreviewSuccess,
} from 'store/preview/preview.slice';
import { toastErrorAction } from 'store/toast/toast.slice';

// @ts-ignore
function* fetchPreviewFlow({ payload }) {
  try {
    // @ts-ignore
    const product = yield call(fetchPreview, payload);

    yield put(fetchPreviewSuccess(product.data));
  } catch (err) {
    yield put(
      toastErrorAction({
        message: 'toast.error.fetch-preview',
      }),
    );
    // @ts-ignore
    yield put(fetchPreviewFailed(err));
  }
}

function* saga() {
  yield takeLatest(fetchPreviewStart, fetchPreviewFlow);
}

export default saga;
