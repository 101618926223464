import SvgIcon from '@material-ui/core/SvgIcon';
import { Component } from 'react';

import type { ModalActionButton } from 'types/modal.types';

import { ButtonIcon01 } from 'components/@common/Button';
import IcoClose from 'components/@icons/icons/IcoClose';

import { Button01Styled, ButtonIconStyled } from './HeaderModal.styled';
import { ModalAppBarStyled } from './ModalAppBar/ModalAppBar.styled';
import { ModalTitleStyled } from './ModalTitle/ModalTitle.styled';
import { ModalToolbarStyled } from './ModalToolbar/ModalToolbar.styled';

type Props = {
  onClose?: Function,
  onActionClick?: Function,
  title: string,
  actionButton?: ModalActionButton,
};

class HeaderModal extends Component<Props> {
  renderButtons = (actionButton?: ModalActionButton, onActionClick: any) => {
    const { label, icon } = actionButton || {};
    return (
      actionButton && (
        <>
          <ButtonIconStyled isDark>
            <SvgIcon>{icon}</SvgIcon>
          </ButtonIconStyled>
          <Button01Styled
            isDark
            color="primary"
            iconLeft={icon}
            onClick={onActionClick}
          >
            {label}
          </Button01Styled>
        </>
      )
    );
  };

  render() {
    const { onClose, title, actionButton, onActionClick } = this.props;
    return (
      <ModalAppBarStyled>
        <ModalToolbarStyled>
          <ButtonIcon01 onClick={onClose}>
            <SvgIcon>
              <IcoClose />
            </SvgIcon>
          </ButtonIcon01>
          <ModalTitleStyled variant="h6">{title}</ModalTitleStyled>
          {this.renderButtons(actionButton, onActionClick)}
        </ModalToolbarStyled>
      </ModalAppBarStyled>
    );
  }
}

export default HeaderModal;
