import { filterTypes } from 'enums';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { getProducts } from 'services/api/products/products.api';
import history from 'services/history';
import { getKioskProductsPaging } from 'store/kiosk/kiosk.selectors';
import {
  fetchKioskProductsFailed,
  fetchKioskProductsStart,
  fetchKioskProductsSuccess,
  fetchKioskSuggestionProductsFailed,
  fetchKioskSuggestionProductsStart,
  fetchKioskSuggestionProductsSuccess,
  redirectToHomeFailed,
  redirectToHomeStart,
  redirectToHomeSuccess,
  setKioskProductsPaging,
} from 'store/kiosk/kiosk.slice';
import { closeAllModalsAction } from 'store/modal/modal.slice';
import { getFilterValues } from 'store/step-filter/step-filter.selectors';
import {
  setStepFilterSorting,
  setStepFilterValues,
} from 'store/step-filter/step-filter.slice';
import { toastErrorAction } from 'store/toast/toast.slice';
import { getActiveUserShopId } from 'store/user/user.selectors';

function* fetchProductsFlow() {
  // use the current filterValues
  // @ts-ignore
  const filter = yield select(getFilterValues);

  // @ts-ignore
  const activeShopId = yield select(getActiveUserShopId);
  const { pageNumber, itemsPerPage } = yield select(getKioskProductsPaging);

  if (filter.wine_region) {
    delete filter.wine_country;
  }

  try {
    // @ts-ignore
    const response = yield call(getProducts, {
      ...filter,
      local: 1,
      shop_id: activeShopId,
      max_results: itemsPerPage || 25,
      p: pageNumber || 1,
    });
    const { data } = response;

    yield put(fetchKioskProductsSuccess(data));
  } catch (err) {
    yield put(
      toastErrorAction({
        message: 'toast.error.fetch-suggestion-products',
      }),
    );
    yield put(fetchKioskProductsFailed(err));

    yield call(history.push, '/error');
  }
}

function* fetchSuggestedProductsFlow() {
  // use the current filterValues
  // @ts-ignore
  const filter = yield select(getFilterValues);
  // @ts-ignore
  const activeShopId = yield select(getActiveUserShopId);

  try {
    // if we filter on regions, we don't need the country id
    if (filter[filterTypes.WINE_REGION]) {
      filter[filterTypes.WINE_COUNTRY] = null;
    }

    // if regions are undefined, we don't want to filter on country/regions.
    // Keep in mind that when regions are NULL, we filter on all regions.
    if (filter[filterTypes.WINE_REGION] === undefined) {
      filter[filterTypes.WINE_COUNTRY] = null;
      yield put(setStepFilterValues(filter));
    }
    // @ts-ignore
    const response = yield call(getProducts, {
      ...filter,
      local: 1,
      shop_id: activeShopId,
      max_results: 5,
      p: 1,
      suggested: true,
    });
    const { data } = response;
    yield put(fetchKioskSuggestionProductsSuccess(data));
  } catch (err) {
    yield put(
      toastErrorAction({
        message: 'toast.error.fetch-suggestion-products',
      }),
    );
    yield put(fetchKioskSuggestionProductsFailed(err));

    yield call(history.push, '/error');
  }
}

function* redirectToHomeFlow() {
  try {
    // Reset all modals (If we are on home, we alsow want the barcode overlay to close)
    yield put(closeAllModalsAction());

    if (window.location.pathname !== '/kiosk') {
      // Redirect to homescreen
      yield call(history.push, `/kiosk`);
    }

    yield put(redirectToHomeSuccess());
  } catch (err) {
    // @ts-ignore
    yield put(redirectToHomeFailed(err));
  }
}

export default function* saga() {
  yield takeLatest(fetchKioskProductsStart, fetchProductsFlow);
  yield takeLatest(setKioskProductsPaging, fetchProductsFlow);
  yield takeLatest(setStepFilterSorting, fetchProductsFlow);
  yield takeLatest(
    fetchKioskSuggestionProductsStart,
    fetchSuggestedProductsFlow,
  );
  yield takeLatest(redirectToHomeStart, redirectToHomeFlow);
}
