import styled, { css } from 'styled-components';

import { fonts, palette } from 'theme/branding';

const defaultHeading = css`
  margin: 0;
  font-family: ${fonts.default}, ${fonts.fallback};
  color: ${palette.neutral02};
`;

export const Heading01 = styled.h1`
  ${({ theme }) => theme.textStyles.heading01};
  ${defaultHeading};
`;

export const Heading02 = styled.h2`
  ${({ theme }) => theme.textStyles.heading02};
  ${defaultHeading};
`;

export const Heading03 = styled.h3`
  ${({ theme }) => theme.textStyles.heading03};
  ${defaultHeading};
`;

export const Heading04 = styled.h4`
  ${({ theme }) => theme.textStyles.heading04};
  ${defaultHeading};
`;

export const Heading05 = styled.h5`
  ${({ theme }) => theme.textStyles.heading05};
  ${defaultHeading};
`;
