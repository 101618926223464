import { darken, lighten, rgba } from 'polished';

import spacingStyles from './spacingStyles';
import textStyles from './textStyles';
import zIndexes from './z-indexes';

export const palette = {
  global01: '#ed3943',
  global02: '#007a47',
  global03: '#d41a31',
  global04: '#ffb5b5',
  global05: '#f6c065',
  global06: '#418be0',
  neutral01: '#ffffff',
  neutral02: '#111111',
  neutral03: '#4a4a4a',
  neutral04: '#9b9b9b',
  neutral05: '#e6e6e6',
  neutral06: '#f8f8f8',
  black: '#000000',
};

export const fonts = {
  default: 'Netto OT, Arial, Helvetica, sans-serif',
  italic: 'Netto OT Italic, Arial, Helvetica, sans-serif',
  bold: 'Netto OT Bold, Arial, Helvetica, sans-serif',
  bold_italic: 'Netto OT Bold Italic, Arial, Helvetica, sans-serif',
  regular: 'Netto OT Regular, Arial, Helvetica, sans-serif',
  written: 'HandOfSeanSpar, Arial, Helvetica, sans-serif',
};

export const breakpoints = {
  xs: 0,
  sm: 480,
  md: 768,
  lg: 992,
  xl: 992,
  ipadPro: 1024,
};

export const fontSizes = {
  heading01: '28px',
  heading02: '24px',
  heading03: '20px',
  heading04: '16px',
  heading05: '12px',
  paragraph01: '14px',
  paragraph02: '12px',
};

export const spacings = {
  spacing01: '4px',
  spacing02: '8px',
  spacing03: '16px',
  spacing04: '24px',
  spacing05: '32px',
  spacing06: '40px',
  spacing07: '48px',
  spacing08: '72px',
  spacing09: '96px',
};

export const radius = {
  radius01: '20px',
  radius02: '8px',
};

export const flavor = {
  button01: {
    primary: {
      base: {
        borderRadius: radius.radius01,
        padding: '8px 24px',
        fontSize: '16px',
        iconSize: '24px',
        fontWeight: 'normal',
      },
      light: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        hover: {
          backgroundColor: lighten(0.1, palette.global01),
        },
        active: {
          backgroundColor: darken(0.1, palette.global01),
          transform: 'none',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          border: 'none',
        },
      },
      dark: {
        backgroundColor: palette.neutral01,
        color: palette.global01,
        border: 'none',
        hover: {
          backgroundColor: rgba(palette.neutral01, 0.95),
        },
        active: {
          backgroundColor: rgba(palette.neutral01, 0.85),
          transform: 'none',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          border: 'none',
        },
      },
    },
    secondary: {
      base: {
        borderRadius: radius.radius01,
        padding: '8px 24px',
        fontSize: '16px',
        iconSize: '24px',
        fontWeight: 'normal',
      },
      light: {
        backgroundColor: 'transparent',
        color: palette.global01,
        border: 'none',
        hover: {
          backgroundColor: rgba(palette.global01, 0.1),
        },
        active: {
          backgroundColor: rgba(palette.global01, 0.2),
          transform: 'none',
        },
        disabled: {
          backgroundColor: 'transparent',
          color: palette.neutral04,
          border: 'none',
        },
      },
      dark: {
        backgroundColor: palette.neutral01,
        color: palette.global01,
        border: 'none',
        hover: {
          backgroundColor: rgba(palette.neutral01, 0.95),
        },
        active: {
          backgroundColor: rgba(palette.neutral01, 0.85),
          transform: 'none',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          border: 'none',
        },
      },
    },
  },
  button01FullWidth: {
    primary: {
      base: {
        padding: '34px 46px',
        fontSize: '20px',
        iconSize: '30px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        hover: {
          backgroundColor: palette.global01,
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        active: {
          backgroundColor: darken(0.05, palette.global01),
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          boxShadow: 'none',
        },
      },
      dark: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        hover: {
          backgroundColor: palette.global01,
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        active: {
          backgroundColor: darken(0.05, palette.global01),
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          boxShadow: 'none',
        },
      },
    },
    secondary: {
      base: {
        padding: '34px 46px',
        fontSize: '20px',
        iconSize: '30px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: palette.neutral01,
        color: palette.global01,
        border: 'none',
        boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        hover: {
          backgroundColor: lighten(0.35, palette.global01),
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        active: {
          backgroundColor: lighten(0.35, palette.global01),
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          boxShadow: 'none',
        },
      },
      dark: {
        backgroundColor: palette.neutral06,
        color: palette.global01,
        border: 'none',
        boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        hover: {
          backgroundColor: lighten(0.35, palette.global01),
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        active: {
          backgroundColor: lighten(0.35, palette.global01),
          boxShadow: '0 -8px 16px 0 rgba(17, 17, 17, 0.08)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
          boxShadow: 'none',
        },
      },
    },
  },
  button02: {
    primary: {
      light: {
        color: palette.global01,
        fontSize: fontSizes.paragraph01,
        borderColor: darken(0.1, palette.global01),
        hover: {
          color: lighten(0.1, palette.global01),
        },
        active: {
          color: darken(0.1, palette.global01),
          borderColor: darken(0.1, palette.global01),
        },
        disabled: {
          color: palette.neutral04,
          borderColor: palette.neutral04,
        },
      },
      dark: {
        color: palette.global01,
        fontSize: fontSizes.paragraph01,
        borderColor: darken(0.1, palette.global01),
        hover: {
          color: lighten(0.1, palette.global01),
        },
        active: {
          color: darken(0.1, palette.global01),
          borderColor: darken(0.1, palette.global01),
        },
        disabled: {
          color: palette.neutral04,
          borderColor: palette.neutral04,
        },
      },
    },
    secondary: {
      light: {
        color: palette.global01,
        fontSize: fontSizes.paragraph01,
        borderColor: palette.global01,
        hover: {
          color: palette.neutral02,
        },
        active: {
          color: palette.neutral02,
          borderColor: palette.neutral02,
        },
        disabled: {
          color: palette.neutral04,
          borderColor: palette.neutral04,
        },
      },
    },
  },
  buttonicon01: {
    primary: {
      base: {
        padding: '10px',
      },
      dark: {
        backgroundColor: palette.neutral01,
        color: palette.global01,
        hover: {
          backgroundColor: rgba(palette.neutral01, 0.95),
        },
        active: {
          backgroundColor: rgba(palette.neutral01, 0.85),
        },
        disabled: {
          backgroundColor: palette.neutral05,
        },
      },
      light: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        hover: {
          backgroundColor: lighten(0.1, palette.global01),
        },
        active: {
          backgroundColor: darken(0.1, palette.global01),
        },
        disabled: {
          backgroundColor: palette.neutral05,
        },
      },
    },
  },
  button05: {
    primary: {
      base: {
        borderRadius: radius.radius01,
        padding: '18px 16px',
        fontSize: '16px',
        iconSize: '24px',
        fontWeight: 'bold',
      },
      light: {
        backgroundColor: palette.global01,
        color: palette.neutral01,
        border: 'none',
        hover: {
          backgroundColor: darken(0.1, palette.global01),
        },
        active: {
          backgroundColor: darken(0.1, palette.global01),
          transform: 'scale(1.05)',
        },
        disabled: {
          backgroundColor: palette.neutral05,
          color: palette.neutral04,
        },
      },
    },
  },
};

export default {
  name: 'B2B',
  fonts,
  palette,
  spacings,
  textStyles,
  spacingStyles,
  radius,
  fontSizes,
  breakpoints,
  zIndexes,
  flavor,
};
