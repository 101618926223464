import type { Node } from 'react';

import { SvgIconStyled } from 'components/@icons/Icon/Icon.styled';

type Props = {
  className: string,
  children: Node,
  size?: number,
};

const Icon = ({ className, children, ...rest }: Props) => (
  <SvgIconStyled className={className} {...rest}>
    {children}
  </SvgIconStyled>
);

export default Icon;
