import styled, { css } from 'styled-components';

import { Button02 } from 'components/@common/Button';
import ImgBg05 from 'components/@icons/icons/_b2c/ImgBg05';

const normalize = css`
  font-family: ${({ theme }) => theme.fonts.default};
  text-align: center;
  margin: 0;
`;

export const Paragraph = styled.p`
  ${normalize};
  color: ${({ theme }) => theme.palette.neutral03};
  font-size: 20px;
  line-height: 1.4;
`;

export const Understanding = styled.p`
  ${normalize};
  color: ${({ theme }) => theme.palette.neutral04};
  font-size: 14px;
  line-height: 1.71;
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: ${({ theme }) => theme.palette.neutral06};
`;

export const CenterWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;

export const Background = styled(ImgBg05)`
  fill: rgb(230, 230, 230);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
`;

export const WrittenTitle = styled.h1`
  color: ${({ theme }) => theme.palette.global02};
  line-height: 96px;
  font-size: 80px;
  margin: 0;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.written};
`;

export const Button02Styled = styled(Button02).attrs({
  classes: { root: 'root', label: 'label' },
})`
  &.root {
    position: absolute;
    top: 30px;
    right: 25px;
  }
`;
