import { PureComponent } from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import type { ToastState } from 'types/toast.types';

import Toast from 'components/@common/Toast';

type Props = {
  intl: IntlShape,
  toast: ToastState,
  toastCloseAction: () => void,
};

class ToastWrapper extends PureComponent<Props> {
  render() {
    const {
      toast: { open, variant, duration, message },
      toastCloseAction,
      intl,
    } = this.props;

    return (
      <Toast
        variant={variant}
        onClose={() => toastCloseAction()}
        autoHideDuration={duration}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        isOpen={open}
        message={message && intl.formatMessage({ id: message })}
      />
    );
  }
}

export default injectIntl(ToastWrapper);
