import SvgIcon from '@material-ui/core/SvgIcon';
import { locationTypes } from 'enums';
import type { Node } from 'react';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import IcoAdd from 'components/@icons/icons/IcoAdd';
import IcoEdit from 'components/@icons/icons/IcoEdit';
import {
  Button01Styled,
  ButtonIconStyled,
} from 'components/@product/WineRack/WineRackButton/WineRackButton.styled';

type WineRackButtonProps = {
  inProductRange: boolean,
  location: string,
  onAdd: () => void,
  onMove: () => void,
};

type ButtonProps = {
  icon: Node,
  text: Node,
};

const Button = ({ icon, text, ...rest }: ButtonProps) => (
  <>
    <ButtonIconStyled {...rest}>
      <SvgIcon>{icon}</SvgIcon>
    </ButtonIconStyled>
    <Button01Styled iconLeft={icon} {...rest}>
      {text}
    </Button01Styled>
  </>
);

export default class WineRackButton extends PureComponent<WineRackButtonProps> {
  renderButton = (icon: Node, handler: Function, id: string) => (
    <Button
      icon={icon}
      onClick={handler}
      isDark
      text={<FormattedMessage id={id ?? ''} />}
    />
  );

  render() {
    const { location, inProductRange, onAdd, onMove } = this.props;
    if (inProductRange) {
      return location === locationTypes.EMPTY
        ? this.renderButton(
            <IcoAdd />,
            onAdd,
            'winerack.button.choose-location',
          )
        : this.renderButton(<IcoEdit />, onMove, 'winerack.button.move');
    }
    return this.renderButton(<IcoAdd />, onAdd, 'winerack.button.add');
  }
}
