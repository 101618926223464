const IcoArrowLeftFirst = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M5.63 12l8-8L15 5.41 9.45 11h12.18v2H9.45L15 18.59 13.63 20zM4.38 4.75h-2v15h2z"
      fill="currentColor"
    />
  </svg>
);

export default IcoArrowLeftFirst;
