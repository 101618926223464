import { Component } from 'react';

import { ToastStyled } from './Toast.styled';
import ToastContent from './ToastContent';

type Props = {
  isOpen: boolean,
  message: string,
  intlId: string,
  variant: string,
  onClose: () => void,
};

class Toast extends Component<Props> {
  render() {
    const { isOpen, message, variant, intlId, onClose, ...rest } = this.props;
    return (
      <>
        <ToastStyled open={isOpen} onClose={onClose} {...rest}>
          <ToastContent
            onClose={onClose}
            variant={variant}
            message={message}
            intlId={intlId}
          />
        </ToastStyled>
      </>
    );
  }
}

export default Toast;
