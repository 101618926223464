import { Component, ReactNode } from 'react';

import IcoTriangleDown from 'components/@icons/icons/IcoTriangleDown';

import { ButtonStyled, SvgIconStyled } from './MenuButton.styled';

type Props = {
  children?: ReactNode,
  withIcon?: boolean,
  disabled?: boolean,
};

export default class MenuButton extends Component<Props> {
  render() {
    const { children, withIcon = true, ...rest } = this.props;
    return (
      <ButtonStyled disabled={rest.disabled} disableRipple {...rest}>
        {children}
        {withIcon && (
          <SvgIconStyled>
            <IcoTriangleDown />
          </SvgIconStyled>
        )}
      </ButtonStyled>
    );
  }
}
