import type { Node } from 'react';

import { StyledLabel } from './Label01.styled';

type Props = {
  bold: boolean,
  children: Node,
};

const Label01 = ({ bold = false, children }: Props) => (
  <StyledLabel bold={bold}>{children}</StyledLabel>
);

export default Label01;
