import styled from 'styled-components';

import { Heading04 } from 'theme/Heading';
import { Paragraph01 } from 'theme/Paragraph';
import { heading04neutral02bold } from 'theme/textStyles/heading';
import { paragraph01neutral02 } from 'theme/textStyles/paragraph';

export const Heading04Styled = styled(Heading04)`
  ${heading04neutral02bold};
`;

export const Paragraph01Styled = styled(Paragraph01)`
  ${paragraph01neutral02};
`;

export const OrStyled = styled.div`
  margin: 0 16px;
  align-self: center;
`;

export const WrapperStyled = styled.div`
  display: inline-flex;
`;
