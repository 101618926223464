import Grid from '@material-ui/core/Grid';
import filterTypes from 'enums/filter.enum';
import viewTypes from 'enums/view.enum';
import { Component } from 'react';
import { FormattedMessage, IntlShape } from 'react-intl';

import { configureDropdownSort } from 'utils';

import { wineAnalytics } from 'services/analytics/Tealium';
import { Paragraph02 } from 'theme/Paragraph';
import type { FilterChip } from 'types/filter.types';
import type { ViewType } from 'types/view.types';

import { Button04 } from 'components/@common/Button';
import DropdownSort from 'components/@common/Dropdown/DropdownSort';
import IcoPrint from 'components/@icons/icons/IcoPrint';
import { Label01 } from 'components/@product/Label';
import { SearchBarAutoComplete } from 'components/@search';

import FilterChips from './FilterChips';
import {
  GridContainerStyled,
  GridStyled01,
  GridStyled02,
} from './ProductSearch.styled';
import ViewButtons from './ViewButtons';

type Props = {
  listViewActive?: boolean,
  normalViewActive?: boolean,
  onViewTypeClick: (viewType: ViewType) => void,
  filters: FilterChip[],
  onSearchChange: (value: string) => void,
  totalResults: number,
  onPrint: () => void,
  onSort: (payload) => void,
  intl: IntlShape,
  onDeleteFilter: (payload) => void,
  onDeleteAll: () => void,
  filter: Object,
};

type State = {
  dropdownValue: string,
};

class ProductSearch extends Component<Props, State> {
  // We need to keep a local state of the dropdown value
  // This is because we need to work around the way filters work in the application
  // The state is purely for UI purpose and to make the dropdown01 component display the arrows inside of the input component
  state = {
    dropdownValue: '',
  };

  handleChange = ({ target: { value } }) => {
    // We need to keep a local state of the dropdown value
    // This is because we need to work around the way filters work in the application
    // The state is purely for UI purpose and to make the dropdown01 component display the arrows inside of the input component
    this.setState(
      {
        dropdownValue: value,
      },
      () => {
        const filter = JSON.parse(value || '{}');

        wineAnalytics.trackEventSorting(
          `${filter.filter} ${
            filter.arrowType ? filter.arrowType.toLowerCase() : ''
          }`,
        );

        this.props.onSort(filter);
      },
    );
  };

  render() {
    const {
      onViewTypeClick,
      listViewActive = false,
      normalViewActive = true,
      filters = [],
      onSearchChange,
      totalResults = 0,
      intl,
      onPrint,
      onDeleteFilter,
      onDeleteAll,
      filter,
    } = this.props;

    const { dropdownValue } = this.state;

    return (
      <GridContainerStyled container direction="column">
        <Grid item xs={12}>
          <SearchBarAutoComplete
            searchValue={filter[filterTypes.SEARCH]}
            onSearchChange={onSearchChange}
            placeholder={intl.formatMessage({
              id: 'product.search.placeholder',
            })}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between">
            <GridStyled01 item>
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
              >
                <Grid item>
                  <Paragraph02>
                    <Label01 bold>
                      {totalResults}
                      &nbsp;
                    </Label01>
                    <FormattedMessage id="product.search.results" />
                  </Paragraph02>
                </Grid>
                <Grid item>
                  <Button04 iconLeft={<IcoPrint />} onClick={onPrint}>
                    <FormattedMessage id="product.search.print-list" />
                  </Button04>
                </Grid>
              </Grid>
            </GridStyled01>
            <GridStyled02 item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={1}
              >
                <Grid item>
                  <DropdownSort
                    value={dropdownValue}
                    onChange={this.handleChange}
                    defaultOption={intl.formatMessage({
                      id: 'product.search.sort',
                    })}
                    items={configureDropdownSort(intl.messages)}
                  />
                </Grid>
                <Grid item>
                  <ViewButtons
                    onSmallViewClick={() =>
                      onViewTypeClick(viewTypes.CONDENSED)
                    }
                    onNormalViewClick={() => onViewTypeClick(viewTypes.NORMAL)}
                    listViewActive={listViewActive}
                    normalViewActive={normalViewActive}
                  />
                </Grid>
              </Grid>
            </GridStyled02>
          </Grid>
        </Grid>
        {filters.length > 0 && (
          <Grid item xs={12}>
            <FilterChips
              filters={filters}
              onDeleteFilter={onDeleteFilter}
              onDeleteAll={onDeleteAll}
            />
          </Grid>
        )}
      </GridContainerStyled>
    );
  }
}

export default ProductSearch;
