enum ModalTypes {
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
  SCANNING = 'SCANNING',
  INTRO = 'INTRO',
  KIOSK = 'KIOSK',
  PRINT = 'PRINT',
  PRINT_DETAIL = 'PRINT_DETAIL',
  THEME = 'THEME',
  DISCLAIMER = 'DISCLAIMER',
  PRODUCTS = 'PRODUCTS',
}

export default ModalTypes;
