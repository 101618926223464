import { ImageStyled } from './Flag.styled';

type Props = {
  src: string,
  h: number,
  w: number,
  className: string,
};

const Flag = ({ src, h, w, className }: Props) => (
  <ImageStyled
    className={className}
    src={src}
    height={h}
    width={w}
    alt=""
    onError={(e) => {
      e.target.style.display = 'none';
    }}
  />
);

export default Flag;
