/**
 * import routes as following: "import [name] from './[name]'"
 * & add it to the array of routes
 */
import branch from './branch';
import catalog from './catalog';
import error from './error';
import home from './home';
import kiosk from './kiosk';
import kioskCatalog from './kiosk-catalog';
import kioskFeatured from './kiosk-featured';
import kioskFilter from './kiosk-filter';
import kioskPreviewDetail from './kiosk-preview-detail';
import kioskPreviewFeatured from './kiosk-preview-featured';
import kioskSearch from './kiosk-search';
import kioskSuggestion from './kiosk-suggestion';
import login from './login';
import requestResetPassword from './request-reset-password';
import resetPassword from './reset-password';
import { Route } from './routes.types';

export default [
  branch,
  catalog,
  error,
  home,
  kiosk,
  kioskCatalog,
  kioskFeatured,
  kioskFilter,
  kioskPreviewDetail,
  kioskPreviewFeatured,
  kioskSearch,
  kioskSuggestion,
  login,
  requestResetPassword,
  resetPassword,
] as Route[];
