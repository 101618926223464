import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import { transformPrices } from 'utils/product.utils';

import type { Product } from 'types/products.types';

import IcoPrint from 'components/@icons/icons/IcoPrint';
import Modal01 from 'components/@modals/Modal01';
import ProductDetails from 'components/@product/ProductDetails';

type Props = {
  open: boolean,
  product: Product,
  productLoading: boolean,
  onClose: () => void,
  onPrint: () => void,
  onRemoveProduct: () => void,
  onUpdateProduct: () => void,
};

export default class ProductDetailsModal extends Component<Props> {
  render() {
    const {
      open,
      product,
      productLoading,
      onClose,
      onPrint,
      onRemoveProduct,
      onUpdateProduct,
    } = this.props;

    return (
      <Modal01
        title={<FormattedMessage id="product-detail-modal.title" />}
        onClose={() => onClose()}
        open={open}
        onActionClick={onPrint}
        actionButton={{
          label: <FormattedMessage id="product-detail-modal.print" />,
          icon: <IcoPrint />,
        }}
      >
        <ProductDetails
          onUpdateProduct={onUpdateProduct}
          onRemoveProduct={onRemoveProduct}
          product={product}
          productLoading={productLoading}
          prices={transformPrices(product)}
        />
      </Modal01>
    );
  }
}
