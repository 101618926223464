import { connect } from 'react-redux';

import { getModalType } from 'store/modal/modal.selectors';
import { closeModalAction } from 'store/modal/modal.slice';
import { getPrintLoading } from 'store/print/print.selectors';
import { fetchPdfStart } from 'store/print/print.slice';
import {
  getTotalPrintDetail,
  getTotalPrintList,
} from 'store/products/products.selectors';

import Print from './Print';

const mapStateToProps = (state) => ({
  modalType: getModalType(state),
  pdfLoading: getPrintLoading(state),
  totalPrintList: getTotalPrintList(state),
  totalPrintDetail: getTotalPrintDetail(state),
});

const mapDispatchToProps = {
  closeModalAction,
  fetchPdfStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Print);
