import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './KioskSuggestion' /* webpackChunkName: "kiosk-suggestion", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.KioskSuggestion,
  authenticated: false,
};
