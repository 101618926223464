import { isProd } from 'utils/env.utils';

export interface ENV_CONFIG {
  NAME: string;
  VERSION: string;

  API_BASE: string;
  API_CLIENT_URL: string;
  API_SERVER_URL: string;
  ANALYTICS_URL?: string;
  SCANDIT_LICENSE_KEY: string;
  PORT?: number;
  ENABLE_DEV_TOOLS: boolean;
  LOCAL_STORAGE_ID: string;
  DEFAULT_CURRENCY: string;
  DEFAULT_LOCALE: string;
  DEFAULT_LOCALE_DATE: string;
  SENTRY: {
    dsn: string;
    environment?: string;
    release?: string;
  };
}

const config: ENV_CONFIG = {
  // general
  NAME: process.env.REACT_APP_NAME || '',
  VERSION: process.env.REACT_APP_VERSION || '',

  // api
  API_BASE: process.env.REACT_APP_API_BASE || '',
  API_CLIENT_URL: process.env.REACT_APP_API_CLIENT_URL || '',
  API_SERVER_URL: process.env.REACT_APP_API_SERVER_URL || '',

  // scandit
  SCANDIT_LICENSE_KEY: process.env.REACT_APP_SCANDIT_LICENSE_KEY || '',

  // analytics
  ANALYTICS_URL: process.env.REACT_APP_ANALYTICS_URL || '',

  // error tracking
  SENTRY: {
    dsn: 'https://4027fd8118274300961cb996f036cbf2@o190235.ingest.sentry.io/1464272',
    environment: process.env.REACT_APP_BUILD_ENV,
    release: isProd()
      ? `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`
      : `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_BUILD_ENV}`,
  },

  // dev
  PORT: Number(process.env.REACT_APP_PORT ?? 3000),
  ENABLE_DEV_TOOLS: Boolean(process.env.REACT_APP_ENABLE_DEV_TOOLS ?? true),
  LOCAL_STORAGE_ID: process.env.REACT_APP_LOCAL_STORAGE_ID ?? '',
  DEFAULT_CURRENCY: process.env.REACT_APP_DEFAULT_CURRENCY ?? 'EUR',
  DEFAULT_LOCALE: process.env.REACT_APP_DEFAULT_LOCALE ?? 'nl',
  DEFAULT_LOCALE_DATE: process.env.REACT_APP_DEFAULT_LOCALE_DATE ?? 'nl',
};

export default config;
