/**
 * DON'T REFACTOR THIS FILE TO A SLICE - more info in the ./README.md
 */

import { createReducer } from 'redux-act';

import {
  fetchBarcodeFail,
  fetchBarcodeStart,
  fetchBarcodeSuccess,
  initializeLibScannerFailed,
  initializeLibScannerSuccess,
  initializeScannerSuccess,
  removeProduct,
  resetProducts,
  setScannerAction,
  startScan,
  stopScan,
} from './scan.actions';

const INITIAL_STATE = {
  paused: true,
  loading: false,
  barcode: '',
  products: [],
  error: false,
  scannerInit: false,
  libInit: false,
  scanner: undefined,
  cameras: undefined,
  activeCamera: undefined,
};

// @ts-ignore
export default createReducer(
  {
    // @ts-ignore
    [fetchBarcodeStart]: (state: unknown, payload: unknown) => ({
      // @ts-ignore
      ...state,
      // @ts-ignore
      barcode: payload.barcode,
      loading: true,
    }),
    // @ts-ignore
    [fetchBarcodeSuccess]: (state: unknown, payload: unknown) => ({
      // @ts-ignore
      ...state,
      // @ts-ignore
      products: [...state.products.filter((p) => payload.id !== p.id), payload],
      loading: false,
      error: false,
    }),
    // @ts-ignore
    [resetProducts]: (state: unknown) => ({
      // @ts-ignore
      ...state,
      products: [],
    }),
    // @ts-ignore
    [removeProduct]: (state: unknown, payload: unknown) => ({
      // @ts-ignore
      ...state,
      // @ts-ignore
      products: state.products.filter((p) => p.id !== payload.id),
      loading: false,
      error: false,
    }),
    // @ts-ignore
    [fetchBarcodeFail]: (state: unknown) => ({
      // @ts-ignore
      ...state,
      loading: false,
      barcode: '',
      error: true,
    }),
    // @ts-ignore
    [startScan]: (state: unknown) => ({
      // @ts-ignore
      ...state,
      paused: false,
      error: false,
    }),
    // @ts-ignore
    [stopScan]: (state: unknown) => ({
      // @ts-ignore
      ...state,
      paused: true,
    }),
    // @ts-ignore
    [initializeScannerSuccess]: (state: unknown, payload: unknown) => ({
      // @ts-ignore
      ...state,
      // @ts-ignore
      cameras: payload.cameras,
      // @ts-ignore
      cameraTypes: payload.cameraTypes,
      scannerInit: true,
      loading: false,
    }),
    // @ts-ignore
    [initializeLibScannerSuccess]: (state: unknown) => ({
      // @ts-ignore
      ...state,
      libInit: true,
    }),
    // @ts-ignore
    [initializeLibScannerFailed]: (state: unknown, payload: unknown) => ({
      // @ts-ignore
      ...state,
      error: payload,
    }),
    // @ts-ignore
    [setScannerAction]: (state: unknown, payload: unknown) => ({
      // @ts-ignore
      ...state,
      scanner: payload,
    }),
  },
  INITIAL_STATE,
);
