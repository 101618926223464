import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

export const ChipStyled = styled(Chip)`
  color: ${({ theme }) => theme.palette.neutral02};
  font-family: ${({ theme }) => theme.fonts.bold};
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  border-radius: 8px;
`;

export default { ChipStyled };
