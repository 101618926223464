import InputAdornment from '@material-ui/core/InputAdornment';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Component } from 'react';

import { InputButtonIconStyled, InputStyled } from './Input.styled';

type Props = {
  startAdornment: Node,
  endAdornment: Node,
  onStartAdornment: Function,
  onEndAdornment: Function,
};

export default class Input extends Component<Props> {
  renderAdorment = (
    position: string,
    adornment: Node,
    adornmentFunc: () => void,
  ) =>
    adornment && (
      <InputAdornment position={position}>
        <InputButtonIconStyled disableRipple onClick={adornmentFunc}>
          <SvgIcon>{adornment}</SvgIcon>
        </InputButtonIconStyled>
      </InputAdornment>
    );

  render() {
    const {
      startAdornment,
      endAdornment,
      onStartAdornment,
      onEndAdornment,
      ...rest
    } = this.props;
    return (
      <InputStyled
        {...rest}
        startAdornment={this.renderAdorment(
          'start',
          startAdornment,
          onStartAdornment,
        )}
        endAdornment={this.renderAdorment('end', endAdornment, onEndAdornment)}
      />
    );
  }
}
