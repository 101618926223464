import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getFilter = (state: StoreState) => _get(state, 'filter');

export const getFilterShopId = (state: StoreState) =>
  _get(state, 'filter.shop_id');

export default { getFilter };
