const IcoScan = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M3 8H1V4.14a1 1 0 0 1 1-1h4v2H3zm18-2.94v3h2v-4a1 1 0 0 0-1-1h-3.83v2zM21 19h-3v2h4a1 1 0 0 0 1-1v-3.85h-2zm-18 .06v-3H1v4a1 1 0 0 0 1 1h3.79v-2zM6 7.39H4v9.79h2zm14 0h-2v9.79h2zm-11 0H8v9.79h1zm2 0h-1v9.79h1zm5.07 0H12v9.79h4.05z"
      fill="currentColor"
    />
  </svg>
);

export default IcoScan;
