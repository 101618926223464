import { css } from 'styled-components';

export const spacing01 = css`
  margin: ${({ theme }) => theme.spacings.spacing01};
`;

export const spacing01left = css`
  margin-left: ${({ theme }) => theme.spacings.spacing01};
`;

export const spacing01top = css`
  margin-top: ${({ theme }) => theme.spacings.spacing01};
`;

export const spacing01right = css`
  margin-right: ${({ theme }) => theme.spacings.spacing01};
`;

export const spacing01bottom = css`
  margin-bottom: ${({ theme }) => theme.spacings.spacing01};
`;

export const spacing02 = css`
  margin: ${({ theme }) => theme.spacings.spacing02};
`;

export const spacing02left = css`
  margin-left: ${({ theme }) => theme.spacings.spacing02};
`;

export const spacing02top = css`
  margin-top: ${({ theme }) => theme.spacings.spacing02};
`;

export const spacing02right = css`
  margin-right: ${({ theme }) => theme.spacings.spacing02};
`;

export const spacing02bottom = css`
  margin-bottom: ${({ theme }) => theme.spacings.spacing02};
`;

export default {
  spacing01,
  spacing01left,
  spacing01top,
  spacing01right,
  spacing01bottom,
  spacing02,
  spacing02left,
  spacing02top,
  spacing02right,
  spacing02bottom,
};
