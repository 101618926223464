import { isValid } from 'date-fns';
import addDays from 'date-fns/addDays';
import isWithinInterval from 'date-fns/isWithinInterval';
import parseISO from 'date-fns/parseISO';
import subMinutes from 'date-fns/subMinutes';
import { locationTypes } from 'enums';
import _get from 'lodash/get';
import _orderBy from 'lodash/orderBy';
import { FormattedMessage } from 'react-intl';

import { Paragraph01 } from 'theme/Paragraph';
import { Product, ShopInfo } from 'types/products.types';

export const formatPrice = (price: number) =>
  price != null && price > -1 ? `€${price.toFixed(2)}` : '--';

export const formatMarge = (margin: number) =>
  margin != null ? `${(Number(margin) * 100).toFixed(2)}%` : '--';

export const transformPrices = (product: Product) => {
  if (product) {
    const { shopInfo } = product;
    if (shopInfo) {
      const { priceAw, priceAv, priceMargin } = shopInfo;
      return [
        {
          title: 'product.details.price.aw',
          content: priceAw ? formatPrice(priceAw) : priceAw,
        },
        {
          title: 'product.details.price.av',
          content: priceAv ? formatPrice(priceAv) : priceAv,
        },
        {
          title: 'product.details.price.margin',
          content: priceMargin ? formatMarge(priceMargin) : priceMargin,
        },
      ];
    }
  }
  return [
    {
      title: 'product.details.price.aw',
      content: '--',
    },
    {
      title: 'product.details.price.av',
      content: '--',
    },
    {
      title: 'product.details.price.margin',
      content: '--',
    },
  ];
};

export const sortByValue = (a: Object, b: Object) => {
  // @ts-ignore
  if (a.value < b.value) {
    return -1;
  }
  // @ts-ignore
  if (a.value > b.value) {
    return 1;
  }

  return 0;
};

export const formatLocation = (shopInfo: ShopInfo) => {
  const { locationX, locationY } = shopInfo;

  if (locationX && locationY) {
    return `${locationX}${locationY}`;
  }

  if (locationX) {
    return `${locationX}`;
  }

  return locationTypes.EMPTY;
};

export const configLabels = (
  labels: { startDate: number; endDate: number }[],
) =>
  _orderBy(labels, ['weight'], ['desc'])
    .filter((label) => {
      // When the API returns no start & endDate,
      // it means the activePeriod is infinite, so we keep the label
      if (!label.startDate && !label.endDate) {
        return true;
      }

      // API gives dates with hour 00 so we need to make them the end of the day
      const dayAfterEndDate = addDays(label.endDate, 1);
      const endOfEndDate = new Date(subMinutes(dayAfterEndDate, 1));
      const startDate = new Date(label.startDate);

      if (isValid(endOfEndDate) && isValid(startDate)) {
        return isWithinInterval(Date.now(), {
          // @ts-ignore
          start: parseISO(startDate),
          // @ts-ignore
          end: parseISO(endOfEndDate),
        });
      }

      // Needs to be true, if icon has only 1 date it should always be shown.
      return true;
    })
    .slice(0, 2);

export const formatRegionCountryLabel = (product: Product) => {
  const wineRegion = _get(product, 'wineRegion.value', '');
  const country = _get(product, 'wineCountry.value', '');
  return [wineRegion, country].filter(Boolean).join(', ');
};

export const formatSubRegionCountryLabel = (product: Product) => {
  const subRegion = _get(product, 'wineSubRegion', '');
  const wineRegion = _get(product, 'wineRegion.value', '');
  const country = _get(product, 'wineCountry.value', '');
  return [subRegion, wineRegion, country].filter(Boolean).join(', ');
};

export const formatVolume = (product: Product, fallback?: unknown) => {
  if (product && product.volume) {
    return `${product.volume}${_get(product, 'volumeUnit', '')}`;
  }

  return fallback || null;
};

export const getBulkUpdateDescription = (products: Product[], type: string) => {
  const newWineCount = products.filter(
    // @ts-ignore
    (p) => p.shopInfo && !p.shopInfo.published,
  ).length;

  const adjustWineCount = products.filter(
    // @ts-ignore
    (p) => p.shopInfo && p.shopInfo.published,
  ).length;

  let descirptionId = `products.${type}-modal.description.other`;

  if (newWineCount === 1 && adjustWineCount === 1)
    descirptionId = `products.${type}-modal.description.single-both`;
  if (newWineCount === 1 && adjustWineCount !== 1)
    descirptionId = `products.${type}-modal.description.single-new-wine`;
  if (newWineCount !== 1 && adjustWineCount === 1)
    descirptionId = `products.${type}-modal.description.single-adjust-wine`;

  return (
    <Paragraph01>
      <FormattedMessage
        id={descirptionId}
        values={{
          newWineCount,
          adjustWineCount,
          b: (t: string) => <b>{t}</b>,
        }}
      />
    </Paragraph01>
  );
};
