import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { formatVolume, sortByValue } from 'utils/product.utils';

import Specification from 'components/@product/Specification';

import { GridStyled } from './PDSpecs.styled';

type Props = {
  specs: unknown[],
  product: unknown,
};

export default class PDSpecs extends PureComponent<Props> {
  renderFoodPairings = (wineFoodPairings) =>
    wineFoodPairings
      .slice()
      .filter((food) => food.value)
      .sort(sortByValue)
      .map((food, idx) => (
        <Specification
          title={
            idx === 0 ? (
              <FormattedMessage id="product.details.spec.dishes" />
            ) : null
          }
          icon={_get(food, 'b2bMedia.reference', '')}
          key={parseInt(idx.toString(), 10)}
          label={food.value}
        />
      ));

  formatGrapePercentage = (percentage) => {
    if (!percentage || parseInt(percentage, 10) === 0) {
      return '';
    }
    return percentage;
  };

  renderGrapes = (wineGrapes) =>
    wineGrapes
      .filter((grape) => grape.grape.value)
      .map((grape, idx) => (
        <Specification
          title={
            idx === 0 ? (
              <FormattedMessage id="product.details.spec.grapes" />
            ) : null
          }
          key={parseInt(idx.toString(), 10)}
          percentage={this.formatGrapePercentage(grape.percentage)}
          label={grape.grape.value}
        />
      ));

  renderSpec = (intlId: string, label: string) =>
    label && (
      <GridStyled item xs={6}>
        <Specification
          title={<FormattedMessage id={intlId ?? ''} />}
          label={label}
        />
      </GridStyled>
    );

  renderTastes = (tastes) => {
    if (tastes.length > 0) {
      return tastes.map((t) => t.value).join(', ');
    }

    return '-';
  };

  render() {
    const { product } = this.props;
    const grapes = this.renderGrapes(_get(product, 'wineGrapes', []));
    const food = this.renderFoodPairings(_get(product, 'wineFoodPairings', []));
    const tastes = this.renderTastes(_get(product, 'wineTastes', []));

    return (
      <Grid container direction="row">
        {food.length > 0 && (
          <GridStyled item xs={6}>
            {food}
          </GridStyled>
        )}
        {food.length === 0 &&
          this.renderSpec('product.details.spec.dishes', '-')}
        {grapes.length > 0 && (
          <GridStyled item xs={6}>
            {grapes}
          </GridStyled>
        )}
        {grapes.length === 0 &&
          this.renderSpec('product.details.spec.grapes', '-')}
        {this.renderSpec(
          'product.details.spec.pouring-temp',
          _get(product, 'pouringTemperature', '-'),
        )}
        {this.renderSpec(
          'product.details.spec.wine-storage-time',
          _get(product, 'wineStorageTime', '-'),
        )}
        {this.renderSpec(
          'product.details.spec.volume',
          formatVolume(product, '-'),
        )}
        {this.renderSpec(
          'product.details.spec.closure-type',
          _get(product, 'wineClosureType.value', '-'),
        )}
        {this.renderSpec('product.details.spec.taste', tastes)}
      </Grid>
    );
  }
}
