import Grid from '@material-ui/core/Grid';
import type { ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { coreAnalytics } from 'services/analytics/Tealium';
import { getLocale } from 'store/app/app.selectors';
import appSlice from 'store/app/app.slice';

import { Button02 } from 'components/@common';

import {
  GridContainerStyled,
  GridContentCentered,
  LogoFullStyled,
  PaperStyled,
} from './OnBoardingWrapper.styled';

interface Props {
  children: ReactNode;
}

const OnBoardingWrapper = ({ children }: Props) => {
  const locale = useSelector(getLocale);
  const dispatch = useDispatch();

  const handleLanguageClick = () => {
    coreAnalytics.trackLanguageSwitch(locale);
    dispatch(appSlice.actions.changeLanguageAction());
  };

  return (
    <GridContainerStyled container direction="column">
      <GridContentCentered item>
        <Button02
          onClick={handleLanguageClick}
          intlId="common.continue-alt-language"
        />
      </GridContentCentered>
      <Grid item>
        <PaperStyled>
          <Grid container spacing={3} direction="column">
            <GridContentCentered item xs={12}>
              <LogoFullStyled />
            </GridContentCentered>
            <Grid item>{children}</Grid>
          </Grid>
        </PaperStyled>
      </Grid>
    </GridContainerStyled>
  );
};

export default OnBoardingWrapper;
