import styled from 'styled-components';

import Icon from 'components/@icons/Icon';

import ButtonBase from '../ButtonBase';
import ButtonLabel from '../ButtonLabel';

export const ButtonLabelStyled = styled(ButtonLabel)`
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutral02};
`;

export const IconStyled = styled(Icon)`
  padding: 0 4px;
`;

export const Button04Styled = styled(ButtonBase)`
  && {
    padding: 0;
    font-size: 14px;
    &:hover {
      background-color: transparent;
    }
  }
`;
