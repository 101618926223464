import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { ToastState } from 'types/toast.types';

export const initialState: ToastState = {
  open: false,
  variant: 'info',
  duration: 10000,
  message: '',
};

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {
    toastSuccessAction: (
      state: ToastState,
      { payload }: PayloadAction<unknown>,
    ) => ({
      ...state,
      // @ts-ignore
      ...payload,
      open: true,
      variant: 'success',
    }),
    toastErrorAction: (
      state: ToastState,
      { payload }: PayloadAction<unknown>,
    ) => ({
      ...state,
      // @ts-ignore
      ...payload,
      open: true,
      variant: 'error',
    }),
    toastInfoAction: (
      state: ToastState,
      { payload }: PayloadAction<unknown>,
    ) => ({
      ...state,
      // @ts-ignore
      ...payload,
      open: true,
      variant: 'info',
    }),
    toastCloseAction: (state: ToastState) => {
      state.open = false;
    },
  },
});

export const {
  // @ts-ignore
  toastOpenAction,
  toastErrorAction,
  toastInfoAction,
  toastSuccessAction,
  toastCloseAction,
} = toastSlice.actions;

export default toastSlice;
