import axiosManager from 'services/api/axios';

import { mapRequestActiveTheme } from './theme.request.mapper';

type Params = {
  local?: number;
  shop_id?: string;
};

export const getThemes = (params: Params) =>
  axiosManager.get(`/themes`, { params }).then(
    (res) => mapRequestActiveTheme(res.data),
    (e) => Promise.reject(e),
  );
