import styled from 'styled-components';

import { Heading05 } from 'theme/Heading';
import { Paragraph01 } from 'theme/Paragraph';

export const PriceHeadingStyled = styled(Heading05)`
  ${({ theme }) => theme.textStyles.heading05neutral03uppercase};
  margin-bottom: 13px;
`;

export const PriceWrapper = styled.div`
  > * {
    ${({ vertical }) =>
      vertical
        ? `
    display: block;
    `
        : `
    margin: 0 2px;
    display: inline-block;
  `};
  }
`;

export const PriceStyled = styled(Paragraph01)`
  ${({ theme }) => theme.textStyles.paragraph01neutral02bold};
`;

export const PriceBig = styled.span`
  ${({ theme }) => theme.textStyles.heading03global02};
  font-weight: 700;
  font-size: ${({ small }) => (small ? '32px;' : '56px;')};
  line-height: ${({ small }) => (small ? '30px;' : '38px;')};
  margin-right: ${({ small }) => (small ? '4px;' : '8px;')};
`;

export const PriceSmall = styled.span`
  ${({ theme }) => theme.textStyles.paragraph01global02};
  font-weight: 700;
  font-size: ${({ small }) => (small ? '14px;' : '20px;')};
  line-height: 20px;
`;

export const Valuta = styled.span`
  ${({ theme }) => theme.textStyles.paragraph01global02};
  font-size: ${({ small }) => (small ? '14px;' : '20px;')};
  margin-right: ${({ small }) => (small ? '4px;' : '8px;')};
  font-weight: 700;
  line-height: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
`;
