import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Heading05 } from 'theme/Heading/Heading.styled';

export const Heading05Styled = styled(Heading05)`
  ${({ theme }) => theme.textStyles.heading05neutral03uppercase};
  ${({ theme }) => theme.spacingStyles.spacing02bottom};
`;

export const WineRackGridContainer = styled(Grid)`
  && {
    ${breakpoint('sm')`
      flex-direction: column;
      justify-content: center;
      align-items: center;
  `};

    ${breakpoint('xs', 'sm')`
      flex-direction: column;
      flex-wrap: nowrap;
  `};
  }
`;

export const WineRackGridItem = styled(Grid)`
  && {
    width: 100%;
  }
`;
