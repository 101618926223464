import styled, { css } from 'styled-components';

import { Heading05 } from 'theme/Heading';

import { Circle } from 'components/@common';

const Heading05StyledReadOnly = css`
  font-size: 20px;
  font-weight: normal;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.default};
`;

const CircleStyledReadOnly = css`
  transform: scale(1.5);
  margin-right: 8px;
`;

export const Heading05Styled = styled(Heading05)`
  ${({ theme, readOnly }) =>
    readOnly
      ? Heading05StyledReadOnly
      : theme.textStyles.heading05neutral03uppercase};
`;

export const CircleStyled = styled(Circle)`
  && {
    top: -0.05em;
    position: relative;

    ${({ readOnly }) => readOnly && CircleStyledReadOnly}
`;
