enum FilterTypes {
  SEARCH_METHOD = 'search_method',
  SEARCH = 'search',
  LOCAL = 'local',
  WINE_COUNTRY = 'wine_country',
  WINE_GRAPE = 'wine_grape',
  WINE_TYPE = 'wine_type',
  WINE_TASTE = 'wine_taste',
  WINE_DISH = 'wine_dish',
  WINE_CLOSING_TYPE = 'wine_closing_type',
  WINE_REGION = 'wine_region',
  BUDGET = 'wine_budget_range',
  BUDGET_MAX_AV = 'budget_max_av',
  BUDGET_MIN_AV = 'budget_min_av',
  BUDGET_MIN_AW = 'budget_min_aw',
  BUDGET_MAX_AW = 'budget_max_aw',
  SORT_BY = 'sort_by',
  SORT_ORDER = 'sort_order',
  SHOP_ID = 'shop_id',
  THEME_ID = 'theme_id',
  PRODUCT_TYPE = 'product_type',
}

export default FilterTypes;
