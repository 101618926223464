import styled, { css } from 'styled-components';

import { fonts, palette } from 'theme/branding';

const defaultParagraph = css`
  margin: 0;
  font-family: ${fonts.default}, ${fonts.fallback};
  color: ${palette.neutral03};
`;

export const Paragraph01 = styled.p`
  ${({ theme }) => theme.textStyles.paragraph01};
  ${defaultParagraph};
`;

export const Paragraph02 = styled.p`
  ${({ theme }) => theme.textStyles.paragraph02};
  ${defaultParagraph};
`;
