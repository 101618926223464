import { Component } from 'react';

import { getHtmlArrow } from 'utils/dropdown.utils';

import Dropdown01 from 'components/@common/Dropdown/Dropdown01';

import Option from './Option';

type Props = {
  items: { label: string, arrowType: string }[],
  defaultOption: string,
};

function decodeValue(value) {
  let decodedValue = value;
  try {
    decodedValue = JSON.parse(value);
  } catch (e) {
    decodedValue = value;
  }

  return decodedValue;
}

export default class DropdownSort extends Component<Props> {
  renderValue = (selected) => {
    const { defaultOption } = this.props;
    const data = decodeValue(selected);

    if (typeof data.label === 'string') {
      return `${data.label} ${
        data.arrowType ? getHtmlArrow(data.arrowType) : ''
      }`;
    }

    if (!selected || !selected.length) {
      return defaultOption;
    }

    return data;
  };

  render() {
    const { items, ...rest } = this.props;

    return (
      <Dropdown01 {...rest} renderValue={this.renderValue}>
        {items.map((item) => (
          <Option
            key={`${item.filter}${item.arrowType}`}
            value={JSON.stringify(item)}
            ellipsis={false}
            {...item}
          />
        ))}
      </Dropdown01>
    );
  }
}
