import { createMigrate, MigrationManifest } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createFilter from 'redux-persist-transform-filter';

import config from 'config';

import { generateStore } from 'services/redux';

import appSlice from './app/app.slice';
import reducers from './reducers';
import sagas from './sagas';

const persitingReducerUser = createFilter('user', ['activeShop']);
const persitingReducerAuth = createFilter('auth', ['authData']);
const persitingReducerApp = createFilter('app', ['language']);

// TODO: Properly Type this, left the code as-is.
const migrations: MigrationManifest = {
  // @ts-ignore
  1: (state) => ({
    ...state,
    auth: {
      // @ts-ignore
      ...state.auth,
      authData: undefined,
    },
    user: {
      // @ts-ignore
      ...state.user,
      activeShop: undefined,
    },
  }),
  // @ts-ignore
  2: () => ({}),
};

const rootStore = generateStore(sagas, reducers, {
  initApp: async (store) => {
    await store.dispatch(appSlice.actions.initApp());
  },
  enableDevTools: Boolean(config.ENABLE_DEV_TOOLS),
  migrate: createMigrate(migrations, {
    debug: Boolean(config.ENABLE_DEV_TOOLS),
  }),
  persistConfig: {
    key: 'root',
    storage,
    version: 2,
    debug: Boolean(config.ENABLE_DEV_TOOLS),
    whitelist: ['app', 'user', 'auth'],
    // add subsets to persist nested data
    transforms: [
      persitingReducerUser,
      persitingReducerAuth,
      persitingReducerApp,
    ],
  },
});

export const { store, persistor } = rootStore;

export default rootStore;
