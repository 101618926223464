import { useQuery } from '@tanstack/react-query';

import { CommonQueryOptions, QueryKeys, StaleTime } from 'queries';
import { getSearchSuggestions } from 'services/api/search';
import { Autocomplete, SearchSuggestionsParams } from 'types/search.types';

export const useSearchSuggestions = (
  { local, shopId, term }: SearchSuggestionsParams,
  config: CommonQueryOptions<Autocomplete> = {},
) => {
  const { isInitialLoading, isError, data } = useQuery({
    queryKey: QueryKeys.Products.suggestions(term, shopId),
    queryFn: () => getSearchSuggestions({ term, shopId, local }),
    keepPreviousData: true,
    enabled: term.length >= 3,
    staleTime: StaleTime.FIVE_MIN,
    ...config,
  });

  return {
    data,
    isInitialLoading,
    isError,
  };
};
