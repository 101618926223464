const IcoSearch = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M15 13.81h-.63l-.22-.22a5.21 5.21 0 1 0-.56.56l.22.22V15l4 4L19 17.81zm-4.8 0a3.6 3.6 0 1 1 3.6-3.6 3.6 3.6 0 0 1-3.6 3.6z"
      fill="currentColor"
    />
  </svg>
);

export default IcoSearch;
