import modalTypes from 'enums/modal.enum';
import { Component } from 'react';

import { wineAnalytics } from 'services/analytics/Tealium';
import type { Product } from 'types/products.types';

import { ProductDetailsModal } from 'components/@modals';

type Props = {
  modalType: string,
  modalData: Object,
  productLoading: boolean,
  closeModalAction: (count?: number) => void,
  updateProductStart: (payload: Product) => void,
  deleteProductStart: (payload: Product) => void,
  openModalAction: (payload: {
    type: typeof modalTypes,
    data: ProductType | ProductType[],
  }) => void,
  locale: string,
  user: User,
};

class ProductDetail extends Component<Props> {
  handleClose = (count: number = 1) => {
    const { closeModalAction } = this.props;
    closeModalAction(count);
  };

  handleUpdateProduct = (payload: ProductType) => {
    this.props.updateProductStart(payload);
  };

  handleRemoveProduct = (payload: Product, analyticsProduct: ProductType) => {
    wineAnalytics.trackEventWineDelete(analyticsProduct);
    this.props.deleteProductStart(payload);
  };

  handlePrint = () => {
    const { modalData: data, openModalAction } = this.props;

    openModalAction({
      type: modalTypes.PRINT_DETAIL,
      data,
    });
  };

  render() {
    const { modalType, modalData = {}, productLoading } = this.props;
    const open = modalType === modalTypes.PRODUCT_DETAIL;

    return (
      <ProductDetailsModal
        open={open}
        product={modalData}
        productLoading={productLoading}
        onClose={this.handleClose}
        onPrint={this.handlePrint}
        onUpdateProduct={this.handleUpdateProduct}
        onRemoveProduct={(payload) =>
          this.handleRemoveProduct(payload, modalData)
        }
      />
    );
  }
}

export default ProductDetail;
