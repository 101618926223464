import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import Dropdown01 from 'components/@common/Dropdown';

export const PaginationGrid = styled(({ _transparent, ...rest }) => (
  <Grid {...rest} />
))`
  ${({ theme, transparent }) =>
    !transparent && `background-color: ${theme.palette.neutral01}`};
  padding: 32px 24px;
  width: 520px;
  justify-content: center;
`;

export const Dropdown01Styled = styled(Dropdown01)`
  justify-content: center;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;

  > * {
    margin: 0 8px;
  }
`;

export const LabelBoldStyled = styled.span`
  ${({ theme }) => theme.textStyles.paragraph01};
  font-weight: 700;
`;

export const LabelStyled = styled.span`
  ${({ theme }) => theme.textStyles.paragraph01};
`;
