import MUIButton from '@material-ui/core/Button';
import styled from 'styled-components';

export const BaseButtonStyled = styled(MUIButton)`
  && {
    text-transform: none;
    font-size: 16px;
    font-family: ${({ theme }) => theme.fonts.default};
    line-height: 1.5;
    letter-spacing: normal;
    font-style: normal;
    font-stretch: normal;
    text-align: center;
    &:hover,
    &:active {
      cursor: pointer;
    }
  }
`;

export default { BaseButtonStyled };
