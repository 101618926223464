import axiosManager from 'services/api/axios';
import { BarcodeQueryParams } from 'types/barcode.types';

export const getBarcode = (barcode: string, params: BarcodeQueryParams) =>
  axiosManager
    .get(`/barcodes/${barcode}`, {
      withCredentials: true,
      params,
    })
    .then(
      (res) => res.data,
      (e) => Promise.reject(e),
    );

export default { getBarcode };
