import { Component, ReactNode } from 'react';

import { ButtonIconStyled } from './ButtonIcon01.styled';

type Props = {
  children?: ReactNode,
  isDark?: boolean,
  fontSize?: string,
  className?: string,
};

export default class ButtonIcon01 extends Component<Props> {
  render() {
    const { children, isDark, className, ...rest } = this.props;
    return (
      <ButtonIconStyled
        className={className}
        disableRipple
        isDark={isDark}
        {...rest}
      >
        {children}
      </ButtonIconStyled>
    );
  }
}
