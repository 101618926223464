import IconButton from '@material-ui/core/IconButton';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Icon from 'components/@icons/Icon';
import IcoCheck from 'components/@icons/icons/IcoCheck';
import IcoClose from 'components/@icons/icons/IcoClose';
import IcoInfo from 'components/@icons/icons/IcoInfo';
import IcoWarning from 'components/@icons/icons/IcoWarning';

import { CenteredContent, ToastContentStyled } from './ToastContent.styled';

type Props = {
  intlId?: string,
  message?: string,
  onClose?: () => void,
  variant: string,
  className: string,
};

const variantIcon = {
  success: <IcoCheck />,
  info: <IcoInfo />,
  error: <IcoWarning />,
};

class ToastContent extends Component<Props> {
  renderIcon = (icon: Node) => <Icon>{icon}</Icon>;

  renderContent = (variant: string, intlId: string, msg: string) => (
    <CenteredContent>
      {variant && this.renderIcon(variantIcon[variant])}
      <span>
        {intlId && <FormattedMessage id={intlId} />}
        {msg}
      </span>
    </CenteredContent>
  );

  hasClose = (onClose: Function) => [
    onClose && (
      <IconButton
        key="close"
        aria-label="Close"
        color="inherit"
        onClick={onClose}
      >
        {this.renderIcon(<IcoClose />)}
      </IconButton>
    ),
  ];

  render() {
    const { message, onClose, variant, intlId, className, ...rest } =
      this.props;
    return (
      <ToastContentStyled
        className={className}
        aria-describedby="client-snackbar"
        variant={variant}
        {...rest}
        message={this.renderContent(variant, intlId, message)}
        action={this.hasClose(onClose)}
      />
    );
  }
}

export default ToastContent;
