import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 136px;
`;

export const GridContainerStyled = styled(Grid)`
  padding: ${({ theme }) => theme.spacings.spacing04};
`;

export const LeftGrid = styled(Grid)`
  padding-right: 10px;
`;

export const RightGrid = styled(Grid)`
  padding-left: 10px;
`;
