import { stepTypes } from 'enums';
import _get from 'lodash/get';
import _pickBy from 'lodash/pickBy';

import { StoreState } from 'store/reducers';

export const getSteps = (state: StoreState) =>
  _get(state, 'stepFilter.steps', []);

export const getCurrentStep = (state: StoreState) =>
  _get(state, 'stepFilter.current', '');

export const getStartPoint = (state: StoreState) =>
  _get(state, 'stepFilter.startPoint', '');

export const getValues = (state: StoreState) =>
  _get(state, 'stepFilter.values', {});

export const getFilterValues = (state: StoreState) => {
  const values = _get(state, 'stepFilter.values', {});
  // filtering the keys that are not used in getProducts
  const filterValues = _pickBy(
    values,
    (value, key) => key !== stepTypes.PRIMARY && key !== stepTypes.SECONDARY,
  );
  return filterValues;
};

export const getFilterShopId = (state: StoreState) =>
  _get(state, 'stepFilter.values.shop_id', {});

export const getStepsFilterLoading = (state: StoreState) =>
  _get(state, 'stepFilter.loading', true);

export const getWine = (state: StoreState) =>
  _get(state, 'stepFilter.wine', {});
