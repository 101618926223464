import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import { Heading01 } from 'theme/Heading';
import { Paragraph01 } from 'theme/Paragraph';

import Flag from 'components/@product/Flag';

export const FullWidthGrid = styled(Grid)`
  && {
    width: 100%;
  }
`;

export const GridContainer = styled(Grid)`
  > ${FullWidthGrid} {
    padding: 12px 0;
    padding-left: 10px;
  }
`;

export const Heading01Styled = styled(Heading01)`
  margin-top: 8px;
  margin-bottom: 16px;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    max-width: 300px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.ipadPro}px) {
    max-width: 475px;
  }
`;

export const Paragraph01Styled = styled(Paragraph01)`
  ${({ theme }) => theme.textStyles.paragraph01neutral02};
  margin-bottom: 18px;
  font-size: 20px;
  margin-right: 24px;
`;

export const FlagStyled = styled(Flag)`
  margin-right: 16px;
  position: relative;
  top: -1px;
`;
