import styled from 'styled-components';

import { Heading05 } from 'theme/Heading';

import ProductDetailsModalB2c from 'components/@modals/ProductDetailsModal/b2c';

export const ProductDetailsModalB2cStyled = styled(
  ProductDetailsModalB2c,
).attrs({
  classes: { paper: 'paper' },
  BackdropProps: { invisible: true },
})`
  && .paper {
    box-shadow: none;
  }
`;

export const ItemCountWrapper = styled.div`
  padding: 0 24px;
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.neutral05}`};
`;

export const ItemCountLabel = styled(Heading05)`
  font-weight: 700;
  font-size: 14;
  padding: 16px 0;
`;

export const ItemListWrapper = styled.div`
  margin-bottom: 80px;
`;

export const ActionBarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #e6e6e6;
  height: 80px;
  background: white;
`;
