import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getModalOpen = (state: StoreState) => _get(state, 'modal.open');
export const getModalType = (state: StoreState) => {
  const type = _get(state, 'modal.type');
  return type[type.length - 1];
};
export const getModalData = (state: StoreState) => _get(state, 'modal.data');
