import { filterTypes, startPointTypes, stepTypes } from 'enums';
import _get from 'lodash/get';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import history from 'services/history';
import { fetchKioskSuggestionProductsStart } from 'store/kiosk/kiosk.slice';
import { getFilterWineTypes } from 'store/products/products.selectors';

import {
  getCurrentStep,
  getFilterValues,
  getStartPoint,
  getSteps,
} from './step-filter.selectors';
import {
  chooseWineType,
  nextStep,
  prevStep,
  resetStepFilter,
  setCurrentStep,
  setStepFilterLoading,
  setStepFilterValues,
  setSteps,
  setWine,
  startStepFilter,
} from './step-filter.slice';

// @ts-ignore
function* nextStepFlow(action) {
  const nextStepType: string = action.payload;
  const steps: string[] = yield select(getSteps);

  // if the step doesn't exist in steps, set it as current step
  if (!steps.includes(nextStepType)) {
    yield put(setSteps([...steps, nextStepType]));
    yield put(setCurrentStep(nextStepType));
    return;
  }

  // if the current step is the COUNTRY then we need to show the REGIONS
  if (nextStepType === stepTypes.COUNTRY) {
    // the next step will be the REGIONS
    yield put(setSteps([...steps, stepTypes.REGION]));
    yield put(setCurrentStep(stepTypes.REGION));
    return;
  }

  const secondaryNotFound =
    steps.findIndex((s) => s === stepTypes.SECONDARY) === -1;
  if (secondaryNotFound) {
    // check if the last step is WINE to set the titles right for step SECONDARY
    yield put(setSteps([...steps, stepTypes.SECONDARY]));
    yield put(setCurrentStep(stepTypes.SECONDARY));
    return;
  }

  if (steps.length >= 4) {
    // set the filter params
    yield put(setStepFilterLoading(false));
    yield put(fetchKioskSuggestionProductsStart());

    yield call(history.push, `/kiosk/suggestion`);
  }
}

function* prevStepFlow() {
  // @ts-ignore
  const currentStep = yield select(getCurrentStep);
  // @ts-ignore
  const stepStartPoint = yield select(getStartPoint);
  // @ts-ignore
  const stepFilterValues = yield select(getFilterValues);

  // go to home when you clicked on a wine on the homepage
  if (
    currentStep === stepTypes.SECONDARY &&
    stepStartPoint === startPointTypes.HOME_WINE
  ) {
    // go to homepage
    yield call(history.push, `/kiosk`);

    // reset the filters
    yield put(resetStepFilter());
  } else {
    const steps: string[] = yield select(getSteps);

    // findIndex of the currentStep
    let idx = 0;

    // If we have secondary step in our steps and we are not on the secondary step itself
    // we will find the index of the Secondary step.
    // If we have secondary step in our steps and we are on the secondary step, we will find the index of the Primary step.
    // TODO: Maybe selector in future?
    if (
      steps.includes(stepTypes.SECONDARY) &&
      currentStep !== stepTypes.SECONDARY
    ) {
      idx = steps.findIndex((step) => step === stepTypes.SECONDARY);
    } else {
      idx = steps.findIndex((step) => step === stepTypes.PRIMARY);
    }

    // 1. Get every step after idx
    // 2. Put those keys in array
    const undoSteps = steps.slice(idx + 1);

    // 2.1 Budget filter
    //     We also need to check on budget special case and push those onto undoSteps
    if (undoSteps.includes(stepTypes.BUDGET)) {
      undoSteps.push(filterTypes.BUDGET_MIN_AV);
      undoSteps.push(filterTypes.BUDGET_MAX_AV);
    }

    // 3. make newStepFilterValues filtering out keys that are in the array
    const newStepFilterValues = Object.keys(stepFilterValues).reduce(
      (acc, key) => {
        // If for budget_av_max and stuff
        // Because those are a also keys inside of this stepFilterValues object

        if (undoSteps.indexOf(key) > -1) {
          return acc;
        }

        return {
          ...acc,
          [key]: stepFilterValues[key],
        };
      },
      {},
    );

    // 4. setStepFilterValues(newStepFilterValues)
    yield put(setStepFilterValues(newStepFilterValues));

    // go back to nearest PRIMARY or SECONDARY step
    yield put(setSteps(steps.slice(0, idx + 1)));

    // set the current step to PRIMARY index of SECONDARY index
    yield put(setCurrentStep(steps[idx]));
  }
}

function* startStepsFlow() {
  // put the primary step in steps
  yield put(setSteps([stepTypes.PRIMARY]));
  // set current step to PRIMARY
  yield put(setCurrentStep(stepTypes.PRIMARY));
}

// @ts-ignore
function* chooseWineFlow({ payload }) {
  const { wineType } = payload;
  const filterWineTypes: unknown[] = yield select(getFilterWineTypes);

  if (filterWineTypes.length > 0) {
    // trying to get the ID of the wineType so that we prefill the filter with the right ID
    // @ts-ignore
    const filterWineType = filterWineTypes.find((wt) => wt.color === wineType);
    const wineTypeId = _get(filterWineType, 'id', null);

    if (wineTypeId) {
      // try to prefill the values of step-filter
      yield put(
        setStepFilterValues({
          [filterTypes.WINE_TYPE]: wineTypeId,
        }),
      );
      // set the wine
      // @ts-ignore
      yield put(setWine(filterWineType));
      // go forward in steps and fill those in until SECONDARY
      yield put(
        setSteps([stepTypes.PRIMARY, stepTypes.WINE, stepTypes.SECONDARY]),
      );
      // set the current step to SECONDARY, because we already have chosen a WINE_TYPE
      yield put(setCurrentStep(stepTypes.SECONDARY));
    }
  }
}

export default function* saga() {
  yield takeLatest(nextStep, nextStepFlow);
  yield takeLatest(prevStep, prevStepFlow);
  yield takeLatest(startStepFilter, startStepsFlow);
  yield takeLatest(chooseWineType, chooseWineFlow);
}
