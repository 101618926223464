import { connect } from 'react-redux';

import { getModalData, getModalType } from 'store/modal/modal.selectors';
import { closeModalAction } from 'store/modal/modal.slice';
import { getPrintLoading } from 'store/print/print.selectors';
import { fetchPdfStart } from 'store/print/print.slice';

import PrintDetail from './PrintDetail';

const mapStateToProps = (state) => ({
  modalType: getModalType(state),
  pdfLoading: getPrintLoading(state),
  modalData: getModalData(state),
});

const mapDispatchToProps = {
  closeModalAction,
  fetchPdfStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintDetail);
