import { createContext, PureComponent } from 'react';

const status = {
  ONLINE: 'online',
  OFFLINE: 'offline',
};

// @ts-ignore
export const NavigatorContext = createContext();

type State = {
  isOnline: boolean;
};

type Props = {
  // @ts-ignore
  children: ChildrenArray<Node & Element<unknown>>;
};

// @ts-ignore
export default (ComponentHOC) =>
  class extends PureComponent<Props, State> {
    // @ts-ignore
    state = {
      isOnline: true,
    };

    // @ts-ignore
    componentDidMount() {
      this.watchNetworkStatus(this.handleOnline, this.handleOffline);
    }

    // @ts-ignore
    componentWillUnmount() {
      window.removeEventListener(status.ONLINE, this.handleOnline);
      window.removeEventListener(status.OFFLINE, this.handleOffline);
    }

    // @ts-ignore
    watchNetworkStatus = (onlineCallback, offlineCallback) => {
      if (window && window.navigator && 'onLine' in window.navigator) {
        window.addEventListener(status.ONLINE, onlineCallback);
        window.addEventListener(status.OFFLINE, offlineCallback);
      }
    };

    handleOffline = () => {
      this.setState({
        isOnline: false,
      });
    };

    handleOnline = () => {
      this.setState({
        isOnline: true,
      });
    };

    // @ts-ignore
    render() {
      const { isOnline } = this.state;

      return (
        <NavigatorContext.Provider value={isOnline}>
          <ComponentHOC {...this.props} />
        </NavigatorContext.Provider>
      );
    }
  };
