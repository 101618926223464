import MUIButton from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import styled from 'styled-components';

export const PaperStyled = styled(Paper)`
  border-radius: 8px;
  padding: 8px;
  box-sizing: border-box;
`;

export const PopperStyled = styled(Popper)`
  ${({ theme }) => theme.zIndexes.popper};
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Button = ({ active, ...rest }) => <MUIButton {...rest} />;

export const ButtonStyled = styled(Button)`
  && {
    text-align: left;
    width: 100%;
    display: block;
    padding: 6px 4px;
    span:first-child {
      font-family: ${({ theme }) => theme.fonts.default};
      font-size: 14px;
      justify-content: flex-start;
      align-items: flex-start;
      ${({ active, theme }) =>
        active &&
        `
          font-family: ${theme.fonts.bold};
          font-weight: 700;
        `};
    }
  }
`;
