import IconButton from '@material-ui/core/IconButton';
import MUIInputBase from '@material-ui/core/InputBase';
import { rgba } from 'polished';
import styled from 'styled-components';

export const InputStyled = styled(MUIInputBase)`
  && {
    box-sizing: border-box;
    width: 100%;
    padding: 10px 8px;
    max-height: 40px;
    border-radius: 8px;
    font-family: ${({ theme }) => theme.fonts.default};
    background-color: ${({ theme }) => theme.palette.neutral01};
    border: ${(props) => `solid ${props.theme.palette.neutral05} 1px`};

    input {
      display: block;
    }

    &:focus {
      border-color: ${({ theme }) => theme.palette.neutral02};
    }

    ${(props) =>
      props.error &&
      `
      border-color: ${props.theme.palette.global01};
      background-color: ${rgba(props.theme.palette.global01, 0.1)};
      `};

    button {
      padding: 4px;
    }
  }
`;

export const InputButtonIconStyled = styled(IconButton)`
  && {
    &:hover {
      background-color: transparent;
      span {
        color: ${({ theme }) => theme.palette.neutral02};
      }
    }
  }
`;
