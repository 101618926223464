import { Spinner } from 'components/@common';

import { AppLoadingContainer } from './AppLoading.styled';

const AppLoading = () => (
  <AppLoadingContainer>
    <Spinner />
  </AppLoadingContainer>
);

export default AppLoading;
