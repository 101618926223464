import { PureComponent } from 'react';

import { ToastStyled } from './NetworkToast.styled';

type Props = {
  isOnline: Boolean,
};

export default class NetworkToast extends PureComponent<Props> {
  render() {
    const { isOnline } = this.props;

    return (
      <ToastStyled
        variant="error"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        isOpen={!isOnline}
        intlId="toast.error.no-network"
      />
    );
  }
}
