import styled, { css } from 'styled-components';

import ButtonBase from '../ButtonBase';

const getThemeStyles = (props) => {
  const { color, isDark, theme } = props;
  const { button01FullWidth } = theme.flavor;

  const group = color === 'secondary' ? 'secondary' : 'primary';
  const variant = isDark ? 'dark' : 'light';

  return css`
    padding: ${button01FullWidth[group].base.padding};
    font-size: ${button01FullWidth[group].base.fontSize};
    font-weight: ${button01FullWidth[group].base.fontWeight};
    transition: background-color 0.3s, transform 0.3s, color 0.3s;
    text-transform: uppercase;
    border-radius: 0;

    svg {
      width: ${button01FullWidth[group].base.iconSize};
      height: ${button01FullWidth[group].base.iconSize};
    }

    background-color: ${button01FullWidth[group][variant].backgroundColor};
    color: ${button01FullWidth[group][variant].color};
    border: ${button01FullWidth[group][variant].border};
    box-shadow: ${button01FullWidth[group][variant].boxShadow};

    &:hover {
      background-color: ${button01FullWidth[group][variant].hover
        .backgroundColor};
      box-shadow: ${button01FullWidth[group][variant].hover.boxShadow};
    }

    &:active {
      background-color: ${button01FullWidth[group][variant].active
        .backgroundColor};
      box-shadow: ${button01FullWidth[group][variant].active.boxShadow};
    }

    &:disabled {
      background-color: ${button01FullWidth[group][variant].disabled
        .backgroundColor};
      color: ${button01FullWidth[group][variant].disabled.color};
      box-shadow: ${button01FullWidth[group][variant].disabled.boxShadow};
      cursor: no-drop;
    }
  `;
};

export const Button01Styled = styled(ButtonBase)`
  && {
    ${(props) =>
      props.fullWidth &&
      `
        width: 100%;
    `};

    ${(props) =>
      props.iconLeft &&
      `
        svg {
          margin-right: 10px;
        }
    `};
    ${(props) =>
      props.iconRight &&
      `
        svg {
          margin-left: 10px;
        }
    `};

    ${(props) => getThemeStyles(props)};
  }
`;

export default { Button01Styled };
