import Slide from '@material-ui/core/Slide';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Component, ReactNode } from 'react';

import type { ModalActionButton } from 'types/modal.types';

import HeaderModal from '../HeaderModal/HeaderModal';
import { DialogStyled, ModalContent } from './Modal01.styled';

type Props = {
  fullScreen: boolean,
  children?: ReactNode,
  title: string,
  actionButton?: ModalActionButton,
  action?: Function,
  open?: boolean,
  className?: string,
  onClose: () => void,
};

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class Modal01 extends Component<Props> {
  render() {
    const { fullScreen, title, children, open, onClose, className, ...rest } =
      this.props;
    return (
      <DialogStyled
        className={className}
        fullScreen={fullScreen}
        maxWidth={false}
        open={open}
        TransitionComponent={Transition}
      >
        <HeaderModal onClose={onClose} title={title || ''} {...rest} />
        <ModalContent>{children}</ModalContent>
      </DialogStyled>
    );
  }
}

export default withMobileDialog({ breakpoint: 'sm' })(Modal01);
