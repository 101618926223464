import { SearchSuggestionsParams } from 'types/search.types';

enum Keys {
  Products = 'products',
  Suggestions = 'suggestions',
}

export const QueryKeys = {
  Products: {
    suggestions: (
      term: SearchSuggestionsParams['term'],
      shopId: SearchSuggestionsParams['shopId'],
    ) => [Keys.Products, Keys.Suggestions, term, shopId],
  },
};
