import _get from 'lodash/get';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import {
  deleteShopProduct,
  deleteShopProducts,
  updateShopProduct,
  updateShopProducts,
} from 'services/api/shops/shops.api';
import { getFilter } from 'store/filter/filter.selectors';
import { getModalData } from 'store/modal/modal.selectors';
import {
  closeAllModalsAction,
  setModalDataAction,
} from 'store/modal/modal.slice';
import { getIsUpdatingProducts } from 'store/product/product.selectors';
import { fetchProductsAction } from 'store/products/products.slice';
import { toastErrorAction, toastSuccessAction } from 'store/toast/toast.slice';
import { getActiveUserShopId } from 'store/user/user.selectors';

import { resetProducts } from '../scan/scan.actions';
import {
  deleteProductFailed,
  deleteProductsFailed,
  deleteProductsStart,
  deleteProductsSuccess,
  deleteProductStart,
  deleteProductSuccess,
  setIsUpdatingProducts,
  setLocationModalOpen,
  updateProductFailed,
  updateProductsFailed,
  updateProductsStart,
  updateProductsSuccess,
  updateProductStart,
  updateProductSuccess,
} from './product.slice';

// @ts-ignore
function* updateProductFlow({ payload }) {
  const { type, ...rest } = payload;
  // @ts-ignore
  const isLoading = yield select(getIsUpdatingProducts);
  try {
    if (!isLoading) {
      // @ts-ignore
      yield put(setIsUpdatingProducts(true));
    }
    // @ts-ignore
    const shopId = yield select(getActiveUserShopId);
    // @ts-ignore
    const filter = yield select(getFilter); // get current filter
    // @ts-ignore
    const updatedProduct = yield call(updateShopProduct, {
      shopId,
      ...rest,
    });
    yield put(setModalDataAction(updatedProduct));
    yield put(updateProductSuccess());
    yield put(
      toastSuccessAction({
        message:
          type === 'add'
            ? 'toast.add-product-success'
            : 'toast.move-product-success',
      }),
    );
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
    yield put(fetchProductsAction(filter));
  } catch (err) {
    // @ts-ignore
    yield put(updateProductFailed());
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
    yield put(
      toastErrorAction({
        message:
          type === 'add'
            ? 'toast.add-product-error'
            : 'toast.move-product-error',
      }),
    );
  }
}

// @ts-ignore
function* updateProductsFlow({ payload }) {
  const { ...rest } = payload;
  // @ts-ignore
  const isLoading = yield select(getIsUpdatingProducts);
  try {
    if (!isLoading) {
      // @ts-ignore
      yield put(setIsUpdatingProducts(true));
    }
    // @ts-ignore
    const shopId = yield select(getActiveUserShopId);
    // @ts-ignore
    const filter = yield select(getFilter); // get current filter

    // @ts-ignore
    const data = rest.products.map((p) => ({
      ...p,
      locationX: rest.locationX,
      locationY: rest.locationY,
    }));
    yield call(updateShopProducts, {
      shopId,
      data,
    });
    yield put(updateProductsSuccess());
    yield put(
      toastSuccessAction({
        message: 'toast.move-product-success',
      }),
    );
    yield put(closeAllModalsAction());
    yield put(setLocationModalOpen(false));
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
    yield put(resetProducts());
    yield put(fetchProductsAction(filter));
  } catch (err) {
    // @ts-ignore
    yield put(updateProductsFailed());
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
    yield put(
      toastErrorAction({
        message: 'toast.add-product-error',
      }),
    );
  }
}

// @ts-ignore
function* deleteProductFlow({ payload }) {
  // @ts-ignore
  const isLoading = yield select(getIsUpdatingProducts);
  try {
    if (!isLoading) {
      // @ts-ignore
      yield put(setIsUpdatingProducts(true));
    }
    // @ts-ignore
    const shopId = yield select(getActiveUserShopId);
    yield call(deleteShopProduct, {
      shopId,
      ...payload,
    });
    // @ts-ignore
    let product = yield select(getModalData);
    if (_get(product, 'shopInfo.published', false)) {
      // Reset product location
      product = {
        ...product,
        shopInfo: {
          ...product.shopId,
          published: false,
          locationX: '',
          locationY: '',
        },
      };
      yield put(setModalDataAction({ ...product }));
    }
    yield put(deleteProductSuccess());
    // @ts-ignore
    const filter = yield select(getFilter);
    yield put(fetchProductsAction(filter));
    yield put(
      toastSuccessAction({
        message: 'toast.remove-product-success',
      }),
    );
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
  } catch (err) {
    // @ts-ignore
    yield put(deleteProductFailed());
    yield put(
      toastErrorAction({
        message: 'toast.remove-product-error',
      }),
    );
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
  }
}
// @ts-ignore
function* deleteProductsFlow({ payload }) {
  // @ts-ignore
  const isLoading = yield select(getIsUpdatingProducts);
  try {
    if (!isLoading) {
      // @ts-ignore
      yield put(setIsUpdatingProducts(true));
    }
    // @ts-ignore
    const shopId = yield select(getActiveUserShopId);
    // @ts-ignore
    const filter = yield select(getFilter);
    // @ts-ignore
    const data = payload.map((p) => p.id);

    yield call(deleteShopProducts, {
      shopId,
      data,
    });

    yield put(deleteProductsSuccess());
    yield put(closeAllModalsAction());
    yield put(setLocationModalOpen(false));
    yield put(resetProducts());

    yield put(
      toastSuccessAction({
        message: 'toast.remove-product-success',
      }),
    );
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
    yield put(fetchProductsAction(filter));
  } catch (err) {
    // @ts-ignore
    yield put(deleteProductsFailed());
    yield put(
      toastErrorAction({
        message: 'toast.remove-product-error',
      }),
    );
    // @ts-ignore
    yield put(setIsUpdatingProducts(false));
  }
}

export default function* saga() {
  yield takeLatest(updateProductStart, updateProductFlow);
  yield takeLatest(updateProductsStart, updateProductsFlow);
  yield takeLatest(deleteProductStart, deleteProductFlow);
  yield takeLatest(deleteProductsStart, deleteProductsFlow);
}
