import { Route } from 'react-router-dom';

import { Route as RouteProps } from 'pages/routes.types';

import withAuth from 'components/@auth/auth';

interface Props extends RouteProps {
  user?: unknown;
}

// TODO: investigate if we can use this? currently I left
// each route public, but kept the same withAuth logic making sure our routes behave as the old behaviour
const RouteProtected = ({ ...rest }: Props) => {
  return <Route {...rest} />;
};

export default withAuth(RouteProtected);
