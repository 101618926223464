import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Shop } from 'types/shop.types';

type State = {
  isLoading: boolean;
  error: boolean;
  activeShop: Shop;
  shops: Shop[];
};

export const initialState: State = {
  isLoading: false,
  error: false,
  // @ts-ignore
  activeShop: {},
  shops: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    fetchUserShopsAction: () => {},
    fetchUserStart: (state: State) => {
      state.isLoading = true;
      state.error = false;
    },
    fetchUserSuccess: (state: State, { payload }: PayloadAction<unknown>) => ({
      ...state,
      // @ts-ignore
      ...payload,
      isLoading: false,
      error: false,
    }),
    fetchUserFail: (state: State) => {
      state.isLoading = false;
      state.error = true;
    },
    fetchUserShopsSuccessAction: (
      state: State,
      { payload }: PayloadAction<unknown>,
    ) => {
      // @ts-ignore
      state.shops = payload;
    },
    setActiveUserShopAction: (
      state: State,
      { payload }: PayloadAction<Shop>,
    ) => {
      state.activeShop = payload;
    },
    resetUserAction: (state: State) => ({
      ...initialState,
      activeShop: state.activeShop,
    }),
  },
});

export const {
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  fetchUserShopsAction,
  fetchUserShopsSuccessAction,
  setActiveUserShopAction,
  resetUserAction,
} = userSlice.actions;

export default userSlice;
