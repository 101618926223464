const IcoArrowRight = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z"
      fill="currentColor"
    />
  </svg>
);

export default IcoArrowRight;
