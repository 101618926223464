import { modalTypes } from 'enums';
import { PureComponent } from 'react';

import PrintDetailModal from 'components/@modals/PrintDetailModal';

type Props = {
  modalType: string,
  closeModalAction: () => void,
  fetchPdfStart: (payload: Object) => void,
  pdfLoading: boolean,
  modalData: Object,
};

class PrintDetail extends PureComponent<Props> {
  handleAccept = (payload: Object) => {
    this.props.fetchPdfStart(payload);
  };

  handleClose = () => {
    const { closeModalAction } = this.props;

    closeModalAction();
  };

  render() {
    const { modalType, ...rest } = this.props;
    const open = modalType === modalTypes.PRINT_DETAIL;
    return (
      <PrintDetailModal
        open={open}
        modalType={modalType}
        {...rest}
        onAccept={this.handleAccept}
        onClose={this.handleClose}
      />
    );
  }
}

export default PrintDetail;
