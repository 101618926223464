import Grid from '@material-ui/core/Grid';
import printActionTypes from 'enums/printAction.enum';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import SingleSelect from 'components/@common/Select/SingleSelect';
import IcoDetail from 'components/@icons/icons/IcoDetail';
import IcoList from 'components/@icons/icons/IcoList';

type Props = {
  checked: string,
  onSelect: (option: string) => void,
  totalPrintList: number,
  totalPrintDetail: number,
};

export default class PrintSelect extends PureComponent<Props> {
  handleChange = (option: string) => () => {
    this.props.onSelect(option);
  };

  getPages = (total: number) => (
    <span>
      {total} <FormattedMessage id="print-modal.select.pages" />
    </span>
  );

  render() {
    const { checked, totalPrintDetail, totalPrintList } = this.props;
    return (
      <>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <SingleSelect
              icon={<IcoDetail />}
              title={<FormattedMessage id="print-modal.select.title-sheets" />}
              small={this.getPages(totalPrintDetail)}
              checked={checked === printActionTypes.PRINT_SHEETS}
              onChange={this.handleChange(printActionTypes.PRINT_SHEETS)}
            />
          </Grid>
          <Grid item xs={6}>
            <SingleSelect
              icon={<IcoList />}
              title={<FormattedMessage id="print-modal.select.title-list" />}
              small={this.getPages(totalPrintList)}
              checked={checked === printActionTypes.PRINT_LIST}
              onChange={this.handleChange(printActionTypes.PRINT_LIST)}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}
