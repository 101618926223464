import Grid from '@material-ui/core/Grid';
import type { Node } from 'react';
import { PureComponent } from 'react';
import { fromEvent } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { AppBar, Title, Toolbar } from './OverlayHeader.styled';

type Props = {
  hasTransparentScrollingBg: boolean,
  title: string,
  left: Node,
  right: Node,
  className: string,
  transparent: boolean,
  dock: boolean,
  position: 'fixed' | 'absolute' | 'sticky' | 'static' | 'relative',
  disableDock: boolean,
};

type State = {
  scrolling: boolean,
};

export default class OverlayHeader extends PureComponent<Props, State> {
  state = {
    scrolling: false,
  };

  subscription = null;

  componentDidMount() {
    this.subscription = fromEvent(window, 'scroll')
      .pipe(
        map(() => window.scrollY !== 0),
        distinctUntilChanged(),
      )
      .subscribe((scrolling) => {
        this.setState({
          scrolling,
        });
      });
  }

  componentWillUnmount() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  render() {
    const {
      disableDock,
      title,
      left,
      right,
      transparent,
      dock,
      className,
      position,
      hasTransparentScrollingBg, // We use a secondary transparent property to avoid any regressions if we would start using the `transparent` prop on the AppBar
    } = this.props;
    const { scrolling } = this.state;

    return (
      <AppBar
        className={className}
        hasTransparentScrollingBg={hasTransparentScrollingBg}
        scrolling={disableDock ? false : scrolling || dock}
        position={position}
      >
        <Toolbar transparent={transparent}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>{left}</Grid>
            <Grid item>
              <Title>{title}</Title>
            </Grid>
            <Grid item>{right}</Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  }
}
