import { css } from 'styled-components';

export const paragraph01 = css`
  font-size: 14px;
  line-height: 20px;
  font-family: ${({ theme }) => theme.fonts.default};
`;

export const paragraph01bold = css`
  ${paragraph01};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const paragraph01neutral02 = css`
  ${paragraph01};
  color: ${({ theme }) => theme.palette.neutral02};
`;

export const paragraph01neutral02bold = css`
  ${paragraph01neutral02};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const paragraph01neutral02center = css`
  ${paragraph01neutral02};
  text-align: center;
`;

export const paragraph01neutral02right = css`
  ${paragraph01neutral02};
  text-align: right;
`;

export const paragraph01global01 = css`
  ${paragraph01};
  color: ${({ theme }) => theme.palette.global01};
`;

export const paragraph01global01bold = css`
  ${paragraph01};
  color: ${({ theme }) => theme.palette.global01};
  font-weight: 700;
`;

export const paragraph01global02 = css`
  ${paragraph01};
  color: ${({ theme }) => theme.palette.global02};
`;

export const paragraph01neutral04 = css`
  ${paragraph01};
  color: ${({ theme }) => theme.palette.neutral04};
`;

export const paragraph01global02bold = css`
  ${paragraph01global02};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const paragraph01neutral04center = css`
  ${paragraph01neutral04};
  text-align: center;
`;

export const paragraph02 = css`
  font-size: 12px;
  line-height: 12px;
  font-family: ${({ theme }) => theme.fonts.default};
`;

export const paragraph02bold = css`
  ${paragraph02};
  font-weight: 700;
  font-family: ${({ theme }) => theme.fonts.bold};
`;

export const paragraph02neutral02 = css`
  ${paragraph02};
  color: ${({ theme }) => theme.palette.neutral02};
`;

export const paragraph02global02 = css`
  ${paragraph02};
  color: ${({ theme }) => theme.palette.global02};
`;

export const paragraph02global02bold = css`
  ${paragraph02global02};
  font-weight: 700;
`;

export const paragraph02global02boldcenter = css`
  ${paragraph02global02bold};
  text-align: center;
`;

export const paragraph02global01boldleft = css`
  ${paragraph02};
  color: ${({ theme }) => theme.palette.global01};
  text-align: left;
  font-weight: 700;
`;

export default {
  paragraph01,
  paragraph01bold,
  paragraph01global01,
  paragraph01global01bold,
  paragraph01global02,
  paragraph01global02bold,
  paragraph01neutral02,
  paragraph01neutral02center,
  paragraph01neutral02bold,
  paragraph01neutral02right,
  paragraph01neutral04,
  paragraph01neutral04center,
  paragraph02,
  paragraph02bold,
  paragraph02neutral02,
  paragraph02global02,
  paragraph02global02bold,
  paragraph02global02boldcenter,
  paragraph02global01boldleft,
};
