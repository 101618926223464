import MUIInputLabel from '@material-ui/core/InputLabel';
import styled from 'styled-components';

export const InputLabelStyled = styled(MUIInputLabel)`
  && {
    ${({ theme }) => theme.textStyles.paragraph01neutral02};
    margin-bottom: 4px;
    line-height: 1.43;
    display: block;
    ${(props) =>
      props.error &&
      `
    color: ${props.theme.palette.global01}
  `};
  }
`;

export default { InputLabelStyled };
