import modalTypes from 'enums/modal.enum';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { productsAnalytics } from 'services/analytics/Tealium';
import { getBarcode } from 'services/api/barcodes';
import { getProduct } from 'services/api/products';
import { getKiosk } from 'store/app/app.selectors';
import {
  closeSpecificModalAction,
  openModalAction,
} from 'store/modal/modal.slice';
import {
  fetchBarcodeFail,
  fetchBarcodeStart,
  fetchBarcodeSuccess,
  stopScan,
} from 'store/scan/scan.actions';
import { getActiveUserShop } from 'store/user/user.selectors';

// @ts-ignore
function* fetchBarcodeFlow({ payload }) {
  try {
    // @ts-ignore
    const activeShop = yield select(getActiveUserShop);
    // @ts-ignore
    const isKiosk = yield select(getKiosk);

    const params: { local: number } = {
      // @ts-ignore
      shop_id: activeShop.id,
    };

    // Don't use local in b2b
    if (isKiosk) {
      params.local = 1;
    }

    const { data: barcodeProduct } = yield call(
      // @ts-ignore
      getBarcode,
      payload.barcode,
      params,
    );
    // @ts-ignore
    const { data: product } = yield call(getProduct, barcodeProduct.id, params);

    // @ts-ignore
    yield put(fetchBarcodeSuccess(product));

    if (isKiosk) {
      yield put(closeSpecificModalAction({ type: 'SCANNING' }));
      yield put(
        openModalAction({
          type: modalTypes.PRODUCT_DETAIL,
          data: product,
        }),
      );
      yield put(stopScan());
    }

    productsAnalytics.trackEventScanning(product);
  } catch (err) {
    productsAnalytics.trackEventScanning();
    yield put(fetchBarcodeFail());
    yield put(
      openModalAction({
        type: modalTypes.SCANNING,
      }),
    );
    yield put(stopScan());
  }
}

function* saga() {
  yield takeLatest(fetchBarcodeStart, fetchBarcodeFlow);
}

export default saga;
