import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import filterTypes from 'enums/filter.enum';

import type { FilterState } from 'types/filter.types';

const CLEAR_STATE: FilterState = {
  [filterTypes.BUDGET_MAX_AV]: 0,
  // @ts-ignore
  [filterTypes.BUDGET_MIN_AV]: 0,
  [filterTypes.BUDGET_MIN_AW]: 0,
  [filterTypes.BUDGET_MAX_AW]: 0,
  [filterTypes.WINE_COUNTRY]: [],
  [filterTypes.WINE_GRAPE]: [],
  [filterTypes.WINE_TYPE]: [],
  [filterTypes.WINE_TASTE]: [],
  [filterTypes.WINE_DISH]: [],
  [filterTypes.WINE_CLOSING_TYPE]: [],
  [filterTypes.WINE_REGION]: [],
  [filterTypes.THEME_ID]: undefined,
  [filterTypes.PRODUCT_TYPE]: undefined,
  [filterTypes.LOCAL]: 0,
};

export const initialState: FilterState = {
  [filterTypes.SEARCH_METHOD]: undefined,
  [filterTypes.LOCAL]: 0,
  [filterTypes.SEARCH]: '',
  ...CLEAR_STATE,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setFilterAction: (
      state: FilterState,
      // @ts-ignore
      { payload }: PayloadAction<{ [string]: number | number[] }>,
    ) => {
      Object.keys(payload).forEach((key) => {
        // @ts-ignore
        state[key] = payload[key];
      });
    },
    setKioskFilter: (
      state: FilterState,
      // @ts-ignore
      { payload }: PayloadAction<{ [string]: number | number[] }>,
    ) => {
      // @ts-ignore
      Object.keys(payload).forEach((key) => {
        // @ts-ignore
        state[key] = payload[key];
      });
    },
    resetFilterAction: (state: FilterState) => ({
      ...initialState,
      [filterTypes.LOCAL]: state[filterTypes.LOCAL],
    }),
    clearFilterChipsStart: () => CLEAR_STATE,
    clearFilterChipStart: (
      state: FilterState,
      { payload }: PayloadAction<{ filterKey: string }>,
    ) => {
      // @ts-ignore
      state[payload.filterKey] = initialState[payload.filterKey];
    },
  },
});

export const {
  setFilterAction,
  setKioskFilter,
  resetFilterAction,
  clearFilterChipsStart,
  clearFilterChipStart,
} = filterSlice.actions;

export default filterSlice;
