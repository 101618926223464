import { NavigatorContext } from './NavigatorProvider';

export type Navigator = {
  isOnline: boolean;
};

// @ts-ignore
export default (Component) => (props) =>
  (
    <NavigatorContext.Consumer>
      {(c) => <Component {...props} isOnline={c} />}
    </NavigatorContext.Consumer>
  );
