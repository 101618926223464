import Dialog from '@material-ui/core/Dialog';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

export const DialogStyled = styled(Dialog)`
  && {
    ${breakpoint('md')`
      > div {
        min-width: 768px;
        margin: 0;
      }
    `};
    .MuiPaper-root {
      min-width: 384px;
    }
  }
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  max-width: 785px;
  -webkit-overflow-scrolling: touch;
`;
