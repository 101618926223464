import { connect } from 'react-redux';

import { getNextLocaleForAnalytics } from 'store/app/app.selectors';
import { changeLanguageAction } from 'store/app/app.slice';
import { resetFilterAction } from 'store/filter/filter.slice';
import { closeModalAction } from 'store/modal/modal.slice';
import { resetProducts, stopScan } from 'store/scan/scan.actions';
import { resetStepFilter } from 'store/step-filter/step-filter.slice';
import { getActiveUserShopId } from 'store/user/user.selectors';

import Header from './Header';

const mapStateToProps = (state) => ({
  uuid: getActiveUserShopId(state),
  analyticsLocale: getNextLocaleForAnalytics(state),
});

const mapDispatchToProps = {
  changeLanguageAction,
  resetFilterAction,
  resetStepFilter,
  closeModalAction,
  resetProducts,
  stopScan,
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
