import { Component } from 'react';

import type { MenuItem } from 'types/menu.types';

import MenuButton from 'components/@common/Menu/MenuButton';
import MenuIconButton from 'components/@common/Menu/MenuIconButton/MenuIconButton';
import MenuPopper from 'components/@common/Menu/MenuPopper';

type Props = {
  menuItems: MenuItem[],
  withIcon: boolean,
  onMenuItemSelect: (menuItem: MenuItem) => void,
  disabled: boolean,
};

type State = {
  anchorEl: HTMLButtonElement | null,
  open: boolean,
};

export default class Menu extends Component<Props, State> {
  state = {
    anchorEl: null,
    open: false,
  };

  handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  handleClickAway = (event: SyntheticEvent<HTMLButtonElement>) => {
    if (this.state.anchorEl.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleMenuItemClick = (item: MenuItem) => {
    this.props.onMenuItemSelect(item);
    this.setState((state) => ({
      ...state,
      open: !state.open,
    }));
  };

  renderButtons = (id: string) => {
    const { withIcon, menuItems = [], disabled } = this.props;
    const menuItem: MenuItem = menuItems.find((mi) => mi.active === true) || {};

    if (withIcon) {
      return <MenuIconButton disabled={disabled} onClick={this.handleClick} />;
    }

    if (menuItems.length > 1) {
      return (
        <MenuButton
          disabled={disabled}
          aria-describedby={id}
          onClick={this.handleClick}
          withIcon
        >
          {menuItem.label}
        </MenuButton>
      );
    }

    return (
      <MenuButton disabled={disabled} aria-describedby={id} withIcon={false}>
        {menuItem.label}
      </MenuButton>
    );
  };

  render() {
    const { anchorEl, open } = this.state;
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { withIcon, menuItems, onMenuItemSelect, ...rest } = this.props;
    const id = open ? 'menu-poppper' : '';
    const placement = withIcon ? 'bottom-end' : 'bottom-start';
    return (
      <>
        {this.renderButtons(id)}
        <MenuPopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClickAway={this.handleClickAway}
          menuItems={menuItems}
          placement={placement}
          onMenuItemClick={(item) => this.handleMenuItemClick(item)}
          {...rest}
        />
      </>
    );
  }
}
