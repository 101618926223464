import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import { type Locale, locales } from 'services/i18n/config';

type State = {
  isLoading: boolean;
  language: { locale: Locale };
  kiosk: boolean;
};

export const initialState: State = {
  isLoading: true,
  // @ts-ignore
  language: { locale: locales.nl },
  kiosk: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    initApp: () => {},
    loadUserFromTokenAction: () => {},
    reloadAppAction: () => {},
    changeLanguageAction: () => {},
    setAppLoadingAction: (
      state: State,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isLoading = payload;
    },
    setLanguageAction: (state: State, { payload }: PayloadAction<Locale>) => {
      // @ts-ignore
      state.language = { ...payload };
    },
    setKioskAction: (state: State, { payload }: PayloadAction<boolean>) => {
      state.kiosk = payload;
    },
    resetKioskAction: (state: State) => {
      state.kiosk = false;
    },
    loadKioskAction: () => {},
  },
});

export const {
  initApp,
  loadUserFromTokenAction,
  reloadAppAction,
  changeLanguageAction,
  setAppLoadingAction,
  setLanguageAction,
  setKioskAction,
  resetKioskAction,
  loadKioskAction,
} = appSlice.actions;

export default appSlice;
