/* eslint-disable @typescript-eslint/no-unused-vars */
import { productActionTypes } from 'enums';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { wineAnalytics } from 'services/analytics/Tealium';
import type { Product } from 'services/api/spec/api-spec';

import Modal02 from 'components/@modals/Modal02';

import BulkRemoveProducts from './BulkRemoveProducts';
import BulkUpdateProducts from './BulkUpdateProducts';
import RemoveProduct from './RemoveProduct';
import UpdateProduct from './UpdateProduct';

type ShopInfo = {
  locationX: string,
  locationY: string,
};
type Props = {
  onRemoveProduct: () => void,
  onUpdateProduct: () => void,
  onUpdateProducts: () => void,
  onRemoveProducts: () => void,
  onCancel: () => void,
  type: string,
  shopInfo: ShopInfo,
  product: Product,
  products: Product[],
  inProductRange: boolean,
  isUpdatingProducts: boolean,
};

export default class ProductLocationModal extends PureComponent<Props> {
  titles = {
    move: 'product.move-modal.title',
    add: 'product.add-modal.title',
    remove: 'product.remove-modal.title',
    'bulk-update': 'product.bulk-update-modal.title',
    'bulk-remove': 'product.bulk-remove-modal.title',
  };

  renderContent = (type: string) => {
    const {
      shopInfo,
      onRemoveProduct,
      onUpdateProduct,
      onCancel,
      inProductRange,
      product,
      products,
      onUpdateProducts,
      onRemoveProducts,
      isUpdatingProducts,
    } = this.props;

    switch (type) {
      case productActionTypes.MOVE:
      case productActionTypes.ADD:
        return (
          <UpdateProduct
            product={product}
            type={type}
            inProductRange={inProductRange}
            onUpdateProduct={onUpdateProduct}
            onCancel={onCancel}
            shopInfo={shopInfo}
            isUpdatingProducts={isUpdatingProducts}
          />
        );
      case productActionTypes.REMOVE:
        return (
          <RemoveProduct
            inProductRange={inProductRange}
            onRemoveProduct={onRemoveProduct}
            onCancel={onCancel}
            isUpdatingProducts={isUpdatingProducts}
          />
        );
      case productActionTypes.BULK_UPDATE:
        return (
          <BulkUpdateProducts
            products={products}
            onCancel={onCancel}
            onUpdateProducts={onUpdateProducts}
            onClose={onCancel}
            isUpdatingProducts={isUpdatingProducts}
            type={type}
          />
        );
      case productActionTypes.BULK_REMOVE:
        return (
          <BulkRemoveProducts
            type={type}
            products={products}
            onCancel={onCancel}
            isUpdatingProducts={isUpdatingProducts}
            onRemoveProducts={() => {
              wineAnalytics.trackEventBulkWineDelete(products);
              onRemoveProducts(products);
            }}
            onClose={onCancel}
          />
        );
      default:
        return null;
    }
  };

  render() {
    const {
      type,
      onUpdateProduct,
      onRemoveProduct,
      onCancel,
      products,
      ...rest
    } = this.props;
    const id = this.titles[type] || 'id';

    return (
      <Modal02
        {...rest}
        onClose={onCancel}
        title={
          <FormattedMessage
            id={id}
            values={{ wineCount: (products || []).length }}
          />
        }
      >
        {this.renderContent(type)}
      </Modal02>
    );
  }
}
