import styled from 'styled-components';

import ProductDetailsModalB2c from 'components/@modals/ProductDetailsModal/b2c';

export const ProductDetailsModalB2cStyled = styled(
  ProductDetailsModalB2c,
).attrs({
  classes: { paper: 'paper' },
  BackdropProps: { invisible: true },
})`
  && .paper {
    box-shadow: none;
  }
`;
