import styled, { css } from 'styled-components';

const size = (height = 30, width = 30) => css`
  height: ${height}px;
  width: ${width}px;
`;

export const ImageStyled = styled.img`
  ${({ height, width }) => size(height, width)};
`;

export default { ImageStyled };
