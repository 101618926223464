import * as Sentry from '@sentry/browser';
import { Component, ErrorInfo, ReactNode } from 'react';

import { Error as GenericError } from 'components/@listeners';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<{ children: ReactNode }> {
  state: State = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras({ root: errorInfo });
      Sentry.captureException(error);
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      // render any custom fallback UI
      return <GenericError />;
    }

    return children;
  }
}

export default ErrorBoundary;
