import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export type AuthData = {
  accessToken: string;
  email: string;
  expiresIn: number;
  refreshTokenExpiresIn: number;
  tokenType: string;
  userId: number;
  username: string;
};

type State = {
  loggedIn: boolean;
  isLoading: boolean;
  error: boolean;
  authData: AuthData | null;
};

export const initialState: State = {
  loggedIn: false,
  isLoading: false,
  error: false,
  authData: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    requestResetPasswordStart: () => {},
    requestResetPasswordSuccess: () => {},
    requestResetPasswordFail: () => {},
    resetPasswordStart: () => {},
    resetPasswordSuccess: () => {},
    resetPasswordFail: () => {},
    rememberMeAction: () => {},
    authUserStart: (state: State) => {
      state.isLoading = true;
      state.error = false;
    },
    authUserSuccess: (state: State, { payload }: PayloadAction<AuthData>) => {
      state.loggedIn = true;
      state.isLoading = false;
      state.error = false;
      state.authData = { ...payload };
    },
    authUserFail: (state: State) => {
      state.isLoading = false;
      state.error = true;
      state.authData = null;
    },
    logoutUserStart: (state: State) => {
      state.isLoading = true;
      state.error = false;
    },
    logoutUserSuccess: () => initialState,
    logoutUserFail: () => initialState,
  },
});

export const {
  requestResetPasswordStart,
  requestResetPasswordSuccess,
  requestResetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFail,
  rememberMeAction,
  authUserStart,
  authUserSuccess,
  authUserFail,
  logoutUserStart,
  logoutUserSuccess,
  logoutUserFail,
} = authSlice.actions;

export default authSlice;
