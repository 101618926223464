export function filterEmptyQueryParams(obj: Object): Object {
  return Object.keys(obj).reduce((acc, curr) => {
    // Null, undefined and empty string are the only values considered empty in this case.
    // False and 0 are valid values.
    // @ts-ignore
    if (obj[curr] !== null && obj[curr] !== undefined && obj[curr] !== '') {
      return {
        ...acc,
        // @ts-ignore
        [curr]: obj[curr],
      };
    }

    return acc;
  }, {});
}
