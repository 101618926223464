import { connect } from 'react-redux';

import { setKioskAction } from 'store/app/app.slice';
import { getModalType } from 'store/modal/modal.selectors';
import { closeModalAction } from 'store/modal/modal.slice';

import Kiosk from './Kiosk';

const mapStateToProps = (state) => ({
  modalType: getModalType(state),
});

const mapDispatchToProps = {
  closeModalAction,
  setKioskAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Kiosk);
