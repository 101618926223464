import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import styled from 'styled-components';
// @ts-ignore
import breakpoint from 'styled-components-breakpoint';

import LogoFull from 'components/@icons/icons/LogoFull';

export const PaperStyled = styled(Paper)`
  && {
    box-sizing: border-box;
    padding: 32px 80px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    min-width: 471px;

    ${breakpoint('sm', 'md')`
      padding: 32px 62px;
    `};

    ${breakpoint('xs', 'sm')`
      padding: 32px 39px;
      min-width: 447px;
    `};
  }
`;

export const LogoFullStyled = styled(LogoFull)`
  height: 36px;
`;

export const GridContentCentered = styled(Grid)`
  text-align: center;
`;

export const GridContainerStyled = styled(Grid)`
  > div {
    padding: 16px;
  }

  ${breakpoint('xs', 'sm')`
    > div {
      padding: 12px;
    }
  `};

  margin: 0 auto;
  max-width: 620px;
`;
