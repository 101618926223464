const IcoPrint = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M17.6 8.8H6.4A2.4 2.4 0 0 0 4 11.2V16h3.2v3.2h9.6V16H20v-4.8a2.4 2.4 0 0 0-2.4-2.4zm-2.4 8.8H8.8v-4h6.4zm2.4-5.6a.8.8 0 1 1 .8-.8.8.8 0 0 1-.8.8zm-.8-7.2H7.2V8h9.6z"
      fill="currentColor"
    />
  </svg>
);

export default IcoPrint;
