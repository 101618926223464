import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import { PureComponent } from 'react';

import { formatSubRegionCountryLabel } from 'utils/product.utils';

import type { Product } from 'types/products.types';

import { ProductID } from 'components/@product';

import PDDescriptions from '../PDDescriptions';
import PDSpecsReadOnly from '../PDSpecs/PDSpecsReadOnly';
import {
  FlagStyled,
  GridContainer,
  Heading01Styled,
  Paragraph01Styled,
} from './PDRight.styled';

type Props = {
  product: Product,
};

export default class PDRight extends PureComponent<Props> {
  render() {
    const { product } = this.props;

    return (
      <GridContainer container direction="column">
        <Grid item>
          <Heading01Styled>{_get(product, 'name', '')}</Heading01Styled>
        </Grid>
        <Grid item>
          <GridContainer container direction="row">
            <Paragraph01Styled>
              <FlagStyled
                src={_get(product, 'wineCountry.media.reference', '')}
                h={20}
                w={20}
              />
              {formatSubRegionCountryLabel(product)}
            </Paragraph01Styled>
            <ProductID
              readOnly
              // id={_get(product, 'productId', '')}
              color={_get(product, 'wineType.colorHex', '')}
              desc={_get(product, 'wineType.value', '')}
              filled={_get(product, 'wineType.filled', '')}
            />
          </GridContainer>
        </Grid>
        <Grid item>
          <PDSpecsReadOnly product={product} />
        </Grid>
        <Grid item>
          <PDDescriptions readOnly product={product} />
        </Grid>
      </GridContainer>
    );
  }
}
