import { Component } from 'react';
import { connect } from 'react-redux';

import { wineAnalytics } from 'services/analytics/Tealium';
import { getLocale } from 'store/app/app.selectors';
import { getFilter } from 'store/filter/filter.selectors';
import { getAvailableFilters } from 'store/products/products.selectors';
import { getFilterValues } from 'store/step-filter/step-filter.selectors';
import { getUser } from 'store/user/user.selectors';

import ProductDetailsModalB2b from './b2b';
import { ProductDetailsModalB2cStyled } from './ProductDetailsModal.styled';

type Props = {
  isKiosk: boolean,
  open: boolean,
};

class ProductDetailsModal extends Component<Props> {
  componentDidUpdate(prevProps) {
    const { open, product } = this.props;

    // Only track when modal opens
    // We want this to be trakced in b2b an b2c
    if (prevProps.open === false && open === true) {
      const {
        user,
        locale,
        activeFilters,
        filterValues,
        availableFilters,
        isKiosk,
      } = this.props;

      const filters = isKiosk ? filterValues : activeFilters;

      wineAnalytics.trackEventWineDetails(product);
      wineAnalytics.trackEventNavigationWineDetail(
        !isKiosk ? user : undefined,
        locale,
        filters,
        availableFilters,
        isKiosk,
      );
    }
  }

  render() {
    const { isKiosk, ...rest } = this.props;

    if (isKiosk) {
      return <ProductDetailsModalB2cStyled {...rest} />;
    }

    return <ProductDetailsModalB2b {...rest} />;
  }
}

const mapStateToProps = (state) => ({
  locale: getLocale(state),
  user: getUser(state),
  availableFilters: getAvailableFilters(state),
  activeFilters: getFilter(state),
  filterValues: getFilterValues(state),
});

export default connect(mapStateToProps)(ProductDetailsModal);
