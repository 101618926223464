import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import modalTypes from 'enums/modal.enum';
import { Component, createRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { transformPrices } from 'utils/product.utils';

import { productsAnalytics } from 'services/analytics/Tealium';
import history from 'services/history';
import { getLocale } from 'store/app/app.selectors';
import type { Product } from 'types/products.types';

import Button01 from 'components/@common/Button/Button01';
import IconButton from 'components/@common/IconButton';
import OverlayHeader from 'components/@common/OverlayHeader';
import IcoClose from 'components/@icons/icons/IcoClose';
import IcoScan from 'components/@icons/icons/IcoScan';
import IcoSearch from 'components/@icons/icons/IcoSearch';
import ProductDetailsReadOnly from 'components/@product/ProductDetails/ProductDetailsReadOnly';

import { Footer } from './ProductDetailsModalB2c.styled';

type Props = {
  headerProps?: unknown,
  open: boolean,
  product: Product,
  productLoading: boolean,
  history: Object,
  onClose: (count?: number) => void,
  startScan: () => void,
  openModalAction: (config: Object) => void,
  resetStepFilter: () => void,
  withFooter?: boolean,
  classes?: Object,
  className?: string,
  BackdropProps?: Object,
  locale: string,
};

type State = {
  scrolling: boolean,
};

type ScrollReff = {
  current?: HTMLDivElement,
};

class ProductDetailsModal extends Component<Props, State> {
  state = {
    scrolling: false,
  };

  scrollRef: ScrollReff = createRef();

  static defaultProps = {
    withFooter: true,
  };

  componentDidUpdate(prevProps: Props) {
    const { current } = this.scrollRef;

    if (prevProps.open !== this.props.open && current) {
      if (this.props.open === true) {
        current.addEventListener('scroll', this.scrollEl);
      } else {
        current.removeEventListener('scroll', this.scrollEl);
        this.clearState();
      }
    }
  }

  clearState = () => {
    this.setState({
      scrolling: false,
    });
  };

  componentWillUnmount() {
    if (this.scrollRef.current) {
      this.scrollRef.current.removeEventListener('scroll', this.scrollEl);
      this.setState({
        scrolling: false,
      });
    }
  }

  scrollEl = (e: Object) => {
    if (!this.state.scrolling && e.target.scrollTop > 0) {
      this.setState({
        scrolling: true,
      });
    } else if (this.state.scrolling && e.target.scrollTop === 0) {
      this.setState({
        scrolling: false,
      });
    }
  };

  handleScanClick = () => {
    const { onClose, openModalAction, locale } = this.props;
    productsAnalytics.trackEventNavigationScanning('product detail', locale);

    onClose();
    openModalAction({
      type: modalTypes.SCANNING,
    });
  };

  handleSearchClick = () => {
    const { onClose, resetStepFilter } = this.props;

    history.push(`/kiosk/filter`);

    resetStepFilter();
    // Since the new modal stack, we need to close the detail modal and scan modal when handleSearchClick is executed.
    // Here we indicate to close 2 views.
    onClose(2);
  };

  render() {
    const {
      open,
      product,
      productLoading,
      onClose,
      headerProps,
      withFooter,
      classes,
      className,
      BackdropProps,
    } = this.props;

    return (
      <Dialog
        fullScreen
        disablePortal
        open={open}
        classes={classes}
        className={className}
        BackdropProps={BackdropProps}
      >
        <OverlayHeader
          transparent
          dock={this.state.scrolling}
          right={
            <IconButton
              icon={<IcoClose />}
              onClick={() => onClose()}
              labelLeft={<FormattedMessage id="b2c.common.close" />}
            />
          }
          position="relative"
          {...headerProps}
        />
        <div
          style={{ overflowY: 'auto', WebkitOverflowScrolling: 'touch' }}
          ref={this.scrollRef}
        >
          <ProductDetailsReadOnly
            product={product}
            productLoading={productLoading}
            prices={transformPrices(product)}
          />
        </div>

        {withFooter && (
          <Footer>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Button01
                  data-tms-navigation-detail="zoek een andere wijn"
                  intlId="b2c.product-details-modal.search"
                  color="secondary"
                  fullWidth
                  iconLeft={<IcoSearch />}
                  onClick={this.handleSearchClick}
                />
              </Grid>
              <Grid item xs={6}>
                <Button01
                  data-tms-navigation-detail="scan wijnfles"
                  intlId="b2c.product-details-modal.scan"
                  color="secondary"
                  fullWidth
                  iconLeft={<IcoScan />}
                  onClick={this.handleScanClick}
                />
              </Grid>
            </Grid>
          </Footer>
        )}
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: getLocale(state),
});

export default connect(mapStateToProps)(ProductDetailsModal);
