import { Component } from 'react';
import { connect } from 'react-redux';

import { getModalType } from 'store/modal/modal.selectors';
import { closeModalAction } from 'store/modal/modal.slice';
import {
  fetchBarcodeStart,
  initializeLibScannerFailed,
  initializeLibScannerSuccess,
  resetProducts,
  startScan,
  stopScan,
} from 'store/scan/scan.actions';
import {
  getBarcodeLoading,
  getScanError,
  getScanInit,
  getScanLibInit,
  getScanPaused,
} from 'store/scan/scan.selectors';

const mapStateToProps = (state) => ({
  paused: getScanPaused(state),
  modalType: getModalType(state),
  loading: getBarcodeLoading(state),
  error: getScanError(state),
  scannerInit: getScanInit(state),
  scannerLibInit: getScanLibInit(state),
});

const mapDispatchToProps = {
  fetchBarcodeStart,
  stopScan,
  closeModalAction,
  startScan,
  initializeLibScannerSuccess,
  initializeLibScannerFailed,
  resetProducts,
};

class Container extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    (async () => {
      this.Scanner = await (await import('./Scanner')).default;
      this.setState({ show: true });
    })();
  }
  render() {
    return this.state.show ? <this.Scanner {...this.props} /> : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
