import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactNode } from 'react';

import ButtonLabel from '../ButtonLabel';
import { Button03Styled } from './Button03.styled';

type Props = {
  children?: ReactNode,
  iconLeft?: ReactNode,
  iconRight?: ReactNode,
  ellipsis?: boolean,
};

const hasIcon = (icon: ReactNode) => icon && <SvgIcon>{icon}</SvgIcon>;

const Button03 = (props: Props) => {
  const { iconLeft, iconRight, ellipsis, ...rest } = props;
  return (
    <Button03Styled disableRipple {...rest}>
      {hasIcon(iconLeft)}
      <ButtonLabel ellipsis={ellipsis}>{props.children}</ButtonLabel>
      {hasIcon(iconRight)}
    </Button03Styled>
  );
};

export default Button03;
