import Radio from '@material-ui/core/Radio';
import { Component } from 'react';

import {
  IconWrapper,
  SingleSelectSmall,
  SingleSelectTitle,
  SingleSelectWrapper,
} from './SingleSelect.styled';

type Props = {
  icon: unknown,
  title?: string,
  small?: string,
  onChange: () => void,
};

class SingleSelect extends Component<Props> {
  render() {
    const { title, small, icon, onChange, checked, ...rest } = this.props;
    return (
      <SingleSelectWrapper onClick={onChange} selected={checked}>
        <IconWrapper>{icon}</IconWrapper>
        <SingleSelectTitle>{title}</SingleSelectTitle>
        <SingleSelectSmall>{small}</SingleSelectSmall>
        <Radio checked={checked} {...rest} />
      </SingleSelectWrapper>
    );
  }
}

export default SingleSelect;
