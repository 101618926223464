import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button04 } from 'components/@common/Button';

import {
  Chip01Styled,
  FilterContainer,
  FilterItem,
} from './FilterChips.styled';

type Props = {
  filters: unknown[],
  onResetFilters: () => void,
  onDeleteFilter: (payload) => void,
  onDeleteAll: () => void,
};

export default class FilterChips extends PureComponent<Props> {
  handleDelete = (key) => () => {
    this.props.onDeleteFilter(key);
  };

  renderFilterChips = (filters: FilterChip[]): any =>
    filters.map(({ label, key }: FilterChip, idx) => (
      <Chip01Styled
        key={idx.toString()}
        onDelete={this.handleDelete(key)}
        label={label}
      />
    ));

  render() {
    const { filters, onDeleteAll } = this.props;

    return (
      <FilterContainer>
        <FilterItem>{this.renderFilterChips(filters)}</FilterItem>
        <FilterItem>
          <Button04 onClick={onDeleteAll}>
            <FormattedMessage id="product.search.delete-filters" />
          </Button04>
        </FilterItem>
      </FilterContainer>
    );
  }
}
