import { connect } from 'react-redux';

import { getToast } from 'store/toast/toast.selectors';
import { toastCloseAction } from 'store/toast/toast.slice';

import ToastWrapper from './ToastWrapper';

const mapStateToProps = (state) => ({
  toast: getToast(state),
});

const mapDispatchToProps = {
  toastCloseAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(ToastWrapper);
