import { lazy } from 'react';

import { Routes } from 'pages/routes.constants';

const componentPromise = import(
  './KioskPreviewDetail' /* webpackChunkName: "kiosk-preview-detail", webpackPreload: true */
);
const Component = lazy(() => componentPromise);

export default {
  component: Component,
  exact: true,
  path: Routes.KioskPreviewDetail,
  authenticated: false,
};
