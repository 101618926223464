import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { getBulkUpdateDescription } from 'utils/index';

import { Button01 } from 'components/@common/Button';
import { SpinnerStyled } from 'components/@common/Spinner/Spinner.styled';

type Props = {
  onRemoveProducts: () => void,
  onCancel: () => void,
  products: Product[],
  type: string,
  isUpdatingProducts: boolean,
};

export default class BulkRemoveProducts extends PureComponent<Props> {
  render() {
    const { onRemoveProducts, onCancel, products, type, isUpdatingProducts } =
      this.props;

    return (
      <>
        <Grid container direction="row">
          <Grid item xs={12}>
            {getBulkUpdateDescription(products, type)}
          </Grid>
        </Grid>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            <Button01 onClick={onCancel} color="secondary">
              <FormattedMessage id="common.button.cancel" />
            </Button01>
            {isUpdatingProducts ? (
              <SpinnerStyled />
            ) : (
              <Button01 onClick={onRemoveProducts} color="primary">
                <FormattedMessage id="common.button.delete" />
              </Button01>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
}
