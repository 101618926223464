import type { Node } from 'react';
import { PureComponent } from 'react';

import { FilterButton01Styled } from './FilterButton01.styled';

type Props = {
  children: Node,
  active?: boolean,
};

export default class FilterButton01 extends PureComponent<Props> {
  render() {
    const { active, children, ...rest } = this.props;
    return (
      <FilterButton01Styled active={active} disableRipple {...rest}>
        {children}
      </FilterButton01Styled>
    );
  }
}
