import { filterTypes } from 'enums';
import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getProducts = (state: StoreState) => _get(state, 'products.data');
export const getProductsTotal = (state: StoreState) =>
  _get(state, 'products.meta.total');
export const getTotalPrintList = (state: StoreState) =>
  _get(state, 'products.meta.totalPrintList');
export const getTotalPrintDetail = (state: StoreState) =>
  _get(state, 'products.meta.totalPrintDetail');
export const getProductsTotalPages = (state: StoreState) =>
  _get(state, 'products.meta.pages');
export const getProductsPaging = (state: StoreState) =>
  _get(state, 'products.paging');
export const getProductsLoading = (state: StoreState) =>
  _get(state, 'products.loading');
export const getAvailableFilters = (state: StoreState) =>
  _get(state, 'products.availableFilters');
export const getFilterLoading = (state: StoreState) =>
  _get(state, 'products.loadingFilter');

export const getFilterWineTypes = (state: StoreState) => {
  const filters = _get(state, 'products.availableFilters', []);

  const wineTypes = filters.find(
    // @ts-ignore
    (filter) => filter.name === filterTypes.WINE_TYPE,
  );
  return _get(wineTypes, 'listing.listingValues', []);
};
