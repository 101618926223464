// LOGIN API
import { authTypes } from 'enums';
import {
  call,
  delay,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects';

// UTILS
import { getExpirationTime } from 'utils/auth.utils';

import {
  deleteRefresh,
  login,
  refresh,
  requestResetPassword,
  setResetPassword,
} from 'services/api/auth';
import history from 'services/history';
import { setAppLoadingAction } from 'store/app/app.slice';
// LOGIN ACTIONS
import { getAuthData } from 'store/auth/auth.selectors';
import {
  authUserFail,
  authUserStart,
  authUserSuccess,
  logoutUserStart,
  logoutUserSuccess,
  rememberMeAction,
  requestResetPasswordFail,
  requestResetPasswordStart,
  requestResetPasswordSuccess,
  resetPasswordFail,
  resetPasswordStart,
  resetPasswordSuccess,
} from 'store/auth/auth.slice';
// FILTER ACTIONS
import { resetFilterAction } from 'store/filter/filter.slice';
// PRODUCTS ACTIONS
import { resetProducts } from 'store/products/products.slice';
// TOAST ACTIONS
import { toastErrorAction, toastSuccessAction } from 'store/toast/toast.slice';
// USER SELECTORS
import { getUserId } from 'store/user/user.selectors';
// USER ACTIONS
import { fetchUserStart, resetUserAction } from 'store/user/user.slice';

// Logout subroutine
function* logout(redirect: string) {
  // Delete the auth token
  yield call(deleteRefresh);
  // Notify the store
  yield put(logoutUserSuccess());
  yield put(resetUserAction());
  // empty products
  yield put(resetProducts());
  // empty filter
  yield put(resetFilterAction());

  if (history) {
    if (redirect) {
      // to logout to Kiosk we should use the redirect param to redirect to '/kiosk'
      yield call(history.push, redirect);
    } else {
      // otherwise push to /login
      yield call(history.push, '/login');
    }
  }

  yield put(setAppLoadingAction(false));
}

// Authorization subroutine
// @ts-ignore
function* authorize(credentials) {
  // TODO: active shop and other stuff in the user reducer don't allow us to have a userSelector,
  //  we will use the userId for now
  // @ts-ignore
  const userId = yield select(getUserId);
  let auth = null;

  // If we have credentials we know it's a login,
  // so we call the login api service.
  if (credentials) {
    try {
      // @ts-ignore
      auth = yield call(login, credentials);
    } catch (err) {
      yield put(authUserFail());

      return null;
    }
  } else {
    // Else we know it is a refresh call.
    // We use a race condition here because
    // if the user triggers signout during a refresh
    // we need to cancel the authorization operation.
    try {
      const { authData } = yield race({
        // @ts-ignore
        authData: yield call(refresh), // done through cookies, might not work locally so you will be logged out
        logoutAction: take(logoutUserStart),
      });

      auth = authData;
    } catch (err) {
      yield put(authUserFail());
    }
  }

  // Auth is successful, so return the token
  // and notify the store
  if (auth && auth.accessToken) {
    yield put(authUserSuccess(auth));

    // If we don't have the user in our store
    // We need to fetch it.
    if (!userId) {
      yield put(fetchUserStart(auth));
    }

    return auth;
  }

  // If not successful, we should log our user out (Refresh token is probably not valid anymore)
  // @ts-ignore
  yield call(logout);
  // If anything goes wrong or the user logs out we return null
  // we then use this in the authFlow to determine if the authorization
  // was successful or not
  return null;
}

/**
 * Authorization flow that gets used for authorizing our user.
 * Used on login and for refresh calls
 */
// @ts-ignore
function* authFlow({ payload }) {
  // Get the authData (token, expire time, ...) from the redux store
  // @ts-ignore
  let authData = yield select(getAuthData);

  if (authData) {
    // If we have an authData object, set expiresIn to 0, so we instantly fetch new accessToken.
    authData = { ...authData, expiresIn: 0 };
  } else {
    // Take the login action and try to authorize the user
    const credentials = {
      username: (payload || {}).username,
      password: (payload || {}).password,
    };
    // @ts-ignore
    authData = yield call(authorize, credentials);
  }

  let isUserLoggedOut = false;
  // With this while loop we wait for
  // 1) the expiration of the token
  // 2) the logout action of the user
  while (!isUserLoggedOut && authData) {
    const { tokenExpired, logoutAction } = yield race({
      tokenExpired: delay(getExpirationTime(authData.expiresIn)),
      logoutAction: take(logoutUserStart),
    });

    // If the race condition is triggered with
    // expired token, do a token refresh
    // Else the user has called the logout action
    if (tokenExpired) {
      // Refresh the user token
      // @ts-ignore
      authData = yield call(authorize);
      // If the refresh failed we log the user out
      if (!authData) {
        isUserLoggedOut = true; // breaks loop
        // @ts-ignore
        yield call(logout);
      }
    } else {
      // the user has triggered a logout action before token expiration
      isUserLoggedOut = true; // breaks loop
      authData = null; // this way we can login again, otherwise the check of !authData is true

      yield call(logout, logoutAction.payload);
    }
  }
}
// @ts-ignore
function rememberMe(action) {
  const { rememberMe: remember, username } = action.payload;
  if (remember) {
    localStorage.setItem(authTypes.REMEMBERED_USER, username);
  } else {
    localStorage.removeItem(authTypes.REMEMBERED_USER);
  }
}

// @ts-ignore
function* requestPasswordResetFlow({ payload }) {
  const { email, resolve } = payload;
  try {
    yield call(requestResetPassword, email);
    yield put(requestResetPasswordSuccess());
    yield put(
      toastSuccessAction({
        message: 'toast.request-password-reset-success',
      }),
    );
    resolve(); // keeps submitting the form
    yield call(history.push, '/login');
  } catch (err) {
    // @ts-ignore
    yield put(requestResetPasswordFail(err));
    resolve();
  }
}
// @ts-ignore
function* resetPasswordFlow({ payload }) {
  try {
    const { token, password, resolve } = payload;
    yield call(setResetPassword, token, password);
    yield put(
      toastSuccessAction({
        message: 'toast.password-reset-success',
      }),
    );
    resolve();
    // @ts-ignore
    yield call(logout);
    yield call(history.push, '/login');
    yield put(resetPasswordSuccess());
  } catch (err) {
    const { reject } = payload;
    reject();
    yield put(
      toastErrorAction({
        message: 'toast.password-reset-error',
      }),
    );
    // @ts-ignore
    yield put(resetPasswordFail(err));
  }
}

function* saga() {
  yield takeLatest(rememberMeAction, rememberMe);
  yield takeLatest(requestResetPasswordStart, requestPasswordResetFlow);
  yield takeLatest(resetPasswordStart, resetPasswordFlow);
  yield takeLatest(authUserStart, authFlow);
}

export default saga;
