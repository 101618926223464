import _get from 'lodash/get';

import { Circle } from 'components/@common';
import Icon from 'components/@icons/Icon';
import IcoArrowRight from 'components/@icons/icons/IcoArrowRight';

import {
  Content,
  Flag,
  ImageContainer,
  InfoTagWrapper,
  Name,
  Paragraph01Styled,
  Wrapper,
} from './ProductCard.styled';

const ProductCard = ({ product, handleClick }) => (
  <Wrapper onClick={() => handleClick(product)}>
    <ImageContainer>
      <img
        style={{ objectFit: 'contain' }}
        width={72}
        height={72}
        alt={product.name}
        src={_get(product, 'media.small', '')}
      />
    </ImageContainer>
    <Content>
      <InfoTagWrapper maxWidth="100%">
        <Paragraph01Styled>{_get(product, 'productId', '')}</Paragraph01Styled>
        <div style={{ margin: '6px 0' }}>
          <Circle
            filled
            fontSize="small"
            color={_get(product, 'wineType.colorHex', '')}
          />
        </div>
        <Paragraph01Styled>
          {_get(product, 'wineType.value', '')}
        </Paragraph01Styled>
      </InfoTagWrapper>
      <Name>{product.name}</Name>

      <InfoTagWrapper maxWidth={220}>
        <Flag src={_get(product, 'wineCountry.media.reference', '')} />
        <Paragraph01Styled>
          {_get(product, 'wineCountry.value', '')}
        </Paragraph01Styled>
      </InfoTagWrapper>
    </Content>
    <Icon>
      <IcoArrowRight color="#ed3943" size="small" />
    </Icon>
  </Wrapper>
);

ProductCard.propTypes = {
  product: Object,
  handleClick: () => {},
};

export default ProductCard;
