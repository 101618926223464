import { rgba } from 'polished';
import styled from 'styled-components';

import { Heading04, Heading05 } from 'theme/Heading';
import { Paragraph02 } from 'theme/Paragraph';

export const LocationWrapper = styled.div`
  border-radius: 4px;
  background-color: ${({ theme }) => rgba(theme.palette.global02, 0.15)};
  ${({ small }) =>
    small
      ? `
    padding 0 6px;
    height: 20px;
    line-height: 20px;
  `
      : `
    padding 0 8px;
    height: 24px;
    line-height: 24px;
  `};
`;

export const LocationLabel = styled(Heading04)`
  ${({ theme }) => theme.textStyles.heading04global02boldcenter};
`;

export const LocationLabelSmall = styled(Paragraph02)`
  ${({ theme }) => theme.textStyles.paragraph02global02boldcenter};
  line-height: unset;
`;

export const Title = styled(Heading05)`
  text-align: center;
`;
