import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import { PureComponent, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { sortByValue } from 'utils/product.utils';

import FoodPairing from 'components/@product/FoodPairing';
import Specification from 'components/@product/Specification';

import { GridStyled } from './PDSpecs.styled';

type Props = {
  product: unknown,
};

export default class PDSpecsReadOnly extends PureComponent<Props> {
  renderFoodPairings = (wineFoodPairings: Array<Object>): ReactNode =>
    wineFoodPairings
      .slice()
      .filter((food) => food.value)
      .sort(sortByValue)
      .map((food, idx) => (
        <FoodPairing
          title={
            idx === 0 ? (
              <FormattedMessage id="product.details.spec.dishes" />
            ) : null
          }
          icon={_get(food, 'media.reference', '')}
          key={food.value}
          label={food.value}
        />
      ));

  formatGrapePercentage = (percentage) => {
    if (!percentage || parseInt(percentage, 10) === 0) {
      return '';
    }

    return percentage;
  };

  renderGrapes = (wineGrapes) =>
    wineGrapes
      .filter((grape) => grape.grape.value)
      .map((grape, idx) => (
        <Specification
          readOnly
          title={
            idx === 0 ? (
              <FormattedMessage id="product.details.spec.grapes" />
            ) : null
          }
          key={parseInt(idx.toString(), 10)}
          percentage={this.formatGrapePercentage(grape.percentage)}
          label={grape.grape.value}
        />
      ));

  renderSpec = (intlId: string, label: string) =>
    label && (
      <GridStyled item xs={6}>
        <Specification
          readOnly
          title={<FormattedMessage id={intlId ?? ''} />}
          label={label}
        />
      </GridStyled>
    );

  renderTastes = (tastes) => {
    if (tastes.length > 0) {
      return tastes.map((t) => t.value).join(', ');
    }

    return '-';
  };

  render() {
    const { product } = this.props;

    const grapes = this.renderGrapes(_get(product, 'wineGrapes', []));
    const food = this.renderFoodPairings(_get(product, 'wineFoodPairings', []));
    const tastes = this.renderTastes(_get(product, 'wineTastes', []));

    return (
      <Grid container direction="row">
        {food.length > 0 && (
          <GridStyled item xs={12}>
            {food}
          </GridStyled>
        )}
        {food.length === 0 &&
          this.renderSpec('product.details.spec.dishes', '-')}

        {grapes.length > 0 && (
          <GridStyled item xs={6}>
            {grapes}
          </GridStyled>
        )}
        {grapes.length === 0 &&
          this.renderSpec('product.details.spec.grapes', '-')}

        {this.renderSpec('product.details.spec.taste', tastes)}
      </Grid>
    );
  }
}
