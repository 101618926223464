// comment out locales that should not be supported
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/nl';

import fr from 'date-fns/locale/fr';
import nlBE from 'date-fns/locale/nl'; // nl-BE doesn't exist

import config from 'config';

import MESSAGES_FR from './translations/fr-BE.json';
import MESSAGES_NL from './translations/nl-BE.json';

export type Locale = { date: { code: string }; value: string };

const messages = {
  nl: MESSAGES_NL,
  fr: MESSAGES_FR,
};

function getDefaultLocaleMessages() {
  // @ts-ignore
  return messages[DEFAULT_LOCALE];
}

const { DEFAULT_LOCALE, DEFAULT_LOCALE_DATE } = config;

const locales = {
  nl: { value: 'nl-BE', date: { code: nlBE.code } },
  fr: { value: 'fr', date: { code: fr.code } },
};

const formats = {};

export {
  DEFAULT_LOCALE,
  DEFAULT_LOCALE_DATE,
  formats,
  getDefaultLocaleMessages,
  locales,
  messages,
};
