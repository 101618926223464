import styled from 'styled-components';

export const StyledLabel = styled.span`
  ${({ bold, theme }) =>
    bold
      ? `${theme.textStyles.paragraph01neutral02bold};`
      : `${theme.textStyles.paragraph01neutral02};`};
`;

export default { StyledLabel };
