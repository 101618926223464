import { call, put, select, takeLatest } from 'redux-saga/effects';

import { setSentryShopContext } from 'utils/sentry.utils';

import { getUser } from 'services/api/user/user.api';
import history from 'services/history';
import { getLocale } from 'store/app/app.selectors';
import { changeLanguageAction, setAppLoadingAction } from 'store/app/app.slice';
import { toastErrorAction } from 'store/toast/toast.slice';
import {
  fetchUserFail,
  fetchUserStart,
  fetchUserSuccess,
  setActiveUserShopAction,
} from 'store/user/user.slice';

// @ts-ignore
function* fetchUserFlow(action) {
  const { payload } = action;

  try {
    // @ts-ignore
    const user = yield call(getUser, payload.userId);
    // @ts-ignore
    const locale = yield select(getLocale);

    // Change language, when user's language is not the same as current language
    if (user.locale !== locale) {
      yield put(changeLanguageAction());
    }

    yield put(fetchUserSuccess(user));

    // If we have 1 shop, we redirect to the catalog
    // and set that shop active
    if (user.shops && user.shops.length === 1) {
      yield put(setActiveUserShopAction(user.shops[0]));

      yield call(setSentryShopContext, user.shops[0]);

      yield call(history.push, '/catalog');
    } else {
      yield call(history.push, '/branch');
    }

    yield put(setAppLoadingAction(false));
  } catch (e) {
    yield put(fetchUserFail());
    yield put(
      toastErrorAction({
        message: 'toast.error.fetch-user',
        duration: 10000,
      }),
    );
  }
}

function* saga() {
  yield takeLatest(fetchUserStart, fetchUserFlow);
}

export default saga;
