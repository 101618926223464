import { injectIntl } from 'react-intl';

import { PriceHeadingStyled, PriceStyled, PriceWrapper } from './Price.styled';

type Props = {
  title: string,
  content: string,
  vertical: boolean,
  intl: Object,
};

const Price = ({ vertical, title, content, intl }: Props) => (
  <PriceWrapper vertical={vertical}>
    {title && (
      <PriceHeadingStyled>
        {intl.formatMessage({
          id: title,
        })}
      </PriceHeadingStyled>
    )}
    <PriceStyled>{content}</PriceStyled>
  </PriceWrapper>
);

export default injectIntl(Price);
