import { TEALIUM_NAVIGATION_DEFAULTS } from 'constants/tealium.constant';
import GeneralAnalytics from 'services/analytics/GeneralAnalytics';
import { Theme } from 'types/theme.types';
import { User } from 'types/user.types';

export default class CoreAnalytics extends GeneralAnalytics {
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(props: (data: Object) => void) {
    super(props);
  }

  trackBranch = (user: User, locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2b',
      page_status: 200,
      page_template: 'other',
      page_name: 'branch',
      user_id: user.id,
      user_logged: user.id ? 'yes' : 'no',
      page_language: locale,
    });

  trackLogin = (user: User, locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2b',
      user_id: user && user.id,
      user_logged: user && !!user.id ? 'yes' : 'no',
      page_language: locale,
      page_name: 'login',
      page_status: 200,
      page_template: 'login',
    });

  trackHome = (locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: 'kiosk',
      page_status: 200,
      page_template: 'Ik zoek een wijn volgens',
    });

  trackFilterPage = (locale: string, location: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: location,
      page_status: 200,
      page_template: 'other',
    });

  trackAgeValidation = (element: HTMLElement) =>
    this.track({
      page_name_virtual: 'age confirmation',
      event_name: 'page navigation virtual',
      element,
    });

  trackThemed = (theme: Theme, locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: 'featured',
      page_status: 200,
      page_template: 'other',
      'data-tms-intpromo-name': theme && theme.name,
    });

  trackResetPassword = () =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2b',
      page_name: 'reset password',
      page_status: 200,
      page_template: 'other',
    });

  trackOnBoarding = (isUserLoggedIn: number, locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2b',
      page_language: locale,
      page_name: 'onBoarding',
      page_status: 200,
      page_template: 'other',
      user_logged: isUserLoggedIn ? 'yes' : 'no',
    });

  trackLanguageSwitch = (analyticsLocale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2b',
      page_language: analyticsLocale,
      page_name: 'login',
      page_status: 200,
      page_template: 'other',
      'data-tms-navigation-section': 'language switch',
    });

  trackEventNavigationSwitchingStores = (
    shopId: number,
    locale: string,
    userId: string,
  ) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      'data-tms-navigation-section': 'header',
      user_id: userId,
      user_logged: 'yes',
      env_shop: 'b2b',
      env_organisation: shopId,
      page_language: locale,
      page_name: 'catalog',
      page_status: 200,
      page_template: 'other',
      'data-tms-navigation-detail': 'store_switch',
    });

  trackEventNavigationLanguageSwitcher = (
    locale: string,
    page: string,
    shopId?: string,
    userId?: string,
  ) => {
    let info = {
      env_shop: 'b2c',
    };
    if (userId) {
      info = {
        env_shop: 'b2b',
        // @ts-ignore
        user_id: userId,
        user_logged: 'yes',
        env_organisation: shopId,
      };
    }

    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      ...info,
      page_language: locale,
      page_name: page,
      page_status: 200,
      page_template: 'other',
      'data-tms-navigation-detail': 'language switch',
    });
  };

  trackEventNavigationLogout = (userId: number) => {
    this.track({
      event_name: 'user logout',
      user_id: userId,
      user_logged: 'yes',
    });
  };

  trackEventNavigationLogin = () => {
    this.track({
      event_name: 'user login',
      user_logged: 'no',
    });
  };

  trackExportInteraction = (
    exportType: 'single' | 'list: separate view' | 'list: list view',
  ) =>
    this.track({
      event_name: 'interaction',
      interaction_name: 'click: export product fiches',
      interaction_details1: exportType,
    });

  trackEventOnboardingIntromodalInteraction = () => {
    this.track({
      event_name: 'interaction',
      interaction_name: 'onboarding',
    });
  };
}
