import { PriceBig, PriceSmall, Valuta, Wrapper } from './Price.styled';

type Props = {
  value: number,
  small?: boolean,
  spacing?: string,
};

const getBigPrice = (price: string) => {
  const splitted = price.split('.');

  return splitted && splitted.length > 0 ? splitted[0] : '';
};

const getSmallPrice = (price: string) => {
  const splitted = price.split('.');
  const hasFloat = splitted && splitted.length === 2;

  if (hasFloat) {
    // We don't want the number to be 4.2 but 4.20
    splitted[1] = splitted[1].length === 1 ? `${splitted[1]}0` : splitted[1];
  }

  return hasFloat ? splitted[1] : '';
};

const Price = ({ value, small }: Props) => (
  <Wrapper>
    <Valuta small={small}>&euro;</Valuta>
    <PriceBig small={small}>{getBigPrice(value.toString())}</PriceBig>
    <PriceSmall small={small}>{getSmallPrice(value.toString())}</PriceSmall>
  </Wrapper>
);

export default Price;
