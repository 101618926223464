import type { Node } from 'react';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  Button04Styled,
  ButtonLabelStyled,
  IconStyled,
} from 'components/@common/Button/Button04/Button04.styled';

type Props = {
  children?: Node,
  iconLeft?: Node,
  iconRight?: Node,
  ellipsis?: boolean,
  intlId?: string,
};

export default class Button04 extends Component<Props> {
  hasIcon = (icon: Node) =>
    icon && <IconStyled fontSize="small">{icon}</IconStyled>;

  render() {
    const { iconLeft, iconRight, ellipsis, intlId, children, ...rest } =
      this.props;
    return (
      <Button04Styled disableRipple {...rest}>
        {this.hasIcon(iconLeft)}
        <ButtonLabelStyled ellipsis={ellipsis}>
          {intlId && <FormattedMessage id={intlId} />}
          {children}
        </ButtonLabelStyled>
        {this.hasIcon(iconRight)}
      </Button04Styled>
    );
  }
}
