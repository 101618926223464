const IcoClose = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M18 7.21L16.79 6 12 10.79 7.21 6 6 7.21 10.79 12 6 16.79 7.21 18 12 13.21 16.79 18 18 16.79 13.21 12z"
      fill="currentColor"
    />
  </svg>
);

export default IcoClose;
