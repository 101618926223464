// TODO: Check if polyfills are necessary?
import 'utils/polyfill.utils';

import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';

import config from 'config';

import { runInProd } from 'utils/env.utils';

import App from './App';

runInProd(() => Sentry.init(config.SENTRY));

ReactDOM.render(<App />, document.getElementById('root'));
