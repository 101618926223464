import styled from 'styled-components';

export const SingleSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.palette.neutral01};
  border-radius: 8px;
  border: solid 2px ${(props) => props.theme.palette.neutral05};

  &:hover {
    border-color: ${(props) => props.theme.palette.neutral04};
    cursor: pointer;
  }

  border-sizing: border-box;
  padding: 15px;

  ${(props) =>
    props.selected &&
    `
    &:hover {
    border-color: ${props.theme.palette.global01};
    cursor: pointer;
  }
    border-color: ${props.theme.palette.global01};
  `};
`;

export const SingleSelectTitle = styled.span`
  width: 100%;
  height: 24px;
  ${({ theme }) => theme.textStyles.heading04neutral02boldcenter};
`;

export const SingleSelectSmall = styled.span`
  width: 100%;
  height: 20px;
  ${({ theme }) => theme.textStyles.paragraph01neutral04center};
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 152px;
  height: 152px;
  margin: 0 55px;
`;

export const ContentWrapper = styled.div``;
