import { filterEmptyQueryParams } from 'utils/api.utils';

import axiosManager from 'services/api/axios';
import { ProductQueryParams } from 'types/products.types';

import { mapRequestProduct } from './products.request.mapper';

export const getProducts = (params: ProductQueryParams) => {
  const data = mapRequestProduct(params);
  const paramsWithoutEmptyValues = filterEmptyQueryParams(data);

  return axiosManager
    .get(`/products`, {
      withCredentials: true,
      params: paramsWithoutEmptyValues,
    })
    .then(
      (res) => res,
      (e) => Promise.reject(e),
    );
};

export const getProduct = (productId: string, params: ProductQueryParams) => {
  const paramsWithoutEmptyValues = filterEmptyQueryParams(params);
  return axiosManager
    .get(`/products/${productId}`, {
      withCredentials: true,
      params: paramsWithoutEmptyValues,
    })
    .then(
      (res) => res,
      (e) => Promise.reject(e),
    );
};

export const getProductFilters = (params: Object) =>
  axiosManager
    .get(`/product_filters`, {
      withCredentials: true,
      params,
    })
    .then(
      (res) => res.data,
      (e) => Promise.reject(e),
    );

export const getTask = (url: string) =>
  axiosManager
    .get(url, {
      withCredentials: true,
      baseURL: '', // needed to replace the whole url when polling for printing
    })
    .then(
      (res) => res,
      (e) => Promise.reject(e),
    );
