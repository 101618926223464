import MUIIconButton from '@material-ui/core/IconButton';
import { brandingTypes } from 'enums';
import styled, { css } from 'styled-components';

const getB2CTheme = (props) => {
  const { isDark, theme } = props;
  const group = 'primary';
  const { buttonicon01 } = theme.flavor;
  const variant = isDark ? 'dark' : 'light';
  if (theme.name === brandingTypes.B2C) {
    return css`
      &:active {
        border-color: ${buttonicon01[group][variant].active.borderColor};
      }
      border-color: ${buttonicon01[group][variant].borderColor};
      border-style: solid;
      border-width: 2px;
    `;
  }
  return css``;
};

const getThemedStyled = (props) => {
  const { isDark, theme } = props;
  const { buttonicon01 } = theme.flavor;
  const group = 'primary';
  const variant = isDark ? 'dark' : 'light';

  return css`
    background-color: ${buttonicon01[group][variant].backgroundColor};
    color: ${buttonicon01[group][variant].color};
    padding: ${buttonicon01[group].base.padding};

    &:hover {
      background-color: ${buttonicon01[group][variant].hover.backgroundColor};
    }

    &:active {
      background-color: ${buttonicon01[group][variant].active.backgroundColor};
    }

    &:disabled {
      background-color: ${buttonicon01[group][variant].disabled
        .backgroundColor};
      cursor: no-drop;
    }

    ${getB2CTheme(props)};
  `;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ButtonIconStyled = styled(({ isDark, color, ...rest }) => (
  <MUIIconButton {...rest} />
))`
  && {
    ${(props) => getThemedStyled(props)};
  }
`;

export default { ButtonIconStyled };
