import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Component } from 'react';
import { FormattedMessage } from 'react-intl';

import Button02 from 'components/@common/Button/Button02';
import WineRackButton from 'components/@product/WineRack/WineRackButton/WineRackButton';
import WineRackContainer from 'components/@product/WineRack/WineRackContainer';
import WineRackTitle from 'components/@product/WineRack/WineRackTitle/WineRackTitle';

import {
  Heading05Styled,
  WineRackGridContainer,
  WineRackGridItem,
} from './WineRack.styled';

type Props = {
  location: string,
  inProductRange: boolean,
  onRemove?: () => void,
  onAdd: () => void,
  onMove: () => void,
};

export default class WineRack extends Component<Props> {
  render() {
    const { location, inProductRange, onRemove, onAdd, onMove } = this.props;
    return (
      <>
        <Heading05Styled>
          <FormattedMessage id="winerack.title" />
        </Heading05Styled>
        <WineRackGridContainer container>
          <WineRackGridItem item xs={12}>
            <WineRackContainer
              container
              spacing={1}
              inProductRange={inProductRange}
            >
              <Grid item>
                <WineRackTitle
                  location={location}
                  inProductRange={inProductRange}
                />
              </Grid>
              <Grid item>
                <WineRackButton
                  location={location}
                  inProductRange={inProductRange}
                  onAdd={onAdd}
                  onMove={onMove}
                />
              </Grid>
            </WineRackContainer>
          </WineRackGridItem>
          {inProductRange && (
            <Grid item xs={12}>
              <Box mt={1}>
                <Button02 onClick={onRemove} intlId="winerack.button.delete" />
              </Box>
            </Grid>
          )}
        </WineRackGridContainer>
      </>
    );
  }
}
