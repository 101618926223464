/**
 * DON'T REFACTOR THIS FILE TO A SLICE - more info in the ./README.md
 */

import { createAction } from 'redux-act';

export const fetchBarcodeStart = createAction('BARCODE_FETCH_START');
export const fetchBarcodeSuccess = createAction('BARCODE_FETCH_SUCCESS');
export const fetchBarcodeFail = createAction('BARCODE_FETCH_FAIL');

export const startScan = createAction('SCAN_START');
export const stopScan = createAction('SCAN_STOP');

export const resetProducts = createAction('RESET_PRODUCTS');
export const removeProduct = createAction('REMOVE_PRODUCT');

export const initializeLibScanner = createAction(
  'SCANNER_LIB_INITIALIZE_START',
);
export const initializeLibScannerSuccess = createAction(
  'SCANNER_LIB_INITIALIZE_SUCCESS',
);
export const initializeLibScannerFailed = createAction(
  'SCANNER_LIB_INITIALIZE_FAIL',
);

export const initializeScanner = createAction('SCANNER_INITIALIZE_START');
export const initializeScannerSuccess = createAction(
  'SCANNER_INITIALIZE_SUCCESS',
);
export const initializeScannerFail = createAction('SCANNER_INITIALIZE_FAIL');

export const setScannerAction = createAction(
  'SCANNER_SET',
  (payload) => payload,
);
