import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { ButtonIcon01 } from 'components/@common/Button';
import Button01 from 'components/@common/Button/Button01';

export const Button01Styled = styled(Button01)`
  && {
    ${breakpoint('xs', 'sm')`
      display: none;
  `};
  }
`;

export const ButtonIconStyled = styled(ButtonIcon01)`
  && {
    ${breakpoint('sm')`
      display: none;
  `};
  }
`;
