import { Component } from 'react';
import { connect } from 'react-redux';

import {
  initializeScannerSuccess,
  setScannerAction,
} from 'store/scan/scan.actions';
import { getScanInit, getScanLibInit } from 'store/scan/scan.selectors';

const mapStateToProps = (state) => ({
  scannerInit: getScanInit(state),
  libInit: getScanLibInit(state),
});

const mapDispatchToProps = {
  initializeScannerSuccess,
  setScannerAction,
};
type State = {
  show: boolean,
};

class Container extends Component<
  typeof mapDispatchToProps & ReturnType<typeof mapStateToProps>,
  State,
> {
  state = {
    show: false,
  };

  componentDidMount() {
    (async () => {
      this.Scandit = await (await import('./Scandit')).default;
      this.setState({ show: true });
    })();
  }

  render() {
    const { libInit, ...rest } = this.props;
    return this.state.show && libInit ? <this.Scandit {...rest} /> : null;
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Container);
