import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getToastOpen = (state: StoreState) => _get(state, 'toast.open');
export const getToastVariant = (state: StoreState) =>
  _get(state, 'toast.variant');
export const getToastDuration = (state: StoreState) =>
  _get(state, 'toast.duration');
export const getToastMessage = (state: StoreState) =>
  _get(state, 'toast.message');
export const getToastIntlId = (state: StoreState) =>
  _get(state, 'toast.intlId');
export const getToast = (state: StoreState) => _get(state, 'toast');
