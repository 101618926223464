import modalTypes from 'enums/modal.enum';
import { Component } from 'react';

import { wineAnalytics } from 'services/analytics/Tealium';
import type { Product } from 'types/products.types';

import ProductsModal from 'components/@modals/ProductsModal';

type Props = {
  modalType: string,
  modalData: Object,
  productLoading: boolean,
  closeModalAction: (count?: number) => void,
  updateProductStart: (payload: Product) => void,
  deleteProductStart: (payload: Product) => void,
  openModalAction: (payload: Object) => void,
  locale: string,
  user: Object,
};

class ScannedProductsOverview extends Component<Props> {
  handleClose = () => {
    const { openModalAction, closeModalAction } = this.props;

    closeModalAction();
    openModalAction({
      type: modalTypes.SCANNING,
    });
  };

  handleUpdateProduct = (payload: Product) => {
    this.props.updateProductStart(payload);
  };

  handleRemoveProduct = (payload: Product, analyticsProduct: Product) => {
    wineAnalytics.trackEventWineDelete(analyticsProduct);
    this.props.deleteProductStart(payload);
  };

  render() {
    const {
      modalType,
      modalData = {},
      productLoading,
      openModalAction,
    } = this.props;
    const open = modalType === modalTypes.PRODUCTS;

    return (
      <ProductsModal
        openModalAction={openModalAction}
        open={open}
        product={modalData}
        productLoading={productLoading}
        onClose={this.handleClose}
        onUpdateProduct={this.handleUpdateProduct}
        onRemoveProduct={(payload) =>
          this.handleRemoveProduct(payload, modalData)
        }
      />
    );
  }
}

export default ScannedProductsOverview;
