import {
  AppLoading,
  Intro,
  Kiosk,
  NetworkToast,
  Print,
  PrintDetail,
  ProductDetail,
  ScannedProductsOverview,
  Scanner,
  Toast,
} from 'components/@listeners';

// All components that listen to redux state and open/render when there is user interaction
const Listeners = () => {
  return (
    <>
      <Toast />
      <NetworkToast />
      <AppLoading />
      <Scanner />
      <Kiosk />
      <Intro />
      <ProductDetail />
      <PrintDetail />
      <Print />
      <ScannedProductsOverview />
    </>
  );
};

export default Listeners;
