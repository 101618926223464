import { rgba } from 'polished';
import styled from 'styled-components';

import ButtonBase from '../ButtonBase';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FilterButton01Styled = styled(({ active, ...rest }) => (
  <ButtonBase {...rest} />
))`
  && {
    padding: 16px 10px;
    border-radius: 16px;
    background-color: transparent;
    border: 2px solid ${({ theme }) => theme.palette.global02};
    color: ${({ theme }) => theme.palette.global02};
    font-size: 20px;
    font-weight: bold;

    &:hover {
      color: ${({ theme }) => theme.palette.global02};
      background-color: transparent;
    }

    &:active {
      color: ${({ theme }) => theme.palette.global02};
      background-color: ${({ theme }) => rgba(theme.palette.global02, 0.25)};
    }

    ${({ active, theme }) =>
      active &&
      `
        color: ${theme.palette.neutral01};
        background-color: ${theme.palette.global02};
    `};
  }
`;

export default { FilterButton01Styled };
