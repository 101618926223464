import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

import type { Product } from 'types/products.types';

type State = {
  loading: boolean;
  product: Product | null;
};

export const initialState: State = {
  loading: false,
  product: null,
};

const previewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    fetchPreviewStart: (state) => {
      state.loading = true;
    },
    fetchPreviewSuccess: (state, { payload }: PayloadAction<Product>) => {
      state.product = payload;
      state.loading = false;
    },
    fetchPreviewFailed: () => initialState,
  },
});

export const { fetchPreviewStart, fetchPreviewFailed, fetchPreviewSuccess } =
  previewSlice.actions;

export default previewSlice;
