import styled from 'styled-components';

export const Footer = styled.div`
  background: ${({ theme }) => theme.palette.neutral01};
  padding: 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -8px 16px 0 rgba(17, 17, 17, 0.08);
`;
