import { connect } from 'react-redux';
import { compose } from 'recompose';

import { openModalAction } from 'store/modal/modal.slice';
import {
  getEditAction,
  getIsLocationModalOpen,
} from 'store/product/product.selectors';
import {
  deleteProductsStart,
  setEditingAction,
  setLocationModalOpen,
  updateProductsStart,
} from 'store/product/product.slice';
import { removeProduct } from 'store/scan/scan.actions';
import { getScannedProducts } from 'store/scan/scan.selectors';

import ProductsModal from './ProductsModal';

const mapStateToProps = (state) => ({
  products: getScannedProducts(state),
  isLocationModalOpen: getIsLocationModalOpen(state),
  editAction: getEditAction(state),
});

const mapDispatchToProps = {
  openModalAction,
  removeProduct,
  onUpdateProducts: updateProductsStart,
  onRemoveProducts: deleteProductsStart,
  setIsLocationModalOpen: setLocationModalOpen,
  setEditingAction,
};

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhancer(ProductsModal);
