import _get from 'lodash/get';
import { PureComponent } from 'react';

import { configLabels } from 'utils/product.utils';

import {
  IconsWrapper,
  ImageAward,
  ImagePlaceholder,
  ImageStyled,
  ImageWrapper,
  ProductImageContainer,
} from './ProductImage.styled';

type Props = {
  src: string,
  className: unknown,
  labels: unknown[],
};

export default class ProductImage extends PureComponent<Props> {
  renderLabels = (labels: Array<Object>) =>
    labels &&
    configLabels(labels).map((label) => (
      <ImageAward key={label.name} src={_get(label, 'media.reference', '')} />
    ));

  render() {
    const { src, className, labels } = this.props;
    return (
      <ProductImageContainer className={className}>
        <IconsWrapper>{this.renderLabels(labels)}</IconsWrapper>
        <ImageWrapper>
          {src ? <ImageStyled src={src} /> : <ImagePlaceholder />}
        </ImageWrapper>
      </ProductImageContainer>
    );
  }
}
