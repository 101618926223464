import Grid from '@material-ui/core/Grid';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Button01 } from 'components/@common/Button';
import IcoPdf from 'components/@icons/icons/IcoPdf';

type Props = {
  onCancel: () => void,
  onAccept: () => void,
  disabledAccept: boolean,
};

export default class PrintButtons extends PureComponent<Props> {
  render() {
    const { onCancel, onAccept, disabledAccept } = this.props;
    return (
      <Grid container direction="row" justifyContent="flex-end" spacing={1}>
        <Grid item>
          <Button01 onClick={onCancel} color="secondary">
            <FormattedMessage id="common.button.cancel" />
          </Button01>
        </Grid>
        <Grid item>
          <Button01
            disabled={disabledAccept}
            onClick={onAccept}
            color="primary"
            iconLeft={<IcoPdf />}
          >
            <FormattedMessage id="print-modal.button.export-pdf" />
          </Button01>
        </Grid>
      </Grid>
    );
  }
}
