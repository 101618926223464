import axiosManager from 'services/api/axios';

// @ts-ignore
export const login = (data) =>
  axiosManager
    .post(`/auth/login`, data, {
      withCredentials: true,
    })
    .then(
      (response) => response.data,
      (errorResponse) => Promise.reject(errorResponse),
    );

export const refresh = () =>
  axiosManager
    .post(`/auth/refresh`, undefined, {
      withCredentials: true,
    })
    .then(
      (response) => response.data,
      (errorResponse) => Promise.reject(errorResponse),
    );

export const deleteRefresh = () =>
  axiosManager
    .delete('/auth/refresh', {
      withCredentials: true,
    })
    .then(
      (response) => response,
      (errorResponse) => Promise.reject(errorResponse),
    );

// @ts-ignore
export const requestResetPassword = (email) =>
  axiosManager
    .post('auth/reset_password', {
      email,
    })
    .then(
      (res) => res.data,
      (err) => Promise.reject(err),
    );
// @ts-ignore
export const setResetPassword = (token, password) =>
  axiosManager
    .put(`auth/reset_password/${token}`, {
      password,
    })
    .then(
      (res) => res.data,
      (err) => Promise.reject(err),
    );
