import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Heading01, Heading03 } from 'theme/Heading';
import { Paragraph01 } from 'theme/Paragraph';

export const Heading01Styled = styled(Heading01)`
  ${({ theme }) => theme.textStyles.heading01global02bold};
  ${({ readOnly }) => readOnly && 'font-size: 40px;'};
  word-break: break-all;
  text-align: center;
`;

export const Heading03Styled = styled(Heading03)`
  && {
    ${({ inProductRange, theme }) =>
      inProductRange
        ? theme.textStyles.heading03global02bold
        : theme.textStyles.heading03neutral04bold};

    ${breakpoint('xs', 'sm')`
    display: none;
  `};
  }
`;

export const Paragraph01Styled = styled(Paragraph01)`
  ${breakpoint('sm')`
    display: none;
  `};
`;
