import { all } from 'redux-saga/effects';

import appSaga from './app/app.saga';
import authSaga from './auth/auth.saga';
import kioskSaga from './kiosk/kiosk.saga';
import previewSaga from './preview/preview.saga';
import printSaga from './print/print.saga';
import productSaga from './product/product.saga';
import productsSaga from './products/products.saga';
import scanSaga from './scan/scan.saga';
import shopsSaga from './shops/shops.saga';
import stepFilterSaga from './step-filter/step-filter.saga';
import themeSaga from './theme/theme.saga';
import userSaga from './user/user.saga';

export default function* sagas() {
  yield all([
    appSaga(),
    authSaga(),
    printSaga(),
    productSaga(),
    productsSaga(),
    scanSaga(),
    shopsSaga(),
    userSaga(),
    themeSaga(),
    kioskSaga(),
    stepFilterSaga(),
    previewSaga(),
  ]);
}
