import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useDebouncedState, usePrevious } from 'hooks';
import { useSearchSuggestions } from 'queries';
import { getKiosk } from 'store/app/app.selectors';
import { getFilter } from 'store/filter/filter.selectors';
import { getActiveUserShopId } from 'store/user/user.selectors';

import SearchBarAutoComplete from './AutoComplete';

type Props = {
  searchValue: string;
  placeholder?: string;
  onSearchChange: (value: string, isSuggestion: boolean) => void;
};

const SearchBarAutoCompleteContainer = ({
  searchValue,
  placeholder,
  onSearchChange,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [search, setSearch] = useState(searchValue);
  const prevSearchValue = usePrevious(searchValue);
  const shopId = useSelector(getActiveUserShopId);
  const filter = useSelector(getFilter);
  const isKiosk = useSelector(getKiosk);
  const debouncedInputValue = useDebouncedState(inputValue);

  const { data } = useSearchSuggestions({
    term: debouncedInputValue,
    shopId: String(shopId),
    local: isKiosk ? 1 : filter.local ?? 0,
  });
  // TODO: this doesn't seem right?
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (searchValue !== prevSearchValue) {
      setSearch(searchValue);
    }
  });

  const handleChangeInput = (value: string) => {
    setInputValue(value);
  };

  return (
    <SearchBarAutoComplete
      searchValue={search}
      inputValue={inputValue}
      suggestions={
        data && inputValue.length >= 3 ? data.autocomplete.options : []
      }
      onInputChange={handleChangeInput}
      placeholder={placeholder}
      onSearchChange={onSearchChange}
    />
  );
};

export default SearchBarAutoCompleteContainer;
