import _get from 'lodash/get';

import { TEALIUM_NAVIGATION_DEFAULTS } from 'constants/tealium.constant';
import GeneralAnalytics from 'services/analytics/GeneralAnalytics';
import { AnalyticsFunction } from 'types/analytics.types';
import type { Product } from 'types/products.types';

export default class ProductsAnalytics extends GeneralAnalytics {
  // eslint-disable-next-line no-useless-constructor, @typescript-eslint/no-useless-constructor
  constructor(args: AnalyticsFunction) {
    super(args);
  }

  trackProductList = (userId: string, shopNumber: number, locale: unknown) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2b',
      user_id: userId,
      user_logged: userId ? 'yes' : 'no',
      env_organization: shopNumber,
      page_language: locale,
      page_name: 'catalog',
      page_status: 200,
      page_template: 'product listing',
    });

  trackProductListB2C = (locale: unknown) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: 'catalog',
      page_status: 200,
      page_template: 'product listing',
    });

  trackSearchB2C = (locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: 'Search',
      page_status: 200,
      page_template: 'other',
    });

  trackSuggestionB2C = (locale: string) =>
    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      env_shop: 'b2c',
      page_language: locale,
      page_name: 'Suggestion',
      page_status: 200,
      page_template: 'Product listing',
      'data-tms-navigation-detail': 'view wine suggestions',
    });

  trackEventFilteringSearch = (
    filteringSearchDetails?: string,
    itemCount?: number,
  ) => {
    let filteringSearchDetailsFormatted = filteringSearchDetails;
    filteringSearchDetailsFormatted = filteringSearchDetailsFormatted || '';

    this.track({
      event_name: 'search',
      search_category: 'product',
      search_term: filteringSearchDetailsFormatted,
      search_results: itemCount,
    });
  };

  trackB2CSearch = (
    searchTerm?: string,
    itemCount?: number,
    searchMethod?: string,
  ) => {
    this.track({
      event_name: 'search',
      search_category: 'product',
      search_term: searchTerm,
      search_results: itemCount,
      search_method: searchMethod,
    });
  };

  trackEventScanning = (data?: Product) => {
    if (!data) return null;
    const price = _get(data, 'shopInfo.priceAw', ''); // lodash?
    const products = [
      {
        id: data.productId,
        name: data.name,
        price: price ? Number(price).toFixed(2) : '',
      },
    ];

    return this.track({
      event_name: 'interaction',
      interaction_name: 'scan: wine product',
      products,
    });
  };

  trackChangeListView = (small: boolean) =>
    this.track({
      event_name: 'interaction',
      interaction_name: 'click: change wine listing view',
      interaction_details1: small ? 'small view' : 'large view',
    });

  trackChangeButtons = (action: string) =>
    this.track({
      event_name: 'interaction',
      interaction_name: 'click: wine list pagination buttons',
      interaction_details1: action,
    });

  trackEventNavigationScanning = (
    pageName?: string,
    locale?: string,
    shopId?: string,
    userId?: string,
  ) => {
    let info = {
      env_shop: 'b2c',
    };
    if (userId) {
      info = {
        env_shop: 'b2b',
        // @ts-ignore
        user_id: userId,
        user_logged: 'yes',
        env_organisation: shopId,
      };
    }

    this.track({
      ...TEALIUM_NAVIGATION_DEFAULTS,
      ...info,
      page_language: locale,
      page_name: pageName,
      page_status: 200,
      page_template: 'other',
      'data-tms-navigation-detail': 'scan wine',
    });
  };
}
