const IcoPdf = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 24 24" {...props}>
    <path
      d="M13.75 3.25h-7A1.75 1.75 0 0 0 5 5v14a1.75 1.75 0 0 0 1.74 1.75h10.51A1.76 1.76 0 0 0 19 19V8.5zM6.75 19V5h6.13v4.38h4.38V19z"
      fill="currentColor"
    />
  </svg>
);

export default IcoPdf;
