import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';

import Dropdown01 from 'components/@common/Dropdown';

export const GridContainerStyled = styled(Grid)`
  background-color: ${({ theme }) => theme.palette.neutral01};
  padding: 0 16px;
  padding-bottom: 0;
  width: 520px;

  > * {
    padding: 16px 0;
  }
`;

export const GridStyled02 = styled(Grid)`
  display: inline-flex;
  align-items: center;
`;

export const Dropdown01Styled = styled(Dropdown01)`
  margin-right: 20px;
  > div {
    min-width: 100px;
  }
`;

export const GridFilterStyled = styled(Grid)`
  align-self: flex-end;
`;

export const GridStyled01 = styled(Grid)`
  flex: 2 1 auto;
`;
