import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import modalTypes from 'enums/modal.enum';

type State = {
  open: boolean;
  type: (typeof modalTypes)[];
  data?: Object;
};

export const initialState: State = {
  open: false,
  type: [],
  data: undefined,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModalAction: (state, { payload }: PayloadAction<unknown>) => {
      const isScanningAlready =
        // @ts-ignore
        payload.type === modalTypes.SCANNING
          ? // @ts-ignore
            state.type.includes(payload.type)
          : false;

      const type = isScanningAlready
        ? state.type
        : // @ts-ignore
          state.type.concat([payload.type]);

      state.type = type;
      // @ts-ignore
      state.data = payload.data;
      state.open = true;
    },
    setModalDataAction: (state, { payload }: PayloadAction<unknown>) => {
      // @ts-ignore
      state.data = payload;
    },
    closeModalAction: (state, { payload }: PayloadAction<number>) => {
      const type = state.type.splice(0, state.type.length - (payload || 1));
      state.type = type;
      state.open = false;
    },
    closeSpecificModalAction: (
      state: State,
      { payload }: PayloadAction<{ type: string }>,
    ) => {
      // @ts-ignore
      const type = state.type.filter((t) => t !== payload.type);
      state.type = type;
      state.open = false;
    },
    closeAllModalsAction: () => initialState,
  },
});

export const {
  openModalAction,
  closeModalAction,
  setModalDataAction,
  closeAllModalsAction,
  closeSpecificModalAction,
} = modalSlice.actions;

export default modalSlice;
