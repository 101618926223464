const LogoFull = (props) => (
  <svg data-name="Laag 1" viewBox="0 0 137 28" {...props}>
    <path
      d="M61.61 5.52c1.2 0 2 .45 3.08 1.89l8.37 12a1.7 1.7 0 0 1-.37 2.28 1.77 1.77 0 0 1-2.54-.23l-2-2.78H55l-2 2.82a1.64 1.64 0 0 1-2.36.32 1.69 1.69 0 0 1-.52-2.34l8.42-12.07c1.07-1.51 1.91-1.89 3.07-1.89M62.1 10c-.21-.32-.3-.35-.53-.35s-.37.13-.55.37c-.28.39-3.64 5.33-3.68 5.33h8.48s-3.25-4.7-3.72-5.35M4.49 5.58a4.91 4.91 0 0 0-4.49 5 5 5 0 0 0 5.16 5.08h10.27a1.57 1.57 0 0 1 1.36 1.52 1.6 1.6 0 0 1-1.5 1.5H.1a3.24 3.24 0 0 0 3.43 3.47h12.26a5.19 5.19 0 0 0 4.62-5.25c0-3-3-4.72-5-4.72H4.92a1.51 1.51 0 0 1-1.36-1.61A1.42 1.42 0 0 1 5 9h14.8s-.27-3.44-3.26-3.44h-12m77.34-.05a3.75 3.75 0 0 0-3.39 3.4v11.23a1.9 1.9 0 1 0 3.81 0v-11h11.55a1.68 1.68 0 0 1 1.49 1.67 1.65 1.65 0 0 1-1.49 1.65H83.33a3.37 3.37 0 0 0 3.27 3.53h4.87c2.69 3.42 3.68 4.72 4.52 5.71a1.89 1.89 0 0 0 2.93-2.3c-2.66-3.63-2.79-3.83-2.79-3.83a5.11 5.11 0 0 0 3-4.52v-.23a5 5 0 0 0-4.82-5.23H81.82m-51.34 0A3.73 3.73 0 0 0 27.11 9v11.16a1.9 1.9 0 1 0 3.8-.05v-11h11.6A1.68 1.68 0 0 1 44 10.78a1.64 1.64 0 0 1-1.51 1.6H32.06a3.31 3.31 0 0 0 3.17 3.56h7.83a11.38 11.38 0 0 0 1.74-.39A5.15 5.15 0 0 0 47.75 11v-.24a5 5 0 0 0-4.81-5.24H30.48"
      fill="#ed3943"
    />
    <path
      d="M122.74.1a14.13 14.13 0 0 0-14.24 14.25 13 13 0 0 0 7.13 12 16.85 16.85 0 0 0 14.24 0 13.42 13.42 0 0 0 7.12-12A14.21 14.21 0 0 0 122.74.1zm9.63 20.27a6.91 6.91 0 0 1-5.76 3.44 2.36 2.36 0 0 1-2.44-2.43v-2.1h7.51c-3.23-3.49-7.51-10.61-8.93-15.48-1.43 4.87-5.69 12-8.81 15.48h7.37v2.12a2.44 2.44 0 0 1-2.31 2.42 6.81 6.81 0 0 1-5.8-3.39 10.43 10.43 0 0 1-1.83-6.08 11.38 11.38 0 0 1 11.37-11.41 11.54 11.54 0 0 1 11.41 11.41 12.2 12.2 0 0 1-1.78 6.03z"
      fill="#007a47"
    />
  </svg>
);

export default LogoFull;
