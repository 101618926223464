import _get from 'lodash/get';

import { StoreState } from 'store/reducers';

export const getKioskProducts = (state: StoreState) =>
  _get(state, 'kiosk.products.data');

export const getKioskProductsPaging = (state: StoreState) =>
  _get(state, 'kiosk.paging');
export const getKioskProductsTotal = (state: StoreState) =>
  _get(state, 'kiosk.products.meta.total');
export const getKioskProductsTotalPages = (state: StoreState) =>
  _get(state, 'kiosk.products.meta.pages');

export const getKioskProductLoading = (state: StoreState) =>
  _get(state, 'kiosk.loading');

export const getKioskAllProductsCount = (state: StoreState) =>
  _get(state, 'kiosk.allProducts.meta.total', 0);
