import { modalTypes, productActionTypes } from 'enums';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';

import { transformPrices } from 'utils/index';

import type { Product } from 'services/api/spec/api-spec';
import { getLocale } from 'store/app/app.selectors';
import { getUser } from 'store/user/user.selectors';

import ActionBar from 'components/@catalog/ActionBar/ActionBar';
import { ProductLocationModal } from 'components/@product';
import ProductView from 'components/@product/ProductView';

import { Modal01 } from '../index';
import {
  ActionBarContainer,
  ItemCountLabel,
  ItemCountWrapper,
  ItemListWrapper,
} from './ProductsModal.styled';

type Props = {
  products: Product[],
  open: boolean,
  openModalAction: (config: {
    type: typeof modalTypes,
    data?: Product,
  }) => void,
  onClose: () => void,
  removeProduct: (product: Product) => void,
  onUpdateProducts: () => void,
  onRemoveProducts: () => void,
  isLocationModalOpen: boolean,
  setIsLocationModalOpen: (isOpen: boolean) => void,
  editAction: string,
  setEditingAction: (action: string) => void,
};

class ProductsModal extends PureComponent<Props> {
  renderProducts = () => {
    const { products, openModalAction, removeProduct, open } = this.props;
    if (open && products.length <= 0) {
      // open scanner modal
      openModalAction({
        type: modalTypes.SCANNING,
      });
      return null;
    }

    return products.map((product) => (
      <ProductView
        key={product.productId}
        product={product}
        onDelete={() => {
          removeProduct(product);
        }}
        onProductClick={() =>
          openModalAction({
            type: modalTypes.PRODUCT_DETAIL,
            data: product,
          })
        }
        prices={transformPrices(product)}
      />
    ));
  };

  render() {
    const {
      onClose,
      open,
      products,
      onUpdateProducts,
      onRemoveProducts,
      isLocationModalOpen,
      setIsLocationModalOpen,
      editAction,
      setEditingAction,
    } = this.props;
    return (
      <Modal01
        title={<FormattedMessage id="product-detail-modal.title" />}
        onClose={onClose}
        open={open}
      >
        <ItemCountWrapper>
          <ItemCountLabel>
            <FormattedMessage
              id="products-modal.item-count.label"
              values={{ itemCount: products.length }}
            />
          </ItemCountLabel>
        </ItemCountWrapper>
        <ItemListWrapper>{this.renderProducts()}</ItemListWrapper>
        <ActionBarContainer>
          <ActionBar
            onItemChange={setEditingAction}
            value={editAction}
            onSubmit={() => setIsLocationModalOpen(true)}
          />
        </ActionBarContainer>
        <ProductLocationModal
          open={isLocationModalOpen}
          type={
            editAction === 'edit'
              ? productActionTypes.BULK_UPDATE
              : productActionTypes.BULK_REMOVE
          }
          products={products}
          onUpdateProducts={onUpdateProducts}
          onRemoveProducts={onRemoveProducts}
          onCancel={() => setIsLocationModalOpen(false)}
        />
      </Modal01>
    );
  }
}

const mapStateToProps = (state) => ({
  locale: getLocale(state),
  user: getUser(state),
});

export default connect(mapStateToProps)(ProductsModal);
