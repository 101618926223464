import MUICheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styled from 'styled-components';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const FormControlLabelStyled = styled(({ $ellipsis, ...rest }) => (
  <FormControlLabel {...rest} />
))`
  && {
    font-family: ${({ theme }) => theme.fonts.default};
    margin-left: 0;
    span:nth-child(2) {
      max-width: 130px;
      ${({ $ellipsis }) => $ellipsis && 'overflow: hidden;'};
      white-space: nowrap;
      display: block;
      text-overflow: ellipsis;
      ${({ theme }) => theme.textStyles.paragraph01neutral02right};
    }
  }
`;

export const MUICheckboxStyled = styled(MUICheckbox)`
  && {
    input:disabled {
      cursor: no-drop;
    }
  }
`;
