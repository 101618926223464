import styled from 'styled-components';

export const FormControlStyled = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
`;

export const ErrorStyled = styled.span`
  ${({ theme }) => theme.textStyles.paragraph02global01boldleft};
  display: block;
  margin-top: 8px;
  line-height: 1.33;
`;
