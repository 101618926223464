import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import { PureComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import type { Product } from 'types/products.types';

import ProductDescription from 'components/@product/ProductDescription';

type Props = {
  product: Product,
  readOnly: boolean,
};

export default class PDDescriptions extends PureComponent<Props> {
  renderDescription = (intlId: string, description: string) =>
    description && (
      <Grid item xs={12}>
        <ProductDescription
          readOnly={this.props.readOnly}
          title={<FormattedMessage id={intlId ?? ''} />}
          description={description}
        />
      </Grid>
    );

  render() {
    const { product } = this.props;

    return (
      <Grid container direction="row">
        {this.renderDescription(
          'product.details.title.taste',
          _get(product, 'wineTastingInfo', ''),
        )}
        {this.renderDescription(
          'product.details.title.gastronomy',
          _get(product, 'wineGastronomy', ''),
        )}
        {this.renderDescription(
          'product.details.title.factoid',
          _get(product, 'wineFactoid', ''),
        )}
      </Grid>
    );
  }
}
