import { PureComponent, ReactNode } from 'react';

import {
  IconStyled,
  Item,
  Paragraph01Styled,
  Title,
} from './FoodPairing.styled';

type Props = {
  title: string,
  label: string,
  icon?: ReactNode,
};

export default class FoodPairing extends PureComponent<Props> {
  hasIcon = (icon?: ReactNode) => icon && <IconStyled src={icon} alt="" />;

  render() {
    const { title, label, icon } = this.props;
    return (
      <>
        {title && <Title>{title}</Title>}
        {label && (
          <Item>
            {this.hasIcon(icon)}
            <Paragraph01Styled>{label || '-'}</Paragraph01Styled>
          </Item>
        )}
      </>
    );
  }
}
