import { connect } from 'react-redux';
import { compose } from 'recompose';

import { getFilterShopId } from 'store/filter/filter.selectors';
import { openModalAction } from 'store/modal/modal.slice';
import { startScan } from 'store/scan/scan.actions';
import { resetStepFilter } from 'store/step-filter/step-filter.slice';

import ProductDetailsModalB2c from './ProductDetailsModalB2c';

const mapStateToProps = (state) => ({
  shopId: getFilterShopId(state),
});

const mapDispatchToProps = {
  openModalAction,
  startScan,
  resetStepFilter,
};

const enhancer = compose(connect(mapStateToProps, mapDispatchToProps));

export default enhancer(ProductDetailsModalB2c);
