import { BaseButtonStyled } from './ButtonBase.styled';

type Props = {
  isDark: boolean,
  rest: unknown,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ButtonBase = ({ isDark, iconLeft, iconRight, ...rest }: Props) => (
  <BaseButtonStyled {...rest} />
);

export default ButtonBase;
