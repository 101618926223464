import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { withTheme } from 'styled-components';

import ButtonLabel from '../ButtonLabel';
import { Button01Styled } from './Button01.styled';

type Props = {
  fullWidth?: boolean;
  children?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  color?: string;
  isDark?: boolean;
  ellipsis?: boolean;
  intlId?: string;
  className?: string;
  isLoading?: Boolean;
  onClick?: () => void;
};

const hasIcon = (icon: ReactNode) => icon && <SvgIcon>{icon}</SvgIcon>;

const Button01 = (props: Props) => {
  const {
    iconLeft,
    iconRight,
    ellipsis,
    intlId,
    children,
    className,
    ...rest
  } = props;
  return (
    <Button01Styled
      className={className}
      $iconLeft={iconLeft}
      $iconRight={iconRight}
      disableRipple
      {...rest}
    >
      {hasIcon(iconLeft)}
      <ButtonLabel ellipsis={ellipsis}>
        {intlId && <FormattedMessage id={intlId} />}
        {children}
      </ButtonLabel>
      {hasIcon(iconRight)}
    </Button01Styled>
  );
};

export default withTheme(Button01);
