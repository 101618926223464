import styled, { css } from 'styled-components';

import { Heading03 } from 'theme/Heading';
import { Paragraph01 } from 'theme/Paragraph';

const Heading03StyledReadOnly = css`
  font-family: ${({ theme }) => theme.fonts.written};
  color: ${({ theme }) => theme.palette.global02};
  font-weight: normal;
  margin-top: 28px;
  font-size: 36px;
`;

const Paragraph01StyledReadOnly = css`
  font-size: 20px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.black};
`;

export const Heading03Styled = styled(Heading03)`
  ${({ theme, readOnly }) =>
    readOnly
      ? Heading03StyledReadOnly
      : theme.textStyles.heading03global02bold};
  margin-bottom: ${({ readOnly }) => (readOnly ? 14 : 8)}px;
`;

export const Paragraph01Styled = styled(Paragraph01)`
  ${({ theme, readOnly }) =>
    readOnly
      ? Paragraph01StyledReadOnly
      : theme.textStyles.paragraph01neutral02};
`;

export const Container = styled.div`
  margin-bottom: 24px;
`;
