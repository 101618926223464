import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import MUIAutocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useIntl } from 'react-intl';
import styled from 'styled-components';

const StyledTextField = styled(TextField)`
  border-radius: 16px;
  background-color: white;
  height: 64px;
  & > div {
    border-radius: 16px;
    border-color: ${({ theme }) => theme.palette.neutral01};
    height: 64px;
  }
`;

type Props = {
  searchValue: string;
  inputValue: string;
  suggestions: string[];
  placeholder?: string;
  onSearchChange: (value: string, isSuggestion: boolean) => void;
  onInputChange: (value: string) => void;
};

const Autocomplete = ({
  searchValue,
  inputValue,
  suggestions,
  placeholder,
  onSearchChange,
  onInputChange,
}: Props) => {
  const intl = useIntl();

  return (
    <MUIAutocomplete
      id="combo-box-demo"
      options={suggestions}
      freeSolo
      getOptionLabel={(option) => option}
      value={searchValue}
      clearOnBlur={false}
      onChange={(_, value) => {
        const safeValue = value === undefined || value === null ? '' : value;
        onSearchChange(safeValue.trim(), true);
        onInputChange(safeValue.trim());
      }}
      onInputChange={(_, newInputValue) => {
        onInputChange(newInputValue);
        onSearchChange(newInputValue.trim() || '', false);
      }}
      renderInput={(params) => (
        <StyledTextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          // eslint-disable-next-line react/jsx-no-duplicate-props
          inputProps={{
            ...params.inputProps,
            value: inputValue,
          }}
          variant="outlined"
          placeholder={
            placeholder ||
            intl.formatMessage({
              id: 'common.search.placeholder',
            })
          }
          fullWidth
        />
      )}
      renderOption={(option, { inputValue: value }) => {
        const matches = match(option, value);
        const parts = parse(option, matches);

        return (
          <div>
            {parts.map((part) => (
              <span
                key={part.text}
                style={{ fontWeight: part.highlight ? 400 : 700 }}
              >
                {part.text}
              </span>
            ))}
          </div>
        );
      }}
    />
  );
};
export default Autocomplete;
