import { ButtonProps } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonLabel from '../ButtonLabel';
import { Button02Styled } from './Button02.styled';

interface Props extends ButtonProps {
  children?: ReactNode;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  ellipsis?: boolean;
  intlId?: string;
  isDark?: boolean;
}

const hasIcon = (icon: ReactNode) => icon && <SvgIcon>{icon}</SvgIcon>;

const Button02 = (props: Props) => {
  const { iconLeft, iconRight, ellipsis, intlId, isDark, ...rest } = props;
  return (
    <Button02Styled $isDark={isDark} disableRipple {...rest}>
      {hasIcon(iconLeft)}
      <ButtonLabel ellipsis={ellipsis}>
        {intlId && <FormattedMessage id={intlId} />}
        {props.children}
      </ButtonLabel>
      {hasIcon(iconRight)}
    </Button02Styled>
  );
};

export default Button02;
