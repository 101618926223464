import axiosManager from 'services/api/axios';
import { Autocomplete, SearchSuggestionsParams } from 'types/search.types';

export const getSearchSuggestions = ({
  local,
  shopId,
  term,
}: SearchSuggestionsParams) =>
  axiosManager
    .get<Autocomplete>(`/autocomplete`, {
      withCredentials: true,
      params: { search: term.split(', ').join(), shop_id: shopId, local },
    })
    .then(
      (res) => res.data,
      (e) => Promise.reject(e),
    );
