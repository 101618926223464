import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import { Button01, ButtonIcon01 } from 'components/@common/Button';

export const ButtonIconStyled = styled(ButtonIcon01)`
  && {
    ${breakpoint('sm')`
    display: none;
  `};
  }
`;

export const Button01Styled = styled(Button01)`
  && {
    font-size: 16px;
    ${breakpoint('xs', 'sm')`
    display: none;
  `};
  }
`;
